import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import TradeHeader from "./TradeHeader";
import Footer from "./Footer";

import { connect } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import { store } from "react-notifications-component";
import queryString from "query-string";

import { registerfrontUser } from "../actions/authactions";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      password2: "",
      mobileaccept: "",
      referalcode: "",
      errors: {},
      accept: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onChangeCheckbox = (e) => {
    this.setState({ [e.target.id]: e.target.checked });
  };

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    // console.log("filter values",values)
    if (typeof values.ref != "undefined") {
      this.setState({ referalcode: values.ref });
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextprops in Register ", nextProps);

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth.newuser !== undefined &&
      nextProps.auth.newuser.data !== undefined &&
      nextProps.auth.newuser.data.message !== undefined
    ) {
      store.addNotification({
        title: "Wonderful!",
        message: nextProps.auth.newuser.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }

    if (nextProps.auth.newuser.status == 200) {
      this.props.history.push("/Login");
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const newUser = {
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      referalcode: this.state.referalcode,
      accept: this.state.accept.toString(),
    };
    // console.log("user registration details", newUser);

    this.props.registerfrontUser(newUser, this.props.history);
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <TradeHeader />
        <section className="loginRegBg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-5">
                <div className="loginRegLeft">
                  <h3>
                    <small>
                      <span>Join for access to</span>
                    </small>
                    NeoGenesis Exchange/Wallet <span>NeoGenesis CyberLottery</span> GenesisOne Ecosystem{" "}
                  </h3>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-7">
                <div className="loginRegRight">
                  <div className="formBox">
                    <h2>Registration</h2>
                    <form noValidate onSubmit={this.onSubmit}>
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.email}
                          error={errors.email}
                          type="email"
                          id="email"
                          className={classnames("form-control", {
                            invalid: errors.email,
                          })}
                        />
                        <span className="text-danger">{errors.email}</span>
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.password}
                          error={errors.password}
                          type="password"
                          id="password"
                          className={classnames("form-control", {
                            invalid: errors.password,
                          })}
                        />
                        <span className="text-danger">{errors.password}</span>
                      </div>
                      <div className="form-group">
                        <label>Retype Password</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.password2}
                          error={errors.password2}
                          type="password"
                          id="password2"
                          className={classnames("form-control", {
                            invalid: errors.password2,
                          })}
                        />
                        <span className="text-danger">{errors.password2}</span>
                      </div>

                      <div className="form-group">
                        <label>Referral Code (Optional)</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.referalcode}
                          error={errors.referalcode}
                          type="text"
                          id="referalcode"
                          className={classnames("form-control", {
                            invalid: errors.referalcode,
                          })}
                        />
                        <span className="text-danger">
                          {errors.referalcode}
                        </span>
                      </div>

                      <div className="form-group clearfix fadeIn third">
                        <div className="float-left">
                          <div className="checkbox">
                            <label>
                              <input
                                onChange={this.onChangeCheckbox}
                                value={this.state.accept}
                                error={errors.accept}
                                id="accept"
                                type="checkbox"
                                className={classnames("form-control", {
                                  invalid: errors.accept,
                                })}
                              />
                              <span className="cr">
                                <i className="cr-icon fa fa-check"></i>
                              </span>
                              <span>
                                I accept and agree to the{" "}
                                <a href="/TermsAndConditions" className="regTerms">
                                  Terms &amp; Conditions
                                </a>
                              </span>
                            </label>
                            <br></br>
                            <span className="text-danger">{errors.accept}</span>
                          </div>
                        </div>
                      </div>
                      <p className="text-center">
                        <button className="btn btnType1 my-0" type="submit">
                          Register now
                        </button>
                      </p>
                      <p className="text-center regLinks">
                        Already registered user?{" "}
                        <a href="/Login" className="ml-0">
                          Login
                        </a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 d-none d-lg-block"></div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

Register.propTypes = {
  registerfrontUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerfrontUser })(
  withRouter(Register)
);
