import React, { Component } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ReactDOM from 'react-dom';
import NewsThumb1 from "../images/news1.jpg"
import NewsThumb2 from "../images/news2.jpg"

class News extends Component {
	render() {
		return (
      <div>
      <Navbar />
      <section className="lgoinContent newsListMain">
      <div className="container">
      <h2>News</h2>
      <div className="newsCard">
        <div className="row">
          <div className="col-md-4 col-sm-4 mb-4 mb-sm-0">
            <div className="newsListImg">
              <a href="#"><img src={NewsThumb1} className="img-fluid" /></a>
              <div className="newsDate">18<span>Feb</span></div>
            </div>
          </div>
          <div className="col-md-8 col-sm-8">
            <div className="newsListContent">
              <h5>New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB<span>4 DAYS AGO  |   by Michael Kapilkov</span></h5>
              <p>New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB, New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB, New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB, New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB.</p>
              <p>New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB, New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB.  New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="newsCard">
        <div className="row">
          <div className="col-md-4 col-sm-4 mb-4 mb-sm-0">
            <div className="newsListImg">
              <a href="#"><img src={NewsThumb2} className="img-fluid" /></a>
              <div className="newsDate">02<span>Jan</span></div>
            </div>
          </div>
          <div className="col-md-8 col-sm-8">
            <div className="newsListContent">
              <h5>New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB<span>4 DAYS AGO  |   by Michael Kapilkov</span></h5>
              <p>New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB, New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB, New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB, New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB.</p>
              <p>New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB, New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB.  New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB, New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB.  New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB, New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB.  New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB, New Partnership Aims to Bring Blockchain Sports Tokens to the NFL, MLB.</p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
      <Footer />
</div>
);
	}
}

export default News