import React, { Component } from 'react'
import {Link} from 'react-router-dom';

class UserFooter extends Component{
	render(){
			return (
        <div>
          <footer className="pageFooter">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="footerBottom loginFooter">
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li><Link to="/TokenDetails">Token Details</Link></li>
                      <li><Link to="/Support">Support Ticket</Link></li>
                      <li><Link to="/FAQ">FAQ</Link></li>
                    </ul>
                    <p>Copyright @ 2022 EG Group Global Holdings. All rights reserved</p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
);
	};
}

export default UserFooter;