import React, { Component } from "react";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import ReactDOM from "react-dom";
import DepositQrCode from "../images/depositCode.jpg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import keys from "../actions/config";
import "react-toastify/dist/ReactToastify.css";
import * as moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { store } from "react-notifications-component";
import Select from "react-select";
import classnames from "classnames";

var QRCode = require("qrcode.react");
const url = keys.baseUrl;

class Deposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_assigned: false,
      _id: "",
      currency: "",
      eth_address: "",
      go_address: "",
      btc_address: "",
      btcbalance: 0,
      ethbalance: 0,
      gobalance: 0,
      history: [],
      firstname:"",
      lastname:"",
      email:"",

      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }

    console.log("nextProps in KyC", nextProps.auth);
    if (
      nextProps.auth.updatenewuser !== undefined &&
      nextProps.auth.updatenewuser.data !== undefined &&
      nextProps.auth.updatenewuser.data.message !== undefined
    ) {
      store.addNotification({
        title: "Wonderful!",
        message: nextProps.auth.updatenewuser.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
      // this.getData();
    }
  }

  componentDidMount() {
    this.getcurrency();
    this.getaddress();
    this.gethistory();
  }

  getcurrency() {
    axios.get(url + "api/currencydata").then((res) => {
      var currencyarray = [];
      res.data.map((item, i) => {
        if (item.currencySymbol != "USD") {

        const name = item.currencySymbol;
        const value = item.currencySymbol;
        const obj = { value: name, label: value };
        currencyarray.push(obj);
        }
      });
      console.log(currencyarray, "currencyarray");
      this.setState({ currencydata: currencyarray, email_assigned: true });
    });
  }

  getaddress() {
    const id = this.props.auth.user.id;

    axios.get(url + "api/getseperateaddress/" + id).then((res) => {
      console.log("usser adres", res.data);
      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].currencySymbol == "ETH") {
          var eth_address = res.data[i].currencyAddress;
          var ethbal = res.data[i].balance;

          this.setState({ eth_address: eth_address });
          this.setState({ ethbalance: ethbal });
        }
        if (res.data[i].currencySymbol == "BTC") {
          var btc_address = res.data[i].currencyAddress;
          var btcbal = res.data[i].balance;

          this.setState({ btc_address: btc_address });
          this.setState({ btcbalance: btcbal });
        }
        if (res.data[i].currencySymbol == "GO") {
          var go_address = res.data[i].currencyAddress;
          var gobal = res.data[i].balance;

          this.setState({ go_address: go_address });
          this.setState({ gobalance: gobal });
        }
      }
    });
  }

  gethistory() {
    const id = this.props.auth.user.id;
    axios.get(url + "api/transactionhistorydeposit/" + id).then((res) => {
      console.log("result", res.data);
      this.setState({ history: res.data });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleChange = (selectedOption) => {
    console.log(`Option selected:`, selectedOption);
    this.setState({
      currency: {
        value: selectedOption.value,
        label: selectedOption.value,
      },
    });
  };

  validatePayment() {
    
    let errors = {};
    errors["firstname"] = "";
    errors["lastname"] = "";
    errors["email"] = "";

    let formIsValid = true;
  console.log('this.state.firstname',this.state.firstname)
    if (this.state.firstname == "") {
      formIsValid = false;
      errors["firstname"] = "*Please enter the name";
    }
    if (this.state.lastname == "") {
      formIsValid = false;
      errors["lastname"] = "*Please enter the last name";
    }
    if (this.state.email == "") {
      formIsValid = false;
      errors["email"] = "*Please enter the email id";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  async onPaymentSubmit() {
    var tokenPage="https://merchant.net-cents.com/widget/payment?data=";
    var data ={
      firstname:this.state.firstname,
      lastname:this.state.lastname,
      email:this.state.email,
      amount:this.state.amount,
    }

    var response =  await axios.post(url + "api/get-token",data)
    if (response && response.status == 200) {
      var token = tokenPage+response.data.token;
      console.log('token',token)
      window.open(token, '_blank');
    } else {
        //toastAlert('error', response.message, 'token');
    } 
    
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const { errors } = this.state;
    const { selectedOption } = this.state;
    console.log('errors',errors)
    return (
      <div>
        <UserHeader />
        <section className="lgoinContent depositPage">
          <div className="container">
            <h2>
              <span>Deposit</span>{" "}
              <a href="/Dashboard">
                <i className="fas fa-angle-left"></i> Dashboard
              </a>
            </h2>
            <div class="row">
              <div class="col-lg-6 mx-auto">
                <div className="depositCodeDetails mb-5">
                  <Select
                    className="mb-3"
                    value={selectedOption}
                    onChange={this.handleChange}
                    placeholder="Select the Currency"
                    options={this.state.currencydata}
                  />
                  {this.state.currency.value == "ETH" ? (
                    <div>
                      <h6>Your ETH address</h6>
                      <h4>
                        {this.state.eth_address}{" "}
                        {/* <a href="#" data-tip="Copy Address">
                      <i className="far fa-copy"></i>
                    </a> */}
                        <CopyToClipboard
                          text={this.state.eth_address}
                          onCopy={() => this.setState({ copied: true })}
                        >
                          <button className="input-group-append-icon">
                            <i
                              className="far fa-copy"
                              data-tip="Copy Address"
                            ></i>
                          </button>
                        </CopyToClipboard>
                        {this.state.copied ? (
                          <span style={{ color: "green" }}>Copied.</span>
                        ) : null}
                      </h4>

                      <h6>Your ETH Balance</h6>
                      <h4>{this.state.ethbalance} ETH </h4>

                      <QRCode value={this.state.eth_address} />
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.currency.value == "GO" ? (
                    <div>
                      <h6>Your Genesis ONE address</h6>
                      <h4>
                        {this.state.go_address}{" "}
                        <CopyToClipboard
                          text={this.state.go_address}
                          onCopy={() => this.setState({ copied: true })}
                        >
                          <button className="input-group-append-icon">
                            <i
                              className="far fa-copy"
                              data-tip="Copy Address"
                            ></i>
                          </button>
                        </CopyToClipboard>
                        {this.state.copied ? (
                          <span style={{ color: "green" }}>Copied.</span>
                        ) : null}
                      </h4>

                      <QRCode value={this.state.go_address} />
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.currency.value == "BTC" ? (
                    <div>
                      <h6>Your BTC address</h6>
                      <h4>
                        {this.state.btc_address}{" "}
                        <CopyToClipboard
                          text={this.state.btc_address}
                          onCopy={() => this.setState({ copied: true })}
                        >
                          <button className="input-group-append-icon">
                            <i
                              className="far fa-copy"
                              data-tip="Copy Address"
                            ></i>
                          </button>
                        </CopyToClipboard>
                        {this.state.copied ? (
                          <span style={{ color: "green" }}>Copied.</span>
                        ) : null}
                      </h4>

                      <h6>Your BTC Balance</h6>
                      <h4>{this.state.btcbalance} BTC </h4>

                      <QRCode value={this.state.btc_address} />
                    </div>
                  ) : (
                    ""
                  )}

                  <p>
                    Note: use to deposit only above GO coin address if other
                    address may lost all your fund.
                  </p>

                  <form noValidate onSubmit={this.onProfileSubmit}>
              <div className="settingsBox">
                <div className="row">
                  <div className="col-md-9">
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                       onChange={this.onChange}
                        id="firstname"
                        type="text"
                        error={errors.firstname}
                        placeholder="Enter the First Name"
                        className={classnames("form-control", {
                          invalid: errors.firstname,
                        })}
                      />
                      <span className="text-danger">{errors.firstname}</span>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="form-group">
                      <label>Last Name</label>

                      <input
                        onChange={this.onChange}
                        value={this.state.lastname}
                        id="lastname"
                        type="text"
                        error={errors.lastname}
                        placeholder="Enter the Last Name"
                        className={classnames("form-control", {
                          invalid: errors.lastname,
                        })}
                      />
                      <span className="text-danger">{errors.lastname}</span>
                    </div>
                  </div>

                  <div className="col-md-9">
                    <div className="form-group">
                      <label>Email Address</label>

                      <input
                        onChange={this.onChange}
                        value={this.state.email}
                        id="email"
                        type="text"
                        placeholder="Enter the email address"
                        error={errors.email}
                        className={classnames("form-control", {
                          invalid: errors.email,
                        })}
                      />
                      <span className="text-danger">{errors.email}</span>
                    </div>
                  </div>
                  
                  
                    <div className="col-md-9">
                     <div className="form-group">
                      <label>Amount</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.amount}
                        id="amount"
                        type="amount"
                        error={errors.amount}
                        placeholder="Enter the amount"
                        className={classnames("form-control", {
                          invalid: errors.amount,
                        })}
                      />
                      <span className="text-danger">{errors.amount}</span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <p className="text-center">
                      <input
                        type="text"
                        readOnly
                        name=""
                        value="Proceed"
                        className="btn btnType1 px-0 mt-3"
                        onClick={() => this.onPaymentSubmit()}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </form>


                </div>
              </div>
            </div>

            <h4>Deposit History</h4>
            <div className="table-responsive">
              <table className="table borderedTable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    {/* <th>From</th> */}
                    <th>Trans. ID</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.history.map((answer, i) => {
                    var noHTML = /(<([^>]+)>)/gi; // regex for removing HTML tags
                    var clean_desc = answer.txid.replace(noHTML, ""); // remove all html tags
                    var echo_val = clean_desc.substring(0, 10);
                    var link = "https://etherscan.io/tx/"  + answer.txid;
                    return (
                      <tr>
                        <td>
                          {" "}
                          {moment(answer.created_date).format(
                            "DD-MM-YYYY  h:mm a "
                          )}
                        </td>
                        <td>
                          {answer.amount} {answer.currency}
                        </td>

                        {/* <td>{answer.fromaddress}</td> */}
                        <td>{answer.txid}</td>
                        {/* <td>
                          <a target="_blank" href={link}>
                            {echo_val}....
                          </a>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <UserFooter />
      </div>
    );
  }
}

Deposit.propTypes = {
  // logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Deposit);
