import React, { Component, useState, useRef, useEffect } from "react";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import keys from "../actions/config";
import { store } from "react-notifications-component";
import Select from "react-select";
import classnames from "classnames";
import * as moment from "moment";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Modal, Button } from "react-bootstrap/";
// import { PayPalButton } from "react-paypal-button-v2";
import PaypalExpressBtn from "react-paypal-express-checkout";

import netcentlogo from "../images/netcentlogo.png";

const url = keys.baseUrl;

class Buy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_assigned: false,
      _id: "",
      buyamount: "",
      token_price_btc: 0,
      token_price_usd: 0,
      token_price_eth: 0,
      token_price_gbp: 0,
      token_price_eur: 0,
      currency: "",
      ethamount: "",
      firstname: "",
      lastname: "",
      email: "",
      amount1: "",
      currency1: "",
      validation: {},
      eth_address: "",
      go_address: "",
      btc_address: "",
      btcbalance: 0,
      ethbalance: 0,
      gobalance: 0,
      history: [],
      loading: false,
      paywaiting: false,
      errors: {},
      errors1: {},
      shownetcent: false,
      netcentloading: false,
      cryptocurrency:[]
    };
    // this.buyChange = this.buyChange.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  componentDidMount() {
    this.getvalue();
    this.gethistory();
    this.getcurrency();
    this.getAddress();
  }

  gethistory() {
    const id = this.props.auth.user.id;
    axios.get(url + "api/buyhistory/" + id).then((res) => {
      this.setState({ history: res.data });
    });
  }

  getvalue() {
    axios.get(url + "api/feedata").then((res) => {
      this.setState(res.data[0]);
    });
  }

  getAddress() {
    const id = this.props.auth.user.id;

    axios.get(url + "api/getseperateaddress/" + id).then((res) => {
      // console.log("usser adres", res.data);
      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].currencySymbol == "ETH") {
          var eth_address = res.data[i].currencyAddress;
          var ethbal = res.data[i].balance;
          this.setState({ eth_address: eth_address });
          this.setState({ ethbalance: ethbal });
        }
        if (res.data[i].currencySymbol == "BTC") {
          var btc_address = res.data[i].currencyAddress;
          var btcbal = res.data[i].balance;

          this.setState({ btc_address: btc_address });
          this.setState({ btcbalance: btcbal });
        }
        if (res.data[i].currencySymbol == "GO") {
          var go_address = res.data[i].currencyAddress;
          var gobal = res.data[i].balance;
          this.setState({ go_address: go_address });
          this.setState({ gobalance: gobal });
        }
      }
    });
  }

  getcurrency() {
    axios.get(url + "api/currencydata").then((res) => {
      console.log('res.data',res.data)
      var currencyarray = [];
      var cryptocurr = [];
      res.data.map((item, i) => {
        if (item.currencySymbol != "GO") {
          const name = item.currencySymbol;
          const value = item.currencySymbol;
          const obj = { value: name, label: value };
          currencyarray.push(obj);
          if(item.currencyName=="Ethereum" || item.currencyName=="Bitcoin"){
            cryptocurr.push(obj);
          }
        }
      });
      const objs = { value: "GBP", label: "GBP" };
      const sec = { value: "EUR", label: "EUR" };
      currencyarray.push(objs);
      currencyarray.push(sec);
      // console.log(currencyarray, "currencyarray");
      this.setState({ currencydata: currencyarray, email_assigned: true,cryptocurrency:cryptocurr });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleChange = (selectedOption) => {
    // console.log(`Option selected:`, selectedOption);

    this.setState({ currency: selectedOption.value });

    if (selectedOption.value == "ETH") {
      const buyamount = this.state.buyamount;
      var fullamount = buyamount * this.state.token_price_eth;
      this.setState({ amount: fullamount });
    }

    if (selectedOption.value == "BTC") {
      const buyamount = this.state.buyamount;
      var fullamount = buyamount * this.state.token_price_btc;
      this.setState({ amount: fullamount });
    }

    if (selectedOption.value == "USD") {
      const buyamount = this.state.buyamount;
      var fullamount = buyamount * this.state.token_price_usd;
      this.setState({ amount: fullamount });
    }

    if (selectedOption.value == "EUR") {
      const buyamount = this.state.buyamount;
      var fullamount = buyamount * this.state.token_price_eur;
      this.setState({ amount: fullamount });
    }

    if (selectedOption.value == "GBP") {
      const buyamount = this.state.buyamount;
      var fullamount = buyamount * this.state.token_price_gbp;
      this.setState({ amount: fullamount });
    }

    // this.setState({ currency: selectedOption.value });
  };

  buyChange = (e) => {
    if (this.state.currency == "ETH") {
      const buyamount = e.target.value;
      const price = this.state.token_price_eth;
      var fullamount = buyamount * price;
      this.setState({ amount: fullamount });
    }

    if (this.state.currency == "BTC") {
      const buyamount = e.target.value;
      const price = this.state.token_price_btc;
      var fullamount = buyamount * price;
      this.setState({ amount: fullamount });
    }

    if (this.state.currency == "USD") {
      const buyamount = e.target.value;
      const price = this.state.token_price_usd;
      var fullamount = buyamount * price;
      this.setState({ amount: fullamount });
    }

    if (this.state.currency == "EUR") {
      const buyamount = e.target.value;
      const price = this.state.token_price_eur;
      var fullamount = buyamount * price;
      this.setState({ amount: fullamount });
    }

    if (this.state.currency == "GBP") {
      const buyamount = e.target.value;
      const price = this.state.token_price_gbp;
      var fullamount = buyamount * price;
      this.setState({ amount: fullamount });
    }

    this.setState({ [e.target.id]: e.target.value });
  };

  BuyNow = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      if (this.state.currency == "ETH" || this.state.currency == "BTC") {
        this.setState({ loading: true });
        let fields = {};
        fields["buyamount"] = "";
        fields["amount"] = "";
        this.setState({ validation: fields });
        const Buydata = {
          id: this.props.auth.user.id,
          tokenvalue: this.state.buyamount,
          currency: this.state.currency,
          amount: this.state.amount,
        };
        console.log("buiyy", Buydata)
        axios
          .post(url + "api/tokenbuynew", Buydata)
          .then((res) => {
            // console.log("resin but", res);
            this.setState({ loading: false });

            if (res.data.status) {
              store.addNotification({
                title: "Wonderful!",
                message: res.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 1500,
                  onScreen: true,
                },
              });
              this.gethistory();
            } else {
              store.addNotification({
                title: "Error!",
                message: res.data.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 1500,
                  onScreen: true,
                },
              });
            }
          })
          .catch();
      } else {
        this.setState({ showDetails: true });
      }
    }
  };

  validateForm() {
    let fields = this.state.validation;
    let errors = {};
    errors["buyamount"] = "";
    errors["amount"] = "";
    let formIsValid = true;

    if (this.state.buyamount == "") {
      formIsValid = false;
      errors["buyamount"] = "*Please enter the Token Value";
    }
    if (this.state.buyamount < 0) {
      formIsValid = false;
      errors["buyamount"] = "*Amount is invalid";
    }
    if (this.state.amount == "") {
      formIsValid = false;
      errors["amount"] = "*Please enter the Amount";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  showDetails = (record) => {
    this.setState({ showDetails: true });
  };

  handleClosedetails = (record) => {
    this.setState({ showDetails: false });
  };
  senddata(payment) {
    this.setState({ paywaiting: true });

    const Buydata = {
      id: this.props.auth.user.id,
      tokenvalue: this.state.buyamount,
      currency: this.state.currency,
      amount: this.state.amount,
      payment: payment,
    };
    axios
      .post(url + "api/paymentconfirmed", Buydata)
      .then((res) => {
        this.setState({ paywaiting: false });
        this.setState({ showDetails: false });
        store.addNotification({
          title: "Wonderful!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
        this.gethistory();

      })
      .catch();
  }


  validateData() {
    let fields = this.state.validation;
    let errors = {};
    errors["firstname"] = "";
    errors["lastname"] = "";
    errors["email"] = "";
    errors["amount"] = "";
    let formIsValid = true;

    if (this.state.firstname == "") {
      formIsValid = false;
      errors["firstname"] = "*Please enter the firstname";
    }

    if (this.state.lastname == "") {
      formIsValid = false;
      errors["lastname"] = "*Please enter the lastname";
    }

    if (this.state.email == "") {
      formIsValid = false;
      errors["email"] = "*Please enter the email";
    }

    if (this.state.amount1 == "") {
      formIsValid = false;
      errors["amount1"] = "*Please enter the Amount";
    }
    if (this.state.amount1 < 0) {
      formIsValid = false;
      errors["amount1"] = "*Amount is invalid";
    }
    if (this.state.currency1 == "") {
      formIsValid = false;
      errors["currency1"] = "*Please select the Currency";
    }

    this.setState({
      errors1: errors,
    });
    return formIsValid;
  }

  togglepay = async (record) => {
    const id = this.props.auth.user.id;
    var response = await axios.post(url + "api/get-user", {id:id})

    let firstname = (response && response.data && response.data.result && 
      response.data.result.name)
    ?response.data.result.name:'';

    let lastname = (response && response.data && response.data.result 
      && response.data.result.lastname)
    ?response.data.result.lastname:'';

    let email = (response && response.data && response.data.result 
      && response.data.result.email)
    ?response.data.result.email:'';
    
    this.setState({ 
      shownetcent: !this.state.shownetcent,
      firstname:firstname,
      lastname:lastname, 
      email:email, 
    });
  };

  async onPaymentSubmit() {

    if (this.validateData()) {
      this.setState({ netcentloading: true });
      var tokenPage = "https://merchant.net-cents.com/widget/payment?data=";
      var data = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        amount: this.state.amount1,
        currency: this.state.currency1,
        userid:this.props.auth.user.id
      }

      console.log('data',data)

      var response = await axios.post(url + "api/get-token", data)
      if (response && response.status == 200 && response.data.token && response.data.token !="") {
        var token = tokenPage + response.data.token;
        console.log('token', token)
        window.open(token);
      } else {
        store.addNotification({
          title: "Error!",
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
      }
    }

  }

  handleChange1 = (selectedOption) => {

    this.setState({ currency1: selectedOption.value });
  };

  render() {
    const { errors, shownetcent,errors1 } = this.state;
    const { selectedOption } = this.state.currency;

    const onSuccess = (payment) => {
      // Congratulation, it came here means everything's fine!
      this.senddata(payment);
      // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
    };

    const onCancel = (data) => {
      // User pressed "cancel" or close Paypal's popup!
      // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    };

    const onError = (err) => {
      // The main Paypal's script cannot be loaded or somethings block the loading of that script!
      console.log("Error!", err);
      // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
      // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    };

    let env = "sandbox"; // you can set here to 'production' for production
    let currency = this.state.currency; // or you can set this value from your props or state
    let total = this.state.amount; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout

    const client = {
      sandbox:
        "AcRqUDNVstxPATul7tVJQQkEoBUUv3aAAyqGCAzZilYLGMoAX8_w9r8fYb7Ufgy-JWwy_SdHBNBATQe8",
      production: "YOUR-PRODUCTION-APP-ID",
    };
    return (
      <div>
        <UserHeader />
        <Modal
          show={this.state.showDetails}
          onHide={this.handleClosedetails}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Paypal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="popUpSpace">
              <div className="row mt-2">
                <div className="col-md-4"></div>
                <div className="col-md-3">

                  {this.state.paywaiting ? (
                    <div>
                      <Loader
                        type="Watch"
                        color="#FFBB2A"
                        height={50}
                        width={50}
                      /> Please Wait.....Don't Click on Anything
                    </div>
                  ) : (
                      <div>
                        <PaypalExpressBtn
                          env={env}
                          client={client}
                          currency={currency}
                          total={total}
                          onError={onError}
                          onSuccess={onSuccess}
                          onCancel={onCancel}
                        />
                      </div>
                    )}
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <section className="lgoinContent buyPage">
          <div className="container">
            <h2>
              <span>Buy</span>{" "}
              <a href="/Dashboard">
                <i className="fas fa-angle-left"></i> Dashboard
              </a>
            </h2>
            {!shownetcent && <div className="buyMain">
              <div className="row">
                <div className="col-lg-4 col-md-6"></div>
                <div className="col-lg-4 col-md-6">
                  <div className="buyAmt">
                    <form name="kycsubmit" onSubmit={this.BuyNow}>
                      <div className="input-group form-group">
                        <label>Buy amount</label>
                        <input
                          name="buyamount"
                          onChange={this.buyChange}
                          value={this.state.buyamount}
                          error={errors.buyamount}
                          id="buyamount"
                          type="text"
                          name="buyamount"
                          className={classnames("form-control", {
                            invalid: errors.buyamount,
                          })}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">GenONE</span>
                        </div>
                      </div>
                      <span className="text-danger">{errors.buyamount}</span>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Currency </label>
                            {/* <input type="text" name="" className="form-control" /> */}
                            <Select
                              value={selectedOption}
                              onChange={this.handleChange}
                              options={this.state.currencydata}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#ffba00",
                                  primary: "#ffba00",
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="form-group">
                            <label>
                              Amount <span className="float-right">Full</span>
                            </label>
                            <input
                              name="buyamount"
                              onChange={this.buyChange}
                              value={this.state.amount}
                              error={errors.amount}
                              id="amount"
                              type="text"
                              name="amount"
                              className={classnames("form-control", {
                                invalid: errors.amount,
                              })}
                            />
                            <span className="text-danger">{errors.amount}</span>{" "}
                          </div>
                        </div>
                      </div>

                      <p>
                        {this.state.currency == "ETH" ? (
                          <p>ETH Balance = {this.state.ethbalance} ETH</p>
                        ) : (
                            ""
                          )}
                        {this.state.currency == "BTC" ? (
                          <p>BTC Balance = {this.state.btcbalance} BTC</p>
                        ) : (
                            ""
                          )}
                        1 GO = {this.state.token_price_eth} ETH | 1 GO = {" "}
                        {this.state.token_price_btc} BTC
                        <br />1 GO = {this.state.token_price_usd} USD | 1 GO ={" "}
                        {this.state.token_price_gbp} GBP | 1 GO ={" "}
                        {this.state.token_price_eur} EUR
                      </p>
                      <div className="form-group">
                        {this.state.loading ? (
                          <div>
                            <Loader
                              type="Watch"
                              color="#FFBB2A"
                              height={50}
                              width={50}
                            />
                          </div>
                        ) : (
                            <div>
                              <input
                                className="btn btnType1  mr-3"
                                name=""
                                value="Buy Now"
                                readOnly
                                type="submit"
                              // onClick={() => this.BuyNow()}
                              />
                            </div>
                          )}
                      </div>

                      <div>
                        <a><img src={netcentlogo} onClick={() => this.togglepay()} width="200px" height="100px" /></a>
                      </div>

                      <div className="noteText">
                        {/* <h6>Note:</h6>
                        <p>
                          <span>
                            1. Tokens will send within admin confirmation
                          </span>
                          <span>
                            2. Confirmation mail will send immediately
                          </span>
                        </p> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            }
            {shownetcent &&

              <div className="buyMain">
                <div className="row">
                  <div className="col-lg-4 col-md-6"></div>
                  <div className="col-lg-4 col-md-6">
                    <div className="buyAmt">
                      <form name="kycsubmit">
                        <div className="input-group form-group">
                          <label>First name</label>
                          <input
                            name="firstname"
                            onChange={this.buyChange}
                            value={this.state.firstname}
                            error={errors1.firstname}
                            id="firstname"
                            type="text"
                            name="firstname"
                            className={classnames("form-control", {
                              invalid: errors1.firstname,
                            })}
                          />

                        </div>
                        {errors1.firstname && <span className="text-danger">{errors1.firstname}</span>}
                        <div className="input-group form-group">
                          <label>Last name</label>
                          <input
                            name="lastname"
                            onChange={this.buyChange}
                            value={this.state.lastname}
                            error={errors1.lastname}
                            id="lastname"
                            type="text"
                            name="lastname"
                            className={classnames("form-control", {
                              invalid: errors1.lastname,
                            })}
                          />

                        </div>
                        {errors1.lastname && <span className="text-danger">{errors1.lastname}</span>}
                        <div className="input-group form-group">
                          <label>Email</label>
                          <input
                            name="email"
                            onChange={this.buyChange}
                            value={this.state.email}
                            error={errors1.email}
                            id="email"
                            type="text"
                            name="email"
                            className={classnames("form-control", {
                              invalid: errors1.email,
                            })}
                          />

                        </div>
                        {errors1.email && <span className="text-danger">{errors1.email}</span>}
                        <div className="input-group form-group">
                          <label>Buy token</label>
                          <input
                            name="amount1"
                            onChange={this.buyChange}
                            value={this.state.amount1}
                            error={errors1.amount1}
                            id="amount1"
                            type="text"
                            name="amount1"
                            className={classnames("form-control", {
                              invalid: errors1.amount1,
                            })}
                          />

                          <div className="input-group-append">
                            <span className="input-group-text">GenONE</span>
                          </div>
                        </div>
                        {errors1.amount1 && <span className="text-danger">{errors1.amount1}</span>}

                        <div className="row">
                          <div className="col-md-8">
                            <div className="form-group">
                              <label>Currency </label>
                              {/* <input type="text" name="" className="form-control" /> */}
                              <Select
                                value={selectedOption}
                                onChange={this.handleChange1}
                                options={this.state.cryptocurrency}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#ffba00",
                                    primary: "#ffba00",
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>
                        {errors1.currency1 && <span className="text-danger">{errors1.currency1}</span>}

                        <div className="form-group">
                          {this.state.netcentloading ? (
                            <div>
                              <Loader
                                type="Watch"
                                color="#FFBB2A"
                                height={50}
                                width={50}
                              />
                            </div>
                          ) : (
                              <div>
                                <input
                                  className="btn btnType1  mr-3"
                                  name=""
                                  value="Buy Token"
                                  readOnly
                                  type="button"
                                  onClick={() => this.onPaymentSubmit()}
                                />
                              </div>
                            )}
                        </div>
                        <div>
                          <a><img src={netcentlogo} onClick={() => this.togglepay()} width="200px" height="100px" /></a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            }

            <h4>Buy History</h4>
            <div className="table-responsive">
              <table className="table borderedTable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Token </th>
                    <th>Amount</th>
                    <th>Currency</th>
                    <th>Trans. ID</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.history.map((answer, i) => {
                    var noHTML = /(<([^>]+)>)/gi; // regex for removing HTML tags
                    var clean_desc = answer.transactionHash.replace(noHTML, ""); // remove all html tags
                    var echo_val = clean_desc.substring(0, 10);
                    var link =
                      "https://etherscan.io/tx/" +
                      answer.transactionHash;
                    return (
                      <tr>
                        <td>
                          {" "}
                          {moment(answer.created_date).format(
                            "DD-MM-YYYY  h:mm a "
                          )}
                        </td>
                        <td>{answer.token_receive}</td>
                        <td>{answer.amount}</td>
                        <td>{answer.currency}</td>
                        <td>
                          <a target="_blank" href={link}>
                            {echo_val}....
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <UserFooter />
      </div>
    );
  }
}

Buy.propTypes = {
  // logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Buy);
