module.exports = {
  // demo
  // baseUrl: "http://18.218.61.140:2053/",

  //live site
  // baseUrl: "http://172.104.166.247:2053/",
  // baseUrl : "https://genesisonetoken.com/",
  baseUrl: "https://genesisonetoken.com/",
//
  // baseUrl : "http://localhost:2053/",

  // imageUrl : "https://localhost.com/",
  // socketUrl : "http://localhost:2053/",
  // socketUrl: "http://18.218.61.140:2053/",
  // refurl: "http://18.218.61.140/",

  refurl: "https://genesisonetoken.com/",

  // refurl: "http://localhost:3000/",

  Recaptchakey: "6LdpeoQUAAAAAHwFEDfpcA-W5-leSH8548lZWWeb", //local
};
