import React, { Component, useState, useRef, useEffect } from "react";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import keys from "../actions/config";
import { store } from "react-notifications-component";
import Select from "react-select";
import classnames from "classnames";
import * as moment from "moment";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Modal, Button } from "react-bootstrap/";
// import { PayPalButton } from "react-paypal-button-v2";
import PaypalExpressBtn from "react-paypal-express-checkout";

import netcentlogo from "../images/netcentlogo.png";

const url = keys.baseUrl;

class PaymentSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
   
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  componentDidMount() {
    var external_id = this.getUrlParameter('external_id');
    this.getpaymentId(external_id,this.props.auth.user.id);
  }

  async getpaymentId(external_id,userid){
    var setInter;
    setInter = setInterval(async function () {
      if(external_id){
        var data ={
        userid:userid,
        external_id:external_id,
        }
        var response = await axios.post(url + "api/check-payment", data)
       
        if(response.status==200){
          setInter ="";
          window.location.href="/Buy"
        }
      }else{

      }

  }, 4000);
 
   
  }

  getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};


  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };


  async onPaymentSubmit() {

   
      this.setState({ netcentloading: true });
      var tokenPage = "https://merchant.net-cents.com/widget/payment?data=";
      var data = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        amount: this.state.amount1,
        currency: this.state.currency1,
        userid:this.props.auth.user.id
      }

      console.log('data',data)

      var response = await axios.post(url + "api/get-token", data)
      if (response && response.status == 200) {
        var token = tokenPage + response.data.token;
        console.log('token', token)
        window.open(token, '_blank');
      } else {
        //toastAlert('error', response.message, 'token');
      }
   

  }

  render() {
    
    return (
      <div>
        <UserHeader />
        
        <section className="lgoinContent buyPage">
          <div className="container">
              <div className="buyMain">
                <div className="row">
                  <div className="col-lg-4 col-md-6"></div>
                  <div className="col-lg-4 col-md-6">
                    <div className="buyAmt">
                      <form name="kycsubmit">
                        <div className="input-group form-group">
                          <p>Your token has been processing...!</p>

                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </section>
        <UserFooter />
      </div>
    );
  }
}

PaymentSuccess.propTypes = {
  // logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(PaymentSuccess);
