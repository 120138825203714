import React, { Component } from "react";
import { Link,withRouter } from "react-router-dom";
import TradeHeader from "./TradeHeader";
import Footer from "./Footer";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import keys from "../actions/config";
import {resetUser} from '../actions/useractions'
import axios from "axios";

const url = keys.baseUrl;

class resetpassword extends Component {
  constructor() {
    super();
    this.state = {
      _id: "",
      password: "",
      password2: "",
      errors: {}
    };
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };


	componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
        this.setState({
            errors: nextProps.errors
        });
    }
    if (nextProps.auth.forgotuser !== undefined
        && nextProps.auth.forgotuser.data !== undefined
        && nextProps.auth.forgotuser.data.message !== undefined ) {
        store.addNotification({
          title: "Wonderful!",
          message: nextProps.auth.forgotuser.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        nextProps.auth.forgotuser = "";
        this.props.history.push("/Login");
    }
}


  onSubmit = e => {
    e.preventDefault();
    if(this.state.password == this.state.password2){
      const resetuser = {
        _id: this.props.match.params.id,
        password: this.state.password,
        password2: this.state.password2
      };
      console.log("password change",resetuser)
      // this.props.resetUser(resetuser);
      axios
      .post(url + "api/resetpassword", resetuser)
      .then((res) => {
        console.log("resin but", res);
        if (res.data.status) {
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        } else {
          store.addNotification({
            title: "Error!",
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
      })
      .catch();
    }
    else{
      store.addNotification({
        title: "Error!",
        message: "Passwords does not Match",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });

    }
    
  };



  render() {
    const { errors } = this.state;

    return (
      <div>
        <TradeHeader />
        <section className="forgotPasswordBg">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-7 mx-auto">
                <div className="loginRegRight">
                  <div className="formBox">
                    <h2 className="text-center mb-3">ResetPassword</h2>
                    <p className="text-center">
                      Please Enter the New credentials
                    </p>
                  
                    <form noValidate onSubmit={this.onSubmit}>
                      <div className="form-group">
                        <label>New Password</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.password}
                          error={errors.password}
                          id="password"
                          type="password"
                          className={classnames("form-control", {
                            invalid: errors.password
                          })}
                        />
                        <span className="text-danger">{errors.password}</span>
                      </div>
                      <div className="form-group ">
                        <label>Confirm Password</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.password2}
                          error={errors.password2}
                          id="password2"
                          type="password"
                          className={classnames("form-control", {
                            invalid: errors.password2
                          })}
                        />
                        <span className="text-danger">{errors.password2}</span>
                      </div>
                      <p className="text-center">
                        <button
                          className="btn btnType1"
                          type="submit"
                          // onClick={() => this.ResetPass()}
                        >
                          Submit
                        </button>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

resetpassword.propTypes = {
  resetUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, {
  resetUser
})(withRouter(resetpassword));

