import React, { Component } from "react";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import keys from "../actions/config";
import { store } from "react-notifications-component";
import Select from "react-select";
import classnames from "classnames";

import * as moment from "moment";

const url = keys.baseUrl;

class Transactionhistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      history: [],
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }

    console.log("nextProps in KyC", nextProps.auth);
    if (
      nextProps.auth.updatenewuser !== undefined &&
      nextProps.auth.updatenewuser.data !== undefined &&
      nextProps.auth.updatenewuser.data.message !== undefined
    ) {
      store.addNotification({
        title: "Wonderful!",
        message: nextProps.auth.updatenewuser.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
      // this.getData();
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const id = this.props.auth.user.id;
    axios.get(url + "api/transactionhistory/" + id).then((res) => {
      console.log("result", res.data);
      this.setState({ history: res.data });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <UserHeader />
        <section className="lgoinContent buyPage">
          <div className="container">
            <h2 className="mb-0">
              <span>Transaction History</span>{" "}
              <a href="/Dashboard">
                <i className="fas fa-angle-left"></i> Dashboard
              </a>
            </h2>
            {/* <h4>Buy History</h4> */}
            <div className="table-responsive">
              <table className="table borderedTable">
                <thead>
                  <tr>
                    <th>Date</th>
                    {/* <th>From</th> */}
                    {/* <th>To</th> */}
                    <th>Trans. ID</th>
                    <th>Transfer Type</th>
                    <th>Amount</th>
                    <th>Currency</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.history.map((answer, i) => {
                     var noHTML = /(<([^>]+)>)/ig; // regex for removing HTML tags
                     var clean_desc = answer.txid.replace(noHTML, ''); // remove all html tags
                     var echo_val = clean_desc.substring(0,15)
                     var link = "https://ropsten.etherscan.io/tx/"+answer.txid
                    return (
                      <tr>
                        <td>
                          {" "}
                          {moment(answer.created_date).format(
                            "DD/MM/YYYY  h:mm a "
                          )}
                        </td>
                        {/* <td>{answer.fromaddress}</td> */}
                        {/* <td>{answer.toaddress}</td> */}
                        <td><a  target="_blank" href={link}>{echo_val}....</a></td>
                        <td>{answer.transferType}</td>
                        <td>
                          {answer.amount}
                        </td>
                        <td>{answer.currency}</td>
                      </tr>
                    );
                  })}

                  {/* <tr>
                    <td> date</td>
                    <td>text</td>
                    <td data-tip="test">test</td>
                    <td>text</td>
                    <td>tere</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <UserFooter />
      </div>
    );
  }
}

Transactionhistory.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Transactionhistory);
