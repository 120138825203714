import React, { Component } from "react";
import { Link } from "react-router-dom";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import Select from "react-select";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { store } from "react-notifications-component";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import keys from "../actions/config";
import { withRouter } from "react-router-dom";
import * as moment from "moment";

const url = keys.baseUrl;
const refurl =keys.refurl

class Referral extends Component {
  constructor() {
    super();
    this.state = {
      user: {},
      referencecode_link: "",
      value: "",
      settings: "",
      social_link1: "",
      social_link3: "",
      social_link4: "",
      history: [],
      referencecode: "",
      referralcontent: "",
      bonus: 0,
      bonus_balance: 0,
      copied: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  componentDidMount() {
    this.getData();
    this.getreferraldata();
  }
  getreferraldata() {
    const id = this.props.auth.user.id;
    axios
      .get(url + "api/referralhistory/" + id)
      .then((res) => {
        // console.log("History ",res.data)
        this.setState({ history: res.data });
      })
      .catch();
  }
  getData() {
    const id = this.props.auth.user.id;
    axios
      .get(url + "api/referraldata/" + id)
      .then((res) => {
		var link =refurl+"register?ref="+res.data.referencecode;
		this.setState({
			referencecode_link:link
		});
        this.setState(res.data);
      })
      .catch();
  }

  render() {
    return (
      <div>
        <UserHeader />
        <section className="lgoinContent SettingsPage">
          <div className="container">
            <h2>Referral Program</h2>
            <div className="settingsBox">
              <div className="row">
                <div className="col-md-3 col-sm-4">
                  <div className="form-group">
                    <label>Referral Code</label>
                    <input
                      name="referencecode"
                      className="form-control text-center noBorder"
                      placeholder=""
                      value={this.state.referencecode}
                      type="text"
                      id="referencecode"
                    />
                  </div>
                </div>
                <div className="col-md-9 col-sm-8">
                  <div className="form-group input-group referralLinkBox">
                    <label>Referral Link</label>
                    <input
                      name=""
                      className="form-control"
                      placeholder=""
                      value={this.state.referencecode_link}
                      type="text"
                    />
                    <div className="input-group-append">
                      {/* <input
                        className="btn buttonType1 px-4"
                        type="button"
                        value="Copy Link"
                      /> */}
					  			<CopyToClipboard text={this.state.referencecode_link}
          onCopy={() => this.setState({copied: true})}>
          <button type="button" className="btn buttonType1 px-4">Copy Link</button>
        </CopyToClipboard>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="referralSocial">
                    <h6>Refer from our social media links</h6>
                    <ul>
                      <li className="fb">
                        <a href="https://www.facebook.com/RealEGGroup/" target="_blank">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="tw">
                        <a href="https://twitter.com/RealEGGroup" target="_blank">
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="te">
                        <a href="https://t.me/GenesisOne" target="_blank">
                          <i class="fab fa-telegram-plane"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="form-group">
                    <label>Registered Referrals</label>
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      value={this.state.history.length}
                    />
                  </div>
                </div>
                {/* <div className="col-md-4 col-sm-4">
                  <div className="form-group inputWithText">
                    <label>Balance</label>
                    <input
                      name=""
                      className="form-control"
                      placeholder=""
                      value="0"
                      type="text"
                    />
                  </div>
                </div> */}
              </div>
            </div>

            <h4>Referral History</h4>
            <div className="table-responsive mb-3">
              <table className="table borderedTable">
                <thead>
                  <tr>
                    <th>Registration Time</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Account Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.history.map((answer, i) => {
                    return (
                      <tr>
                        <td>
                          {" "}
                          {moment(answer.date).format("DD-MM-YYYY  h:mm a ")}
                        </td>
                        <td>
                          {answer.name}
                          {""}
                          {answer.lastname}
                        </td>
                        <td>{answer.email}</td>
                        <td>{answer.active}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* <h4>Referral Program</h4>
            <div className="noteTextReferral">
              <p>
                By default, all users are added to the Referral Program. Refer
                GenesisOne to someone and receive a BTC Bonus.
              </p>
              <p>
                You will receive a 2% flat BTC bonus on your referral each
                deposit into his account.
              </p>
              <p>
                Example:- If your referral deposited 1 BTC you will get 0.02 BTC
                Bonus. You can use the BTC bonus to trade and withdraw the
                profits you make in trade.
              </p>
            </div> */}
          </div>
        </section>
        <UserFooter />
      </div>
    );
  }
}

Referral.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, {})(withRouter(Referral));
