import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo.png";
import HeadIconCoin from "../images/headiconCoin.png";
import HeadIconWithdraw from "../images/headiconWithdraw.png";
import HeadIconDeposite from "../images/headiconDeposite.png";
import HeadIconTransfer from "../images/headiconTransfer.png";
import HeadIconInvest from "../images/headiconInvest.png";
import { connect } from "react-redux";
import { logoutUser } from "../actions/authactions";
import PropTypes from "prop-types";
import axios from "axios";
import keys from "../actions/config";
import { store } from "react-notifications-component";
import ReactTooltip from "react-tooltip";

const url = keys.baseUrl;

class UserHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      name: "",
      lastname: "",
      address1: "",
      address2: "",
      email: "",
      city: "",
      pincode: "",
      balance:0,
      phonenumber: "",
      country: "",
      gobal:0,
      errors: {},
    };
  }

  componentDidMount() {
    this.getData();
    this.getBalance();
  }

  getBalance() {
    const id = this.props.auth.user.id;
    axios
      .get(url + "api/balanceheader/" + id)
      .then((res) => {
        this.setState(res.data);
        this.setState({gobal:res.data.balance.toFixed(2)})
      })
      .catch();
  }

  getData() {
    if (this.props.auth !== undefined && this.props.auth.user !== undefined) {
      const id = this.props.auth.user.id;
      axios
        .get(url + "api/userget/" + id)
        .then((res) => {
          this.setState(res.data);
        })
        .catch();
    }
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    store.addNotification({
      title: "Wonderful!",
      message: "Loggedout Successfully",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
    });
    this.props.logoutUser();
  };

  render() {
    return (
      <div>
        <ReactTooltip place="bottom" type="dark" effect="float" />
        <nav
          className="navbar navbar-expand-xl customNav wow fadeInDown loginNavbar"
          data-wow-delay=".2s"
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img src={Logo} alt="EG GROUP HOLDINGS LIC" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fas fa-bars"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <h6>
                    Welcome,{" "}
                    <b>
                      {this.state.name} {this.state.lastname}
                    </b>
                    <br />
                    Your account Balance is <span>{this.state.gobal} GenONE</span>
                  </h6>
                </li>
                <li className="nav-item">
                  <Link to="/Buy" className="nav-link" data-tip="Buy GenOne">
                    <img src={HeadIconCoin} />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/Deposit" className="nav-link" data-tip="Deposit">
                    <img src={HeadIconWithdraw} />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/Withdraw" className="nav-link" data-tip="Withdraw">
                    <img src={HeadIconDeposite} />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/Transfer" className="nav-link" data-tip="Transfer">
                    <img src={HeadIconTransfer} />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/Invest" className="nav-link" data-tip="Invest">
                    <img src={HeadIconInvest} />
                  </Link>
                </li>
                <li className="nav-item">
                  <div className="todayPrice">1 GenONE = $1 USD</div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    My Account
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link to="/Settings" className="dropdown-item">
                      Settings
                    </Link>
                    <Link to="/Kyc" className="dropdown-item">
                      KYC
                    </Link>
                    <Link to="/Support" className="dropdown-item">
                      Support
                    </Link>
                    <Link to="/Referral" className="dropdown-item">
                      Referral
                    </Link>
                    <Link to="/LoginHistory" className="dropdown-item">
                      History
                    </Link>
                    {/* <Link to="" className="dropdown-item">Logout</Link> */}
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={this.onLogoutClick}
                    >
                      {" "}
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

UserHeader.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(UserHeader);
