import React, { Component } from "react";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import classnames from "classnames";
import keys from "../actions/config";
import axios from "axios";
import * as moment from "moment";

const url = keys.baseUrl;

class Transfer extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      receiveraddress: "",
      tokenamount: "",
      finalamount: "",
      eth_address: "",
      ethbalance: "",
      transaction_fee:0,
      validation: {},
      history:[],
      errors: {},
    };
  }

  componentDidMount() {
    this.getData();
    this.getvalue();
    this.gethistory();


  }
  getvalue() {
    axios.get(url + "api/feedata").then((res) => {
      this.setState(res.data[0]);
    });
  }

  gethistory() {
    const id = this.props.auth.user.id;
    axios.get(url + "api/transferhistory/" + id).then((res) => {
      // console.log("history", res.data);
      this.setState({ history: res.data });
    });
  }

  getData() {
    const id = this.props.auth.user.id;
    axios.get(url + "api/getseperateaddress/" + id).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].currencySymbol == "ETH") {
          var eth_address = res.data[i].currencyAddress;
          var ethbal = res.data[i].balance;
          this.setState({ eth_address: eth_address });
          this.setState({ ethbalance: ethbal });
        }
      }
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.validateForm()) {
      let fields = {};
      fields["tokenamount"] = "";
      fields["receiveraddress"] = "";
      this.setState({ validation: fields });
      const newtransfertoken = {
        id: this.props.auth.user.id,
        tokenamount:this.state.tokenamount,
        receiveraddress:this.state.receiveraddress,
        finalamount:this.state.finalamount,
        transactionfee:this.state.transaction_fee
      };
      axios
      .post(url + "api/tokentransferrequest", newtransfertoken)
      .then((res) => {
        if(res.data.status){
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true
            }
          });
          this.gethistory();
         }else{
           store.addNotification({
             title: "Error!",
             message: res.data.message,
             type: "danger",
             insert: "top",
             container: "top-right",
             animationIn: ["animated", "fadeIn"],
             animationOut: ["animated", "fadeOut"],
             dismiss: {
               duration: 1500,
               onScreen: true
             }
           });
         }
      })
      .catch();
    }
  };

  validateForm() {
    let fields = this.state.validation;
    let errors = {};
    errors["tokenamount"] = "";
    errors["receiveraddress"] = "";
    let formIsValid = true;

    if (this.state.tokenamount == "") {
      formIsValid = false;
      errors["tokenamount"] = "*Please enter the Token Amount";
    }
    if (this.state.receiveraddress == "") {
      formIsValid = false;
      errors["receiveraddress"] = "*Please enter the Receiver Address";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  render() {
    const { errors } = this.state;

    return (
      <div>
        <UserHeader />
        <section className="lgoinContent buyPage">
          <div className="container">
            <h2>
              <span>Transfer</span>{" "}
              <a href="/Dashboard">
                <i className="fas fa-angle-left"></i> Dashboard
              </a>
            </h2>
            <div className="buyMain">
              <div className="row">
                <div className="col-md-4 mx-auto">
                  <form name="buytoken" onSubmit={this.onSubmit}>
                    <div className="buyAmt">
                      <div className="input-group form-group">
                        <label>
                          Amount <span className="float-right">
                           + {this.state.transaction_fee} ETH
                          </span>
                        </label>
                        <input
                          name="subject"
                          onChange={this.onChange}
                          value={this.state.tokenamount}
                          error={errors.tokenamount}
                          id="tokenamount"
                          type="text"
                          name="tokenamount"
                          className={classnames("form-control", {
                            invalid: errors.tokenamount,
                          })}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">GenONE</span>
                        </div>
                      </div>
                      <span className="text-danger">{errors.tokenamount}</span>

                      <div className="form-group">
                        <div className="form-group">
                          <label>Receiver’s GenONE token wallet address</label>
                          <input
                            name="receiveraddress"
                            onChange={this.onChange}
                            value={this.state.receiveraddress}
                            error={errors.receiveraddress}
                            id="receiveraddress"
                            type="text"
                            name="receiveraddress"
                            className={classnames("form-control", {
                              invalid: errors.receiveraddress,
                            })}
                          />{" "}
                          <span className="text-danger">{errors.receiveraddress}</span>{" "}
                        </div>
                      </div>
                      {/* <div className="input-group form-group darkInput">
                        <label>
                          Final send amount{" "}
                          <span className="feeText float-right">
                           + {this.state.transaction_fee}%
                          </span>
                        </label>
                        <input
                          name="finalamount"
                          onChange={this.onChange}
                          value={this.state.finalamount}
                          error={errors.finalamount}
                          id="finalamount"
                          type="text"
                          name="finalamount"
                          className={classnames("form-control", {
                            invalid: errors.finalamount,
                          })}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">GenONE</span>
                        </div>
                        <span className="text-danger">{errors.finalamount}</span>{" "}

                      </div> */}
                      <div className="form-group text-center">
                        <input
                          className="btn btnType1"
                          name=""
                          value="Transfer Now"
                          type="submit"
                        />
                      </div>
                      <div className="noteText">
                        <h6>Note:</h6>
                        <p>
                          <span>
                            1. Fee will be deducted from your ETH balance
                          </span>
                          <span>
                            2. Tokens will send within admin confirmation
                          </span>
                          <span>
                            3. Confirmation mail will send immediately
                          </span>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <h4>Transfer History</h4>
            <div className="table-responsive">
              <table className="table borderedTable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>No of Token</th>
                    <th>Receiver Address</th>
                    <th>Trans. ID</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.history.map((answer, i) => {
                    var noHTML = /(<([^>]+)>)/gi; // regex for removing HTML tags
                    var clean_desc = answer.transactionId.replace(noHTML, ""); // remove all html tags
                    var echo_val = clean_desc.substring(0, 10);
                    var link = "https://ropsten.etherscan.io/tx/" + answer.transactionId;
                    return (
                      <tr>
                        <td>
                          {" "}
                          {moment(answer.created_date).format(
                            "DD-MM-YYYY  h:mm a "
                          )}
                        </td>
                        <td>
                          {answer.tokenamount}
                        </td>
                        <td>{answer.receiveraddress}</td>
                        <td><a target="_blank" href={link}>
                            {echo_val}....
                          </a></td>
                          <td>{answer.status}</td>
                      </tr>
                    );
                  })}

                </tbody>
              </table>
            </div>
          </div>
        </section>
        <UserFooter />
      </div>
    );
  }
}

Transfer.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(Transfer));
