import React, { Component } from "react";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import ReactDOM from "react-dom";
import Select from "react-select";
import ImagesUploader from "react-images-uploader";
import "react-images-uploader/styles.css";
import "react-images-uploader/font.css";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { store } from "react-notifications-component";
import keys from "../actions/config";
import { kycadd } from "../actions/useractions";
import * as moment from "moment";

const url = keys.baseUrl;

const idproof = [
  { value: "Social Security ID Card", label: "Social Security ID Card" },
  { value: "Passport", label: "Passport" },
  { value: "Drivers Licence", label: "Drivers Licence" },
];

const adproof = [
  {
    value: "Electricity / Gas / Water tax",
    label: "Electricity / Gas / Water tax",
  },
  { value: "Bank Statement", label: "Bank Statement" },
  { value: "Property tax / Land deed", label: "Property tax / Land deed" },
  { value: "Driver License", label: "Driver License" },
  { value: "National ID  with Address", label: "National ID with Address" },

];



const photoproof = [
  { value: "Social Security ID Card", label: "Social Security ID Card" },
  { value: "Passport", label: "Passport" },
  { value: "Drivers Licence", label: "Drivers Licence" },
];

class Kyc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      Idproof: "",
      IdType: "",
      AddressType: "",
      PhotoIDType: "",
      addressproof: "",
      photoproof: "",
      idfront: null,
      idback: null,
      Addfront: null,
      photofront: null,
      kycdate: "",
      viewidfront: null,
      viewidback: null,
      viewaddressphoto: null,
      viewphoto: "",
      selectedOption: "",
      IDproofno: "",
      Addressproofno: "",
      Phototypeno: "",
      IDstatus: "",
      Addresstatus: "",
      Photostatus: "",
      verifiedstatus: "",
      errors: {},
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    console.log("this,propss", this.props);

    const id = this.props.auth.user.id;
    axios
      .get(url + "api/userget/" + id)
      .then((res) => {
        console.log(res, "ressdcfdvcfvfv");
        this.setState(res.data);
        this.setState({
          IDtype: {
            value: res.data.IDtype,
            label: res.data.IDtype,
          },
        });
        this.setState({
          Addresstype: {
            value: res.data.Addresstype,
            label: res.data.Addresstype,
          },
        });

        // this.setState({
        //   Phototype: {
        //     value: res.data.Phototype,
        //     label: res.data.Phototype
        //   }
        // });
        if (res.data.name == "") {
          this.props.history.push("/Settings");
        }
      })
      .catch();
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleChangeIdfront = (e) => {
    this.setState({
      idfront: e.target.files[0],
      viewidfront: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleChangeIdback = (e) => {
    this.setState({
      idback: e.target.files[0],
      viewidback: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleChangephoto = (e) => {
    this.setState({
      photofront: e.target.files[0],
      viewphoto: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleChangeaddress = (e) => {
    this.setState({
      Addfront: e.target.files[0],
      viewaddressphoto: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleChange1 = (selectedOption1) => {
    // this.setState({ IdType: selectedOption.value });
    this.setState({
      IDtype: {
        value: selectedOption1.value,
        label: selectedOption1.value,
      },
    });
  };

  handleChange2 = (selectedOption2) => {
    // this.setState({ AddressType: selectedOption.value });
    this.setState({
      Addresstype: {
        value: selectedOption2.value,
        label: selectedOption2.value,
      },
    });
  };

  // handleChange3 = selectedOption => {
  //   // this.setState({ Phototype: selectedOption.value });
  //   this.setState({
  //     Phototype: {
  //       value: selectedOption.value,
  //       label: selectedOption.value
  //     }
  //   });
  // };

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }

    console.log("nextProps in KyC", nextProps.auth);
    if (
      nextProps.auth.updatenewuser !== undefined &&
      nextProps.auth.updatenewuser.data !== undefined &&
      nextProps.auth.updatenewuser.data.message !== undefined
    ) {
      store.addNotification({
        title: "Wonderful!",
        message: nextProps.auth.updatenewuser.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
      this.getData();
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      fields["IDproofno"] = "";
      fields["Addressproofno"] = "";
      // fields["Phototypeno"] = "";
      this.setState({ validation: fields });

      const data = new FormData();
      data.append("id", this.props.auth.user.id);
      data.append("IDproofno", this.state.IDproofno);
      data.append("IdType", this.state.IDtype.value);
      data.append("Addressproofno", this.state.Addressproofno);
      data.append("AddressType", this.state.Addresstype.value);
      // data.append("Phototypeno", this.state.Phototypeno);
      // data.append("PhotoIDType", this.state.Phototype.value);
      data.append("idfront", this.state.idfront);
      data.append("idback", this.state.idback);
      data.append("photofront", this.state.photofront);
      data.append("Addfront", this.state.Addfront);
      this.props.kycadd(data);
    }
  };

  validateForm() {
    let fields = this.state.validation;
    let errors = {};
    errors["IDproofno"] = "";
    errors["Addressproofno"] = "";
    // errors["Phototypeno"] = "";
    let formIsValid = true;

    if (this.state.IDproofno == "") {
      formIsValid = false;
      errors["IDproofno"] = "*Please enter the Id Number";
    }
    // if (this.state.Phototypeno == "") {
    //   formIsValid = false;
    //   errors["Phototypeno"] = "*Please enter the Id Number";
    // }

    if (this.state.Addressproofno == "") {
      formIsValid = false;
      errors["Addressproofno"] = "*Please enter the Id Number";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  onSkipbutton() {
    this.props.history.push("/Dashboard");
  }

  ResubmitKYCPhoto = (e) => {

    const Data = {
      userid: this.props.auth.user.id,
      Type:"Photostatus"
    };
    axios
        .post(url+"api/kyc-resubmit", Data)
        .then(res => {
            if (res.status) {
              store.addNotification({
                title: "Wonderful!",
                message: res.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 1500,
                  onScreen: true,
                },
              });
              this.getData();
            }else{
              store.addNotification({
                title: "Error!",
                message: "Unable to Process now",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 1500,
                  onScreen: true,
                },
              });
            }
        })
        .catch();
  }


  ResubmitKYCAddress = (e) => {

    const Data = {
      userid: this.props.auth.user.id,
      Type:"Addresstatus"
    };
    axios
        .post(url+"api/kyc-resubmit", Data)
        .then(res => {
            if (res.status) {
              store.addNotification({
                title: "Wonderful!",
                message: res.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 1500,
                  onScreen: true,
                },
              });
              this.getData();
            }else{
              store.addNotification({
                title: "Error!",
                message: "Unable to Process now",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 1500,
                  onScreen: true,
                },
              });
            }
        })
        .catch();
  }

  ResubmitKYCID = (e) => {

    const Data = {
      userid: this.props.auth.user.id,
      Type:"IDstatus"
    };
    axios
        .post(url+"api/kyc-resubmit", Data)
        .then(res => {
            if (res.status) {
              store.addNotification({
                title: "Wonderful!",
                message: res.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 1500,
                  onScreen: true,
                },
              });
              this.getData();
            }else{
              store.addNotification({
                title: "Error!",
                message: "Unable to Process now",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 1500,
                  onScreen: true,
                },
              });
            }
        })
        .catch();
  }

    //
    // ResubmitKYC = (e) => {
    //
    //   var Type = e.target.id;
    //   const Data = {
    //     userid: this.props.auth.user.id,
    //     Type: Type,
    //   };
    //   console.log("ResubmitKYC",Type);
    //   axios
    //       .post(url+"api/kyc-resubmit", Data)
    //       .then(res => {
    //           if (res.status) {
    //             store.addNotification({
    //               title: "Wonderful!",
    //               message: res.data.message,
    //               type: "success",
    //               insert: "top",
    //               container: "top-right",
    //               animationIn: ["animated", "fadeIn"],
    //               animationOut: ["animated", "fadeOut"],
    //               dismiss: {
    //                 duration: 1500,
    //                 onScreen: true,
    //               },
    //             });
    //             this.getData();
    //           }else{
    //             store.addNotification({
    //               title: "Error!",
    //               message: "Unable to Process now",
    //               type: "danger",
    //               insert: "top",
    //               container: "top-right",
    //               animationIn: ["animated", "fadeIn"],
    //               animationOut: ["animated", "fadeOut"],
    //               dismiss: {
    //                 duration: 1500,
    //                 onScreen: true,
    //               },
    //             });
    //           }
    //       })
    //       .catch();
    // }

  render() {
    const { selectedOption1,selectedOption2, errors } = this.state;

    return (
      <div>
        <UserHeader />
        <section className="lgoinContent kycPage">
          <div className="container">
            <form name="kycsubmit" onSubmit={this.onSubmit}>
              <h2>
                <span>KYC</span>{" "}
                {this.state.kycdate ? (
                  <small>
                    Registered on{" "}
                    <span>
                      {" "}
                      {moment(this.state.kycdate).format("DD-MM-YYYY  h:mm a ")}
                    </span>
                  </small>
                ) : (
                  <small>Not Yet Registered</small>
                )}{" "}
                {this.state.verifiedstatus == "Verified" ? (
                  ""
                ) : (
                  <button
                    className="btn btnType1 float-right"
                    onClick={() => this.onSkipbutton()}
                  >
                    Skip Now
                  </button>
                )}
                {/* <button
                  className="btn btnType1 float-right"
                  onClick={() => this.onSkipbutton()}
                >
                  Skip Now
                </button> */}
              </h2>

              {this.state.verifiedstatus == "Verified" ? (
                <div>
                  <centre>
                    <h4 className="modal-title">
                      KYC has been Verified Succesfully
                    </h4>
                  </centre>
                </div>
              ) : (
                ""
              )}
              <p>
                * Only verified members can benefit from minted or burnable
                tokens after admin approves, if all the user’s tokens lost or
                stolen.
              </p>
              <div className="proofBox">
                <div className="row">
                  <div className="col-md-4">
                    {this.state.IDstatus == "Pending" ? (
                      <div>
                      <div className="proofContentText">
                        <h4>1. ID Proof</h4>
                        <h5>Waiting for Approval</h5>
                        <h7>ID Type : {this.state.IDtype.value}</h7>
                        <br></br>
                        <h7>ID Proof Number :{this.state.IDproofno}</h7>
                      </div>
                      <input
                        className="btn btnType1"
                        name=""
                        value="Resubmit ID"
                        readOnly
                        onClick={() => {
                          this.ResubmitKYCID();
                        }}
                      />

                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.IDstatus == "Verified" ? (
                      <div className="proofContentText">
                        <h4>
                          1. ID Proof{" "}
                          <sup
                            className="ml-2"
                            style={{ backgroundColor: "green" }}
                          >
                            Verified
                          </sup>
                        </h4>
                        <h7>ID Type : {this.state.IDtype.value}</h7>
                        <br></br>
                        <h7>ID Proof Number :{this.state.IDproofno}</h7>
                        {/* <h4 className="modal-title">
                          Id Proof has been Verified
                        </h4> */}
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.IDstatus == "Rejected" ? (
                      <div>
                        <div className="proofContentText">
                          <h4>
                            1. ID Proof
                            <sup
                              className="ml-2"
                              style={{ backgroundColor: "Red" }}
                            >
                              Rejected
                            </sup>
                          </h4>
                          <h5>
                            ID Proof has been Rejected, Upload a new image
                          </h5>
                          <p>Upload a clear readable copy of any one:</p>
                          <div className="form-group">
                            <label>Select ID Proof Type</label>
                            {/* <Select
                          onChange={this.handleChange1}
                          options={idproof}
                          label="Single select"
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#ffba00",
                              primary: "#ffba00"
                            }
                          })}
                        /> */}

                            {/* {this.state.IDtype ? ( */}
                            <Select
                              // value={this.state.IDtype}
                              value={selectedOption1}
                              defaultValue={{
                                label: this.state.IDtype.value,
                                value: this.state.IDtype.value,
                              }}
                              onChange={this.handleChange1}
                              options={idproof}
                            />
                            {/* ) : (
                              ""
                            )} */}
                          </div>
                          <div className="form-group">
                            <label>ID Proof Number </label>
                            <input
                              name="Idproof"
                              onChange={this.onChange}
                              value={this.state.IDproofno}
                              error={errors.IDproofno}
                              id="IDproofno"
                              type="text"
                              name="IDproofno"
                              className={classnames("form-control", {
                                invalid: errors.IDproofno,
                              })}
                            />
                            <span className="text-danger">
                              {errors.IDproofno}
                            </span>{" "}
                          </div>
                        </div>

                        {/* <div className="uploadRightBox">
                          <div className="from-group">
                            <label>Front side</label>
                            <div className="custom-file">
                              <input
                                type="file"
                                onChange={this.handleChangeIdfront}
                                name="file1"
                                id="exampleInputFile"
                                aria-describedby="fileHelp"
                              />
                              <label
                                className="custom-file-label"
                                for="exampleInputFile"
                              >
                                Choose file
                              </label>
                            </div>
                            <span className="custom-file-control form-control-file"></span>
                          </div>
                          <img
                            className="img-fluid proofThumb"
                            src={this.state.viewidfront}
                          />
                        </div> */}
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.IDstatus == "Not verified" ? (
                      <div>
                        <div className="proofContentText">
                          <h4>1. ID Proof</h4>
                          <p>Upload a clear readable copy of any one:</p>
                          <div className="form-group">
                            <label>Select ID Proof Type</label>
                            {/* <Select
                          onChange={this.handleChange1}
                          options={idproof}
                          label="Single select"
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#ffba00",
                              primary: "#ffba00"
                            }
                          })}
                        /> */}

                            {/* {this.state.IDtype ? ( */}
                            <Select
                            value={selectedOption1}

                              onChange={this.handleChange1}
                              options={idproof}
                            />
                            {/* ) : (
                              ""
                            )} */}
                          </div>
                          <div className="form-group">
                            <label>ID Proof Number </label>
                            <input
                              name="Idproof"
                              onChange={this.onChange}
                              value={this.state.IDproofno}
                              error={errors.IDproofno}
                              id="IDproofno"
                              type="text"
                              name="IDproofno"
                              className={classnames("form-control", {
                                invalid: errors.IDproofno,
                              })}
                            />
                            <span className="text-danger">
                              {errors.IDproofno}
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* 2Nd div starts */}

                  <div className="col-md-4">
                    {this.state.IDstatus == "Not verified" ? (
                      <div className="uploadRightBox">
                        <div className="from-group">
                          <label>Front side</label>
                          <div className="custom-file">
                            <input
                              type="file"
                              onChange={this.handleChangeIdfront}
                              name="file1"
                              id="exampleInputFile"
                              aria-describedby="fileHelp"
                            />
                            <label
                              className="custom-file-label"
                              for="exampleInputFile"
                            >
                              Choose file
                            </label>
                          </div>
                          <span className="custom-file-control form-control-file"></span>
                        </div>
                        <img
                          className="img-fluid proofThumb"
                          src={this.state.viewidfront}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.IDstatus == "Rejected" ? (
                      <div className="uploadRightBox">
                        <div className="from-group">
                          <label>Front side</label>
                          <div className="custom-file">
                            <input
                              type="file"
                              onChange={this.handleChangeIdfront}
                              name="file1"
                              id="exampleInputFile"
                              aria-describedby="fileHelp"
                            />
                            <label
                              className="custom-file-label"
                              for="exampleInputFile"
                            >
                              Choose file
                            </label>
                          </div>
                          <span className="custom-file-control form-control-file"></span>
                        </div>
                        <img
                          className="img-fluid proofThumb"
                          src={this.state.viewidfront}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.IDstatus == "Verified" ? (
                      <img
                        className="img-fluid proofThumb"
                        src={url + "kyc/" + this.state.IDprooffront}
                      />
                    ) : (
                      ""
                    )}

                    {this.state.IDstatus == "Pending" ? (
                      <img
                        className="img-fluid proofThumb"
                        src={url + "kyc/" + this.state.IDprooffront}
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  {/* 3Rd Div    starts */}

                  <div className="col-md-4">
                    {this.state.IDstatus == "Not verified" ? (
                      <div className="uploadRightBox">
                        <div className="from-group">
                          <label>Back side</label>
                          <div className="custom-file">
                            <input
                              type="file"
                              onChange={this.handleChangeIdback}
                              name="file2"
                              id="exampleInputFile2"
                              aria-describedby="fileHelp"
                            />
                            <label
                              className="custom-file-label"
                              for="exampleInputFile2"
                            >
                              Choose file
                            </label>
                          </div>

                          <span className="custom-file-control form-control-file"></span>
                        </div>
                        <img
                          className="img-fluid proofThumb"
                          src={this.state.viewidback}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.IDstatus == "Rejected" ? (
                      <div className="uploadRightBox">
                        <div className="from-group">
                          <label>Back side</label>
                          <div className="custom-file">
                            <input
                              type="file"
                              onChange={this.handleChangeIdback}
                              name="file2"
                              id="exampleInputFile2"
                              aria-describedby="fileHelp"
                            />
                            <label
                              className="custom-file-label"
                              for="exampleInputFile2"
                            >
                              Choose file
                            </label>
                          </div>

                          <span className="custom-file-control form-control-file"></span>
                        </div>
                        <img
                          className="img-fluid proofThumb"
                          src={this.state.viewidback}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.IDstatus == "Pending" ? (
                      <img
                        className="img-fluid proofThumb"
                        src={url + "kyc/" + this.state.IDproofback}
                      />
                    ) : (
                      ""
                    )}

                    {this.state.IDstatus == "Verified" ? (
                      <img
                        className="img-fluid proofThumb"
                        src={url + "kyc/" + this.state.IDproofback}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="proofBox">
                <div className="row">
                  <div className="col-md-4">
                    {this.state.Addresstatus == "Pending" ? (
                      <div>
                      <div className="proofContentText">
                        <h4>2. Address Proof</h4>

                        <h5>Waiting for Approval</h5>
                        <h7>Address Proof : {this.state.Addresstype.value}</h7>
                        <br></br>
                        <h7>Address Id No : {this.state.Addressproofno}</h7>
                      </div>
                      <input
                        className="btn btnType1"
                        name=""
                        value="Resubmit ID"
                        readOnly
                        onClick={() => {
                          this.ResubmitKYCAddress();
                        }}
                      />
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.Addresstatus == "Not verified" ? (
                      <div className="proofContentText">
                        <h4>2. Address Proof</h4>
                        <p>
                          Upload a clear readable latest copy of any one utility
                          bill:
                        </p>
                        <div className="form-group">
                          <label>Select ID Proof Type</label>
                          {/* <Select
                          onChange={this.handleChange2}
                          options={adproof}
                          label="Single select"
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#ffba00",
                              primary: "#ffba00"
                            }
                          })}
                        /> */}

                          {/* {this.state.Addresstype ? ( */}
                          <Select
                            // value={this.state.Addresstype}
                            value={selectedOption2}
                            defaultValue={{
                              label: this.state.Addresstype.value,
                              value: this.state.Addresstype.value,
                            }}
                            onChange={this.handleChange2}
                            options={adproof}
                          />
                          {/* ) : (
                            ""
                          )} */}
                        </div>
                        <div className="form-group">
                          <label>ID Proof Number</label>
                          <input
                            name="addressproof"
                            onChange={this.onChange}
                            value={this.state.Addressproofno}
                            error={errors.Addressproofno}
                            id="Addressproofno"
                            type="text"
                            name="Addressproofno"
                            className={classnames("form-control", {
                              invalid: errors.Addressproofno,
                            })}
                          />
                          <span className="text-danger">
                            {errors.Addressproofno}
                          </span>{" "}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.Addresstatus == "Verified" ? (
                      <div>
                        <div className="proofContentText">
                          <h4>
                            2. Address Proof{" "}
                            <sup
                              className="ml-2"
                              style={{ backgroundColor: "green" }}
                            >
                              Verified
                            </sup>
                          </h4>

                          {/* <h4 className="modal-title">
                            Address Proof has been Verified
                          </h4>
                          <h7>Address Proof : {this.state.Addresstype}</h7> */}

                          <h7>Address Proof : {this.state.Addresstype.value}</h7>
                          <br></br>
                          <h7>Address Id No : {this.state.Addressproofno}</h7>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.Addresstatus == "Rejected" ? (
                      <div className="proofContentText">
                        <h4>
                          2. Address Proof{" "}
                          <sup
                            className="ml-2"
                            style={{ backgroundColor: "Red" }}
                          >
                            Rejected
                          </sup>
                        </h4>
                        <h5>
                          Address Proof has been rejected, Upload a new one
                        </h5>
                        <p>
                          Upload a clear readable latest copy of any one utility
                          bill:
                        </p>
                        <div className="form-group">
                          <label>Select ID Proof Type</label>
                          {/* <Select
                          onChange={this.handleChange2}
                          options={adproof}
                          label="Single select"
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#ffba00",
                              primary: "#ffba00"
                            }
                          })}
                        /> */}

                          {/* {this.state.Addresstype ? ( */}
                          <Select
                            // value={this.state.Addresstype}
                              value={selectedOption2}
                            defaultValue={{
                              label: this.state.Addresstype.value,
                              value: this.state.Addresstype.value,
                            }}
                            onChange={this.handleChange2}
                            options={adproof}
                          />
                          {/* ) : (
                            ""
                          )} */}
                        </div>
                        <div className="form-group">
                          <label>ID Proof Number</label>
                          <input
                            name="addressproof"
                            onChange={this.onChange}
                            value={this.state.Addressproofno}
                            error={errors.Addressproofno}
                            id="Addressproofno"
                            type="text"
                            name="Addressproofno"
                            className={classnames("form-control", {
                              invalid: errors.Addressproofno,
                            })}
                          />
                          <span className="text-danger">
                            {errors.Addressproofno}
                          </span>{" "}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* 2nd div start */}
                  <div className="col-md-4"></div>
                  {/* 3RD divvv start */}
                  <div className="col-md-4">
                    {this.state.Addresstatus == "Not verified" ? (
                      <div className="uploadRightBox">
                        <div className="from-group">
                          <label>Front side name with address</label>
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="exampleInputFile3"
                              onChange={this.handleChangeaddress}
                              name="file3"
                              aria-describedby="fileHelp"
                            />
                            <label
                              className="custom-file-label"
                              for="exampleInputFile3"
                            >
                              Choose file
                            </label>
                          </div>

                          <span className="custom-file-control form-control-file"></span>
                        </div>
                        <img
                          className="img-fluid proofThumb"
                          src={this.state.viewaddressphoto}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.Addresstatus == "Rejected" ? (
                      <div>
                        <div className="uploadRightBox">
                          <div className="from-group">
                            <label>Front side name with address</label>
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="exampleInputFile3"
                                onChange={this.handleChangeaddress}
                                name="file3"
                                aria-describedby="fileHelp"
                              />
                              <label
                                className="custom-file-label"
                                for="exampleInputFile3"
                              >
                                Choose file
                              </label>
                            </div>

                            <span className="custom-file-control form-control-file"></span>
                          </div>
                          <img
                            className="img-fluid proofThumb"
                            src={this.state.viewaddressphoto}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.Addresstatus == "Pending" ? (
                      <img
                        className="img-fluid proofThumb"
                        src={url + "kyc/" + this.state.Addressfile}
                      />
                    ) : (
                      ""
                    )}

                    {this.state.Addresstatus == "Verified" ? (
                      <img
                        className="img-fluid proofThumb"
                        src={url + "kyc/" + this.state.Addressfile}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="proofBox">
                <div className="row">
                  <div className="col-md-4">
                    {this.state.Photostatus == "Verified" ? (
                      <div className="proofContentText">
                        <h4>
                          3. Photo Proof{" "}
                          <sup
                            className="ml-2"
                            style={{ backgroundColor: "green" }}
                          >
                            Verified
                          </sup>
                        </h4>
                      </div>

                    ) : (
                      ""
                    )}

                    {this.state.Photostatus == "Rejected" ? (
                      <div className="proofContentText">
                        <h4>
                          3. Photo Proof{" "}
                          <sup
                            className="ml-2"
                            style={{ backgroundColor: "Red" }}
                          >
                            Rejected
                          </sup>
                        </h4>
                        <h5>Photo Proof has been Rejected</h5>
                        <p>
                          Upload a selfie with clear, high resolution photo with
                          any one ID proof:
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.Photostatus == "Not verified" ? (
                      <div className="proofContentText">
                        <h4>3. Photo Proof</h4>
                        <p>
                          Upload a selfie with clear, high resolution photo with
                          any one ID proof:
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.Photostatus == "Pending" ? (
                      <div>
                      <div className="proofContentText">
                        <h4>3. Photo Proof</h4>
                        <h5>Waiting for Approval</h5>
                      </div>
                      <input
                        className="btn btnType1"
                        name=""
                        value="Resubmit ID"
                        readOnly
                        onClick={() => {
                          this.ResubmitKYCPhoto();
                        }}
                      />
                      </div>
                    ) : (
                      ""
                      // <div className="proofContentText">
                      //   <h4>3. Photo Proof</h4>
                      //   <p>
                      //     Upload a selfie with clear, high resolution photo with
                      //     any one ID proof:
                      //   </p>
                      //   <div className="form-group">
                      //     <label>Select ID Proof Type</label>
                      //     {/* <Select
                      //     onChange={this.handleChange3}
                      //     options={photoproof}
                      //     label="Single select"
                      //     theme={theme => ({
                      //       ...theme,
                      //       colors: {
                      //         ...theme.colors,
                      //         primary25: "#ffba00",
                      //         primary: "#ffba00"
                      //       }
                      //     })}
                      //   /> */}
                      //     {this.state.Phototype ? (
                      //       <Select
                      //         value={this.state.Phototype}
                      //         defaultValue={{
                      //           label: this.state.Phototype.value,
                      //           value: this.state.Phototype.value
                      //         }}
                      //         onChange={this.handleChange3}
                      //         options={photoproof}
                      //       />
                      //     ) : (
                      //       ""
                      //     )}
                      //   </div>
                      //   <div className="form-group">
                      //     <label>ID Proof Number</label>
                      //     <input
                      //       name="photoproof"
                      //       onChange={this.onChange}
                      //       value={this.state.Phototypeno}
                      //       error={errors.Phototypeno}
                      //       id="Phototypeno"
                      //       type="text"
                      //       name="Phototypeno"
                      //       className={classnames("form-control", {
                      //         invalid: errors.Phototypeno
                      //       })}
                      //     />
                      //     <span className="text-danger">
                      //       {errors.Phototypeno}
                      //     </span>{" "}
                      //   </div>
                      // </div>
                    )}
                  </div>
                  <div className="col-md-4"></div>

                  <div className="col-md-4">
                    {this.state.Photostatus == "Not verified" ? (
                      <div className="uploadRightBox">
                        <div className="from-group">
                          <label>Selfie with ID Proof</label>
                          <div className="custom-file">
                            <input
                              type="file"
                              onChange={this.handleChangephoto}
                              name="file4"
                              id="exampleInputFile4"
                              aria-describedby="fileHelp"
                            />
                            <label
                              className="custom-file-label"
                              for="exampleInputFile4"
                            >
                              Choose file
                            </label>
                          </div>

                          <span className="custom-file-control form-control-file"></span>
                        </div>

                        <img
                          className="img-fluid proofThumb"
                          src={this.state.viewphoto}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.Photostatus == "Rejected" ? (
                      <div className="uploadRightBox">
                        <div className="from-group">
                          <label>Selfie with ID Proof</label>
                          <div className="custom-file">
                            <input
                              type="file"
                              onChange={this.handleChangephoto}
                              name="file4"
                              id="exampleInputFile4"
                              aria-describedby="fileHelp"
                            />
                            <label
                              className="custom-file-label"
                              for="exampleInputFile4"
                            >
                              Choose file
                            </label>
                          </div>

                          <span className="custom-file-control form-control-file"></span>
                        </div>
                        <img
                          className="img-fluid proofThumb"
                          src={this.state.viewphoto}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.Photostatus == "Verified" ? (
                      <img
                        className="img-fluid proofThumb"
                        src={url + "kyc/" + this.state.Photofile}
                      />
                    ) : (
                      ""
                    )}
                    {this.state.Photostatus == "Pending" ? (
                      <img
                        className="img-fluid proofThumb"
                        src={url + "kyc/" + this.state.Photofile}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group text-center mb-0">
                {this.state.verifiedstatus == "Rejected" ? (
                  <input
                    className="btn btnType1"
                    name=""
                    value="submit for verification"
                    type="submit"
                  />
                ) : (
                  ""
                )}


                {this.state.verifiedstatus == "Not verified" ? (
                  <input
                    className="btn btnType1"
                    name=""
                    value="submit for verification"
                    type="submit"
                  />
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </section>
        <UserFooter />
      </div>
    );
  }
}

Kyc.propTypes = {
  kycadd: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { kycadd })(withRouter(Kyc));
