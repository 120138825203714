import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import keys from "../actions/config";
import TradeHeader from "./TradeHeader";
import Footer from "./Footer";
import { store } from "react-notifications-component";
import { loginUser,tfachecking } from "../actions/authactions";
import browser from "browser-detect";
import { Modal, Button } from "react-bootstrap/";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const url = keys.baseUrl;

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      countryName: "",
      countryCode: "",
      ipaddress: "",
      browsername: "",
      regionName: "",
      ismobile: "",
      os: "",
      tfacode:"",
      region: "",
      show: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  componentDidMount() {
    this.getGeoInfo();
    if (this.props.auth.isAuthenticated) {
      toast.success("res.data.message", {
        position: toast.POSITION.TOP_CENTER,
      });
      this.props.history.push("/Dashboard");
    }
    let activate_id = this.props.match.params.id;
    if (typeof activate_id != "undefined" && activate_id != "") {
      let passdata = { userid: activate_id };
      axios
        .post(url + "api/user-activate", passdata)
        .then((res) => {
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
          this.props.history.push("/Login");
        })
        .catch();
    }
  }

  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        // console.log(data,'data');
        this.setState({
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          ipaddress: data.ip,
          region: data.region,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleModal = (e) => {
    this.setState({ show: !this.state.show });
  };

  componentWillReceiveProps(nextProps) {
    // console.log("recewive props", nextProps);
    if (nextProps.auth.isAuthenticated) {
      store.addNotification({
        title: "Wonderful!",
        message: "Loggedin Successfully. Welcome to GenesisOne",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
      this.props.history.push("/Dashboard");
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
      if (
        typeof nextProps.errors.notify != "undefined" &&
        nextProps.errors.notify != ""
      ) {
        if (nextProps.errors.notify == "2fa Enabled") {
          this.setState({ show: true });
        }
        if (nextProps.errors.notify == "blocking") {
          this.setState({ show1: true });
          store.addNotification({
            title: "Error!",
            message: "New IP login check your Email for confirmation code",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
      }
      setTimeout(function () {
        console.log(nextProps.errors);
        if (
          typeof nextProps.errors.notify != "undefined" &&
          nextProps.errors.notify != "" &&
          nextProps.errors.notify != "2fa Enabled" &&
          nextProps.errors.notify != "blocking"
        ) {
          store.addNotification({
            title: "Error!",
            message: nextProps.errors.notify,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
        nextProps.errors.notify = "";
      }, 100);
    }
  }
  TFASubmit = (e) => {
    e.preventDefault();
    const result = browser();
    const loginhistory = {};
    loginhistory.countryCode = this.state.countryCode;
    loginhistory.countryName = this.state.countryName;
    loginhistory.ipaddress = this.state.ipaddress;
    loginhistory.regionName = this.state.region;
    loginhistory.browsername = result.name;
    loginhistory.ismobile = result.mobile;
    loginhistory.os = result.os;
    const userData = {
      email: this.state.email,
      password: this.state.password,
      logininfo: loginhistory,
      tfacode: this.state.tfacode,
    };
    this.props.tfachecking(userData);

  }

  onSubmit = (e) => {
    e.preventDefault();
    const result = browser();
    const loginhistory = {};
    loginhistory.countryCode = this.state.countryCode;
    loginhistory.countryName = this.state.countryName;
    loginhistory.ipaddress = this.state.ipaddress;
    loginhistory.regionName = this.state.region;
    loginhistory.browsername = result.name;
    loginhistory.ismobile = result.mobile;
    loginhistory.os = result.os;
    const userData = {
      email: this.state.email,
      password: this.state.password,
      logininfo: loginhistory,
    };
    this.props.loginUser(userData);
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <TradeHeader />
        <section className="loginRegBg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-5">
                <div className="loginRegLeft">
                  <h3>
                    <small>
                      <span>Join for access to</span>
                    </small>
                    NeoGenesis Exchange/Wallet<span>NeoGenesis CyberLottery</span> GenesisOne Ecosystem{" "}
                  </h3>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-7">
                <div className="loginRegRight">
                  <div className="formBox">
                    <h2>Login</h2>
                    <form noValidate onSubmit={this.onSubmit}>
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.email}
                          error={errors.email}
                          id="email"
                          type="email"
                          className={classnames("form-control", {
                            invalid: errors.email,
                          })}
                        />
                        <span className="text-danger">{errors.email}</span>
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.password}
                          error={errors.password}
                          id="password"
                          type="password"
                          className={classnames("form-control", {
                            invalid: errors.password,
                          })}
                        />
                        <span className="text-danger">{errors.password}</span>
                        {/* <span className="text-danger">{errors.email}</span> */}
                      </div>
                      <p className="text-center">
                        <button className="btn btnType1" type="submit">
                          Login
                        </button>
                      </p>
                      <p className="text-center">
                        <Link to="/Register">New Register</Link> |{" "}
                        <Link to="/ForgotPassword">Forgot password?</Link>
                      </p>
                    </form>

                    <Modal
                      show={this.state.show}
                      onHide={() => {
                        this.handleModal();
                      }}
                      centered
                    >
                      <Modal.Header closeButton>
                        <h4>2FA Code</h4>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12 mx-auto">
                            <div className="buyAmt">
                              <div className="col-md-12">
                              
                                <input
                                  onChange={this.onChange}
                                  value={this.state.tfacode}
                                  id="tfacode"
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter the 2FA Code"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btnType1 smallFont py-2 px-3 mr-2"
                          onClick={() => {
                            this.handleModal();
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          className="btn btnType1 smallFont py-2 px-3 mr-2"
                          onClick={this.TFASubmit}
                        >
                          Confirm
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 d-none d-lg-block"></div>
            </div>
            <ToastContainer />
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  tfachecking:PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser,tfachecking })(withRouter(Login));
