import React, { Component } from "react";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import { support, supportreply } from "../actions/authactions";
import classnames from "classnames";
import keys from "../actions/config";
import axios from "axios";
import * as moment from "moment";
import { TimeAgo } from "@n1ru4l/react-time-ago";

const url = keys.baseUrl;

class Support extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      email_add: "",
      subject: "",
      description: "",
      replystatus: "",
      closedstatus: "",
      ticketno:"",
      created_date: "",
      supportarray: [],
      finalsort: [],
      validation: {},
      _id: "",
      reply: [],
      message_query: "",
      errors: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    if (this.props.auth !== undefined && this.props.auth.user !== undefined) {
      console.log("Next proosss", this.props);
      const id = this.props.auth.user.id;
      axios
        .get(url + "api/supportget/" + id)
        .then((res) => {
          // this.setState(res.data);
          this.setState({ supportarray: res.data });
          // this.setState(res.data[0]);
        })
        .catch();
      // console.log("Support array", this.setState);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      console.log(nextProps, "nextPropsasadxsdsf");
      this.setState({
        errors: nextProps.errors,
      });
    }


    if (
      nextProps.auth !== undefined &&
      nextProps.auth.support !== undefined &&
      nextProps.auth.support.data !== undefined &&
      nextProps.auth.support.data.message !== undefined
    ) {
      store.addNotification({
        title: "Wonderful!",
        message: nextProps.auth.support.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });

      nextProps.auth.support = undefined;
      this.props.history.push("/Support");
      this.getData();
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      fields["subject"] = "";
      fields["description"] = "";
      this.setState({ validation: fields });
      const Newsupport = {
        id: this.props.auth.user.id,
        email_add: this.props.auth.user.email,
        subject: this.state.subject,
        description: this.state.description,
      };
      this.props.support(Newsupport);
    }
  };

  Submitreply(answer_id) {
    const Newsupportreply = {
      _id: answer_id,
      message_query: this.state.message_query,
    };
    this.props.supportreply(Newsupportreply);
  }

  closeticket(ticketno) {
    console.log("ticket ", ticketno);
    const closeticket = {
      ticketnumber: ticketno,
    };
    axios
      .post(url + "api/ticketclose", closeticket)
      .then((res) => {
        if (res.status === 200) {
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
          this.getData();
        }
      })
      .catch();
  }

  validateForm() {
    let fields = this.state.validation;
    let errors = {};
    errors["subject"] = "";
    errors["description"] = "";
    let formIsValid = true;

    if (this.state.subject == "") {
      formIsValid = false;
      errors["subject"] = "*Please enter your Subject";
    }
    if (this.state.description == "") {
      formIsValid = false;
      errors["description"] = "*Please enter your Description";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    const { errors, finalsort } = this.state;
    const { user } = this.props.auth;

    return (
      <div>
        <UserHeader />
        <section className="lgoinContent SettingsPage">
          <div className="container">
            <h2>Support</h2>
            <h4>Create Ticket</h4>
            <form name="supportTicket" onSubmit={this.onSubmit}>
              <div className="settingsBox">
                <div className="row">
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        name="email_add"
                        onChange={this.onChange}
                        value={this.props.auth.user.email}
                        error={errors.email_add}
                        id="email_add"
                        type="email"
                        name="email_add"
                        className={classnames("form-control", {
                          invalid: errors.email_add
                        })}
                      />
                      <span className="text-danger">{errors.email_add}</span>{" "}
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Subject</label>
                      <input
                        name="subject"
                        onChange={this.onChange}
                        value={this.state.subject}
                        error={errors.subject}
                        id="subject"
                        type="text"
                        name="subject"
                        className={classnames("form-control", {
                          invalid: errors.subject,
                        })}
                      />
                      <span className="text-danger">{errors.subject}</span>{" "}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        name="description"
                        onChange={this.onChange}
                        value={this.state.description}
                        error={errors.description}
                        id="description"
                        type="text"
                        name="description"
                        className={classnames("form-control", {
                          invalid: errors.description,
                        })}
                      />
                      <span className="text-danger">{errors.description}</span>{" "}
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button className="btn btnType1" type="submit">
                      Submit{" "}
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <h4>Ticket Details</h4>
            <div className="supportTicketBox">
              <div className="suppotTicketHeader">
                <div className="row">
                  <div className="col-lg-2 col-md-3 col-4">
                    <div className="supportTicketDateTime">Create Date</div>
                  </div>
                  <div className="col-lg-2 col-md-3 col-2">
                    <div className="supportTicketSubject">Ticket No.</div>
                  </div>
                  <div className="col-lg-6 col-md-4 col-3">
                    <div className="supportTicketSubject">Subject</div>
                  </div>

                  <div className="col-lg-2 col-md-2 col-3">
                    <div className="supportTicketStatus">Status</div>
                  </div>
                </div>
              </div>
              <div className="faqCont">
                <div className="supportAccordian wow fadeIn">
                  <div id="accordionSupportTicket">
                    {this.state.supportarray.map((answer, i) => {
                      return (
                        <div className="card">
                          <div
                            className="card-header wow flipInX"
                            id="headingOne"
                          >
                            <div
                              className="row"
                              data-toggle="collapse"
                              data-target={"#collapsetest" + i}
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              <div className="col-lg-2 col-md-3 col-4">
                                <div className="supportTicketDateTime">
                                  {moment(answer.created_date).format(
                                    "DD/MM/YYYY  h:mm a "
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-3 col-2">
                                <div className="supportTicketSubject">
                                  {answer.ticketno}
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-4 col-3">
                                <div className="supportTicketSubject">
                                  {answer.subject}
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-2 col-3">
                                <div className="supportTicketStatus">
                                  <span className="statusTextOpen">
                                    {answer.closedstatus}
                                  </span>{" "}
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            id={"collapsetest" + i}
                            className="collapse"
                            aria-labelledby="headingOne"
                            aria-expanded="false"
                            data-parent="#accordionSupportTicket"
                          >
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-2 col-md-3 col-sm-4 d-none d-sm-block"></div>
                                <div className="col-lg-10 col-md-9 col-sm-8 col-12">
                                  <ul className="ticketComments">
                                    <li>
                                      <div className="ticketUserDetails">
                                        <div className="userImg"></div>
                                        <p>{this.props.auth.user.name}</p>
                                      </div>
                                      <div className="ticketDetails">
                                        <p>Hello ,</p>
                                        <p>{answer.description}</p>
                                        <p>
                                          Thanks,
                                          <br />
                                          {this.props.auth.user.name}
                                        </p>
                                      </div>
                                    </li>
                                    {answer.reply.map((array, i) => {
                                      return (
                                        <li>
                                          <div className="ticketUserDetails">
                                            <div className="adminImg"></div>
                                            <p className="mb-0">
                                              {array.replytype == "user"
                                                ? user.name
                                                : array.replytype}
                                            </p>
                                            <p className="timeAgo">
                                              <TimeAgo
                                                date={new Date(array.replydate)}
                                              >
                                                {({ value }) => value}
                                              </TimeAgo>
                                            </p>
                                          </div>
                                          <div className="ticketDetails">
                                            <p></p>
                                            <p>{array.message_query}</p>
                                            <p></p>
                                          </div>
                                        </li>
                                      );
                                    })}{" "}
                                  </ul>

                                  
                                  {answer.closedstatus=="Closed"?(""):(
                                    <div className="messageTypeBox">
                                    <div className="row">
                                      <div className="col-lg-9">
                                        <div className="form-group">
                                          <label>Reply to admin</label>

                                          <textarea
                                            name="message_query"
                                            onChange={this.onChange}
                                            value={this.state.message_query}
                                            error={errors.message_query}
                                            id="message_query"
                                            type="text"
                                            className={classnames(
                                              "form-control",
                                              {
                                                invalid: errors.message_query,
                                              }
                                            )}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-3 pl-lg-0">
                                        <p className="submitChat">
                                          <input
                                            type="text"
                                            onClick={() =>
                                              this.Submitreply(answer._id)
                                            }
                                            readOnly
                                            value="Submit"
                                            className="btn btnType1 px-0 mt-0 mt-lg-3"
                                          />

                                          <input
                                            type="text"
                                            onClick={() =>
                                              this.closeticket(answer.ticketno)
                                            }
                                            readOnly
                                            value="Close Ticket"
                                            className="btn btnType1 px-0 mt-0 mt-lg-3"
                                          />
                                        </p>
                                      </div>
                                    </div>
                                  </div>


                                  )}
                                  

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {/* 
                    {this.state.reply.map((array, i) => {
                      return (
                        <div className="row">
                          <div className="col-md-2">
                            <div className="responseUserImg">
                              {array.replytype == "user" ? (
                                <p>{user.name}</p>
                              ) : (
                                <p>{array.replytype}</p>
                              )}
                              <p><TimeAgo date={new Date(array.replydate)}>{({ value }) => <p>{value}</p>}</TimeAgo></p>
                            </div>
                          </div>
                          <div className="col-md-10">
                            <div className="responseText">
                              <p> hello,</p>
                              <p>{array.message_query}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <UserFooter />
      </div>
    );
  }
}

Support.propTypes = {
  support: PropTypes.func.isRequired,
  supportreply: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { support, supportreply })(
  withRouter(Support)
);
