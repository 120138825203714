import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ReactDOM from "react-dom";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { store } from "react-notifications-component";
import keys from "../actions/config";
import * as moment from "moment";
import PhoneInput from "react-phone-number-input";
import { compareSync } from "bcryptjs";

const url = keys.baseUrl;

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phonenumber: "",
      subject: "",
      validation: {},
      message: "",
      errors: {},
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  ContactusSubmit() {
    if (this.validateForm()) {
      let fields = {};
      fields["name"] = "";
      fields["email"] = "";
      fields["subject"] = "";
      fields["message"] = "";
      fields["phonenumber"] = "";

      this.setState({ validation: fields });
      const userData = {
        name: this.state.name,
        mobilenumber: this.state.phonenumber,
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
      };

      axios
        .post(url + "api/contactus", userData)
        .then((res) => {
          if (res.data.status) {
            store.addNotification({
              title: "Wonderful!",
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
          } else {
            this.setState({
              mailerror: res.data.error,
            });
          }
        })
        .catch();
    }
  }

  validateForm() {
    let fields = this.state.validation;
    let errors = {};
    errors["name"] = "";
    errors["email"] = "";
    errors["message"] = "";
    errors["subject"] = "";
    errors["phonenumber"] = "";
    let formIsValid = true;

    if (this.state.name == "") {
      formIsValid = false;
      errors["name"] = "*Please enter the Name";
    }
    if (this.state.email == "") {
      formIsValid = false;
      errors["email"] = "*Please enter the Email";
    }
    if (this.state.phonenumber == "") {
      formIsValid = false;
      errors["phonenumber"] = "*Please enter the Mobile Number";
    }

    if (this.state.subject == "") {
      formIsValid = false;
      errors["subject"] = "*Please enter the Subject";
    }
    if (this.state.message == "") {
      formIsValid = false;
      errors["message"] = "*Please enter the Message";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    const { selectedOption, errors } = this.state;
    return (
      <div>
        <Navbar />
        <section className="lgoinContent ContactPage">
          <div className="container">
            <h2>Contact us</h2>
            <h4>Contact support</h4>
            <p className="conDescription">
              If you have any queries , please connect with us by Submitting the
              form
            </p>
            <div className="contactPageBox">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Full Name</label>
                    <input
                      onChange={this.onChange}
                      value={this.state.name}
                      id="name"
                      type="text"
                      error={errors.name}
                      placeholder="Enter the  Name"
                      className={classnames("form-control", {
                        invalid: errors.name,
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Mobile Number</label>
                    <PhoneInput
                      placeholder=" Select the country and Enter Mobile number"
                      country={"IN"}
                      value={this.state.phonenumber}
                      onChange={(phonenumber) => this.setState({ phonenumber })}
                      className={classnames("form-control", {
                        invalid: errors.phonenumber,
                      })}
                    />
                    <span className="text-danger">{errors.phonenumber}</span>{" "}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email Address</label>
                    <input
                      onChange={this.onChange}
                      value={this.state.email}
                      id="email"
                      type="text"
                      error={errors.email}
                      placeholder="Enter the  Email"
                      className={classnames("form-control", {
                        invalid: errors.email,
                      })}
                    />
                    <span className="text-danger">{errors.email}</span>
                    <span className="text-danger">
                      {this.state.mailerror}
                    </span>{" "}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Subject</label>
                    <input
                      onChange={this.onChange}
                      value={this.state.subject}
                      id="subject"
                      type="text"
                      error={errors.subject}
                      placeholder="Enter the Last Name"
                      className={classnames("form-control", {
                        invalid: errors.subject,
                      })}
                    />
                    <span className="text-danger">{errors.subject}</span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Message</label>
                    {/* <textarea rows="4" className="form-control"></textarea> */}
                    <textarea
                      rows="4"
                      name="message"
                      onChange={this.onChange}
                      value={this.state.message}
                      error={errors.message}
                      id="message"
                      type="text"
                      className={classnames("form-control", {
                        invalid: errors.message,
                      })}
                    />
                    <span className="text-danger">{errors.message}</span>
                  </div>
                </div>
                <div className="col-md-12">
                  <p className="text-center">
                    <input
                      type="text"
                      name=""
                      value="Submit"
                      readOnly
                      className="btn btnType1 px-0 mt-3"
                      onClick={() => this.ContactusSubmit()}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

Contact.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(Contact));
