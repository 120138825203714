import React, { Component } from "react";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import ReactDOM from "react-dom";
import DashboardCoin from "../images/dashboardCoin.png";
import BitCoinIcon from "../images/bitcoinIcon.png";
import EtheriumIcon from "../images/etheriumIcon.png";
import QrCodeIcon from "../images/qrcodeIcon.png";
import HeadIconCoin from "../images/headiconCoin.png";
import MetheriumIcon from "../images/MetheriumIcon.png";
import MbitCoinIcon from "../images/MbitcoinIcon.png";
import { logoutUser } from "../actions/authactions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import keys from "../actions/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as moment from "moment";
import Select from "react-select";
import classnames from "classnames";
import { Modal, Button } from "react-bootstrap/";

import { CopyToClipboard } from "react-copy-to-clipboard";

var QRCode = require("qrcode.react");
const url = keys.baseUrl;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      name: "",
      lastname: "",
      address1: "",
      address2: "",
      email: "",
      city: "",
      pincode: "",
      phonenumber: "",
      country: "",
      date: "",
      verifiedstatus: "",
      currencySymbol: "",
      currencyAddress: "",
      balance: "",
      currency: "",
      eth_address: "",
      go_address: "",
      btc_address: "",
      genoneamount: "",
      btcbalance: 0,
      ethbalance: 0,
      invbalance: 0,
      gobalance: 0,
      gcbalance: 0,
      token_price_btc: "",
      cryptoAddress: "",
      token_price_usd: "",
      lastbuy: "",
      token_price_eth: "",
      history: [],
      supportarray: [],
      errors: {},
      show: false,
    };
  }

  componentDidMount() {
    this.getData();
    this.getdepositdetails();
    this.getAddress();
    this.gettransactionhistory();
    this.getcurrency();
    this.getfeedata();
    this.getsupportticket();
    this.getlastbuy();
    this.gettokendepositdetails();
    this.getbtcdeposit();
  }

  getinvestterm() {
    const id = this.props.auth.user.id;
    axios.post(url + "api/investcheck/" + id).then((res) => {});
  }

  getlastbuy() {
    const id = this.props.auth.user.id;
    axios.get(url + "api/buyhistory/" + id).then((res) => {
      if (res.data[0]) {
        this.setState({ lastbuy: res.data[0].created_date });
      }
    });
  }
  getsupportticket() {
    const id = this.props.auth.user.id;
    axios.get(url + "api/supportdashboard/" + id).then((res) => {
      this.setState({ supportarray: res.data });
    });
  }
  getfeedata() {
    axios.get(url + "api/feedata").then((res) => {
      this.setState(res.data[0]);
    });
  }
  getcurrency() {
    axios.get(url + "api/currencydata").then((res) => {
      var currencyarray = [];
      res.data.map((item, i) => {
        if (item.currencySymbol != "GO") {
          const name = item.currencySymbol;
          const value = item.currencySymbol;

          const obj = { value: name, label: value };
          currencyarray.push(obj);
        }
      });
      this.setState({ currencydata: currencyarray, email_assigned: true });
    });
  }

  gettransactionhistory() {
    const id = this.props.auth.user.id;
    axios
      .get(url + "api/transactionhistorydashboard/" + id)
      .then((res) => {
        this.setState({ history: res.data });
      })
      .catch();
  }

  getdepositdetails() {
    const id = this.props.auth.user.id;
    axios
      .post(url + "api/depositdetails/" + id)
      .then((res) => {})
      .catch();
  }

  getbtcdeposit() {
    const id = this.props.auth.user.id;
    axios
      .post(url + "api/btcdepositdetails/" + id)
      .then((res) => {})
      .catch();
  }

  gettokendepositdetails() {
    const id = this.props.auth.user.id;
    axios
      .post(url + "api/tokendeposit/" + id)
      .then((res) => {})
      .catch();
  }

  getAddress() {
    const id = this.props.auth.user.id;

    axios.get(url + "api/getseperateaddress/" + id).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].currencySymbol == "ETH") {
          var eth_address = res.data[i].currencyAddress;
          var ethbal = res.data[i].balance;
          this.setState({ eth_address: eth_address });
          this.setState({ ethbalance: ethbal });
        }
        if (res.data[i].currencySymbol == "BTC") {
          var btc_address = res.data[i].currencyAddress;
          var btcbal = res.data[i].balance;

          this.setState({ btc_address: btc_address });
          this.setState({ btcbalance: btcbal });
        }
        if (res.data[i].currencySymbol == "GO") {
          var go_address = res.data[i].currencyAddress;
          var gobal = res.data[i].balance;

          this.setState({ go_address: go_address });
          this.setState({ gobalance: gobal.toFixed(2) });
        }
        if (res.data[i].currencySymbol == "InvGO") {
          var invbal = res.data[i].balance;
          this.setState({ invbalance: invbal });
        }
        if (res.data[i].currencySymbol == "GC") {
          var invbal = res.data[i].balance;
          this.setState({ gcbalance: invbal });
        }
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.profile !== undefined &&
      nextProps.auth.profile.data !== undefined &&
      nextProps.auth.profile.data.message !== undefined
    ) {
      toast(nextProps.auth.profile.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      nextProps.auth.profile = undefined;
      this.getData();
    }
  }

  getData() {
    if (this.props.auth !== undefined && this.props.auth.user !== undefined) {
      const id = this.props.auth.user.id;
      axios
        .get(url + "api/userget/" + id)
        .then((res) => {
          this.setState(res.data);
          if (res.data.lastname == "") {
            this.props.history.push("/Settings");
          }
        })
        .catch();
    }
  }

  onadd = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    if (this.state.currency == "ETH") {
      const buyamount = e.target.value;
      const price = this.state.token_price_eth;
      var fullamount = buyamount * price;
      this.setState({ amount: fullamount });
    }

    if (this.state.currency == "BTC") {
      const buyamount = e.target.value;
      const price = this.state.token_price_btc;
      var fullamount = buyamount * price;
      this.setState({ amount: fullamount });
    }
    if (this.state.currency == "USD") {
      const buyamount = e.target.value;
      const price = this.state.token_price_usd;
      var fullamount = buyamount * price;
      this.setState({ amount: fullamount });
    }
    this.setState({ [e.target.id]: e.target.value });
  };

  handleChange = (selectedOption) => {
    console.log("Seleeceted ioption ", selectedOption);
    if (selectedOption.value == "ETH") {
      const buyamount = this.state.genoneamount;
      var fullamount = buyamount * this.state.token_price_eth;
      this.setState({ amount: fullamount });
    }

    if (selectedOption.value == "BTC") {
      const buyamount = this.state.genoneamount;
      var fullamount = buyamount * this.state.token_price_btc;
      this.setState({ amount: fullamount });
    }
    if (selectedOption.value == "USD") {
      const buyamount = this.state.genoneamount;
      var fullamount = buyamount * this.state.token_price_usd;
      this.setState({ amount: fullamount });
    }

    this.setState({ currency: selectedOption.value });
    // console.log(`Option selected:`, selectedOption);
  };

  showpopup(address) {
    this.setState({ show: true });
    // this.setState({ cryptoAddress: address });
    this.setState({ cryptoAddress: "https://chart.googleapis.com/chart?chs=166x166&chld=L|0&cht=qr&chl="+address });

  }

  handleModal = (e) => {
    this.setState({ show: !this.state.show });
  };

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };
  render() {
    const { selectedOption } = this.state;
    const { errors } = this.state;

    return (
      <div>
        <UserHeader />
        <section className="dashboardMain">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="dashbalancebox">
                  <div className="dashBalanceTitle">
                    <h2>
                      {this.state.name} {this.state.lastname}
                      {this.state.verifiedstatus == "Verified" ? (
                        <sup>KYC Verified</sup>
                      ) : (
                        <sup
                          className="ml-2"
                          style={{ backgroundColor: "Red" }}
                        >
                          KYC Not Verified
                        </sup>
                      )}
                    </h2>
                    <h6>
                      <a href="/Settings" data-tip="Settings">
                        <i className="far fa-edit"></i>
                      </a>
                      <br /> User since{" "}
                      <span>
                        {this.state.date
                          ? moment(this.state.date).format("DD/MM/YYYY")
                          : ""}
                      </span>
                    </h6>
                  </div>
                  <h5 className="mailTitle">{this.state.email}</h5>
                  <div className="dashBalanceDetails">
                    <div className="row">
                      <div className="col-md-4 col-sm-4">
                        <div className="balCoin">
                          <img src={DashboardCoin} className="img-fluid" />
                        </div>
                      </div>
                      <div className="col-md-8 col-sm-8">
                        <div className="balCoinContent">
                          <h2>
                            <small>Balance</small>
                            {this.state.gobalance} <span>GenONE</span>
                            <br />
                            {this.state.gcbalance} <span>GenCoin</span>
                            <br />
                            {this.state.invbalance} <span>Invested GenONE</span>
                          </h2>

                          {this.state.lastbuy ? (
                            <h6>
                              Last buy{" "}
                              <span>
                                {moment(this.state.lastbuy).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                            </h6>
                          ) : (
                            ""
                          )}

                          <p>
                            <span className="mr-3">
                              <img src={BitCoinIcon} />{" "}
                              <small>{this.state.btcbalance} BTC</small>
                            </span>{" "}
                            <span>
                              <img src={EtheriumIcon} />{" "}
                              <small>
                                {this.state.ethbalance} {""}ETH
                              </small>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="availableCoins">
                  <h6>
                    To buy* GenONE Token, deposit amount to your below address
                    from your external wallet:
                  </h6>
                  <ul>
                    <li>
                      <div className="avcTitle">
                        <h3>
                          <img src={MbitCoinIcon} /> Bitcoin
                        </h3>
                        <div className="qrCopyIconMain">
                          {/* <a href="#" className="mr-5" data-tip="Scan">
                            <img src={QrCodeIcon} />
                          </a> */}
                          <button
                            className="input-group-append-icon"
                            onClick={() =>
                              this.showpopup(this.state.btc_address)
                            }
                          >
                            <i class="fa fa-qrcode" aria-hidden="true"></i>
                          </button>
                          {/* <a href="#" data-tip="Copy Address">
                            <i className="far fa-copy"></i>
                          </a> */}
                          <CopyToClipboard
                            text={this.state.btc_address}
                            onCopy={() => this.setState({ copiedbtc: true })}
                          >
                            <button className="input-group-append-icon">
                              <i
                                className="far fa-copy"
                                data-tip="Copy Address"
                              ></i>
                            </button>
                          </CopyToClipboard>
                          {this.state.copiedbtc ? (
                            <span style={{ color: "green" }}>Copied.</span>
                          ) : null}
                        </div>
                      </div>
                      <h5>{this.state.btc_address}</h5>
                    </li>
                    <li>
                      <div className="avcTitle">
                        <h3>
                          <img src={MetheriumIcon} /> Ethereum
                        </h3>
                        <div className="qrCopyIconMain">
                          {/* <a href="#" className="mr-5" data-tip="Scan">
                            <img src={QrCodeIcon} />
                          </a> */}
                          <button
                            className="input-group-append-icon"
                            onClick={() =>
                              this.showpopup(this.state.eth_address)
                            }
                          >
                            <i class="fa fa-qrcode" aria-hidden="true"></i>
                          </button>
                          {/* <a href="#"><i className="far fa-copy" ></i></a> */}
                          <CopyToClipboard
                            text={this.state.eth_address}
                            onCopy={() => this.setState({ copied: true })}
                          >
                            <button className="input-group-append-icon">
                              <i
                                className="far fa-copy"
                                data-tip="Copy Address"
                              ></i>
                            </button>
                          </CopyToClipboard>
                          {this.state.copied ? (
                            <span style={{ color: "green" }}>Copied.</span>
                          ) : null}
                        </div>
                      </div>
                      <h5>{this.state.eth_address}</h5>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="dashRecentHistory">
                  <h5>
                    Recent Transaction History{" "}
                    <a href="/Transactionhistory" className="float-right">
                      more <i className="fas fa-angle-double-right"></i>
                    </a>
                  </h5>
                  <ul>
                    {this.state.history.map((answer, i) => {
                      var noHTML = /(<([^>]+)>)/gi; // regex for removing HTML tags
                      var clean_desc = answer.txid.replace(noHTML, ""); // remove all html tags
                      var echo_val = clean_desc.substring(0, 15);
                      var link =
                        "https://ropsten.etherscan.io/tx/" + answer.txid;

                      return (
                        <li>
                          <div className="coinTransId">
                            <h3>
                              {/* <img src={BitCoinIcon} /> */}
                              {answer.currency} <span>{answer.amount}</span>
                            </h3>
                            <p>
                              Trans ID{" "}
                              <span>
                                {/* <a target="_blank" href={link}>
                                  {echo_val}....
                                </a> */}
                                {echo_val}
                              </span>
                            </p>
                          </div>
                          <div className="transTypeDate">
                            <h3 className="transTypeDeposite">
                              {answer.transferType}
                            </h3>
                            <p>
                              Date{" "}
                              <span>
                                {moment(answer.created_date).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                            </p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="dashCalculator">
                  <h5>Calculator</h5>
                  <p>Enter amount to calculate token</p>
                  <div className="calcInput">
                    <div className="input-group">
                      {/* <input
                        type="text"
                        className="form-control"
                        value="1500"
                      /> */}
                      <input
                        name="genoneamount"
                        onChange={this.onadd}
                        value={this.state.genoneamount}
                        error={errors.genoneamount}
                        id="genoneamount"
                        type="text"
                        name="genoneamount"
                        className={classnames("form-control", {
                          invalid: errors.genoneamount,
                        })}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">GenONE</span>
                      </div>
                    </div>
                    <h4>=</h4>
                    <div className="input-group">
                      <input
                        name="buyamount"
                        onChange={this.onadd}
                        value={this.state.amount}
                        error={errors.amount}
                        id="amount"
                        type="text"
                        name="amount"
                        className={classnames("form-control", {
                          invalid: errors.amount,
                        })}
                      />
                      <div className="input-group-append">
                        {/* <span className="input-group-text">ETH</span> */}
                        <Select
                          value={selectedOption}
                          onChange={this.handleChange}
                          options={this.state.currencydata}
                          theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#ffba00",
                      primary: "#ffba00",
                    },
                  })}
                        />
                      </div>
                    </div>
                    {/* <input
                      type="button"
                      name=""
                      value="Calculate"
                      className="btn btnType1 mb-0 ml-3 d-none d-xl-block"
                    /> */}
                  </div>
                  <input
                    type="button"
                    name=""
                    value="Calculate"
                    className="btn btnType1 mb-4 ml-0 mt-3 d-block d-xl-none"
                  />
                  <h6>
                    1 GenONE ={" "} {this.state.token_price_usd}USD | 1 GenONE = {" "}{this.state.token_price_eth} ETH | 1 GenONE =
                    {" "} {this.state.token_price_btc} BTC
                  </h6>
                </div>
                <div className="dashSupport">
                  <h5>
                    <span>Support Ticket</span>{" "}
                    <a href="/Support" className="m-0 btn btnType1 py-1 px-3">
                      Create Support Ticket
                    </a>
                  </h5>
                  <div className="dashsuppotTicket">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                        <div className="dashSupportTicketDateTime">
                          <span>Create Date</span>
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-6 col-5">
                        <div className="dashSupportTicketSubject">
                          <span>Subject</span>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                        <div className="dashSupportTicketStatus">
                          <span>Status</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.supportarray.map((answer, i) => {
                    return (
                      <div className="dashsuppotTicket">
                        <div className="row">
                          <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                            <div className="dashSupportTicketDateTime">
                              {moment(answer.created_date).format(
                                "DD/MM/YYYY  h:mm "
                              )}{" "}
                            </div>
                          </div>
                          <div className="col-lg-7 col-md-6 col-sm-6 col-5">
                            <div className="dashSupportTicketSubject">
                              {answer.subject}
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                            <div className="dashSupportTicketStatus">
                              <span className="dashOpenTicket">
                                {" "}
                                {answer.closedstatus}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <Modal
                show={this.state.show}
                onHide={() => {
                  this.handleModal();
                }}
                centered
              >
                <Modal.Header closeButton>
                  <h4>Scan QR Code</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 mx-auto">
                      {/* <QRCode value={this.state.cryptoAddress} /> */}
                      <img src={this.state.cryptoAddress} className="img-fluid" />
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btnType1 smallFont py-2 px-3 mr-2"
                    onClick={() => {
                      this.handleModal();
                    }}
                  >
                    Cancel
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </section>
        <UserFooter />
      </div>
    );
  }
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Dashboard);
