import React, { Component } from "react";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import keys from "../actions/config";
import "react-toastify/dist/ReactToastify.css";
import * as moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { store } from "react-notifications-component";
import Select from "react-select";
import classnames from "classnames";

const url = keys.baseUrl;

class Withdraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_assigned: false,
      _id: "",
      currency: "",
      withdrawamount: "",
      transaction_fee: "",
      finalamount: 0,
      eth_address: "",
      ethbalance: 0,
      gobalance: 0,
      go_address: "",
      btcbalance: 0,
      btc_address: "",
      receiveraddress: "",
      history: [],
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }

    console.log("nextProps in KyC", nextProps.auth);
    if (
      nextProps.auth.updatenewuser !== undefined &&
      nextProps.auth.updatenewuser.data !== undefined &&
      nextProps.auth.updatenewuser.data.message !== undefined
    ) {
      store.addNotification({
        title: "Wonderful!",
        message: nextProps.auth.updatenewuser.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
    }
  }

  componentDidMount() {
    this.getcurrency();
    this.getvalue();
    this.getbalance();
    this.gethistory();
  }
  getvalue() {
    axios.get(url + "api/feedata").then((res) => {
      // console.log("resss",res.data)
      this.setState(res.data[0]);
    });
  }

  gethistory() {
    const id = this.props.auth.user.id;
    axios.get(url + "api/withdrawhistory/" + id).then((res) => {
      this.setState({ history: res.data });
    });
  }
  getbalance() {
    const id = this.props.auth.user.id;
    axios.get(url + "api/getseperateaddress/" + id).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].currencySymbol == "ETH") {
          var eth_address = res.data[i].currencyAddress;
          var ethbal = res.data[i].balance;
          this.setState({ eth_address: eth_address });
          this.setState({ ethbalance: ethbal });
        }
        if (res.data[i].currencySymbol == "BTC") {
          var btc_address = res.data[i].currencyAddress;
          var btcbal = res.data[i].balance;

          this.setState({ btc_address: btc_address });
          this.setState({ btcbalance: btcbal });
        }
        if (res.data[i].currencySymbol == "GO") {
          var go_address = res.data[i].currencyAddress;
          var gobal = res.data[i].balance;
          this.setState({ go_address: go_address });
          this.setState({ gobalance: gobal });
        }
      }
    });
  }

  getcurrency() {
    axios.get(url + "api/currencydata").then((res) => {
      var currencyarray = [];
      res.data.map((item, i) => {
        if(item.currencySymbol!= "GO" && item.currencySymbol!= "USD"){

        const name = item.currencySymbol;
        const value = item.currencySymbol;
        const obj = { value: name, label: value };
        currencyarray.push(obj);
        }
      });
    
      this.setState({ currencydata: currencyarray, email_assigned: true });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onadd = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    if (e.target.value !== "") {
      var initial = e.target.value;
      var fee = this.state.transaction_fee;
      var multipl= parseFloat(initial) * parseFloat(fee) ;
      // var newamount = parseFloat(initial) + parseFloat(fee);
      var newamount=parseFloat(multipl) + parseFloat(initial)
      this.setState({ finalamount: newamount });
    } else {
      this.setState({ finalamount: 0 });
    }
  };

  handleChange = (selectedOption) => {
    this.setState({
      currency: {
        value: selectedOption.value,
        label: selectedOption.value,
      },
    });
  };

  Withdraw() {
    if (this.validateForm()) {
      let fields = {};
      fields["withdrawamount"] = "";
      this.setState({ validation: fields });
      const withdrawdata = {
        id: this.props.auth.user.id,
        currency: this.state.currency.value,
        withdrawamount: this.state.withdrawamount,
        receiveraddress: this.state.receiveraddress,
        finalamount: this.state.finalamount,
        requestType: "Withdraw",
        transaction_fee: this.state.transaction_fee,
      };
      axios
        .post(url + "api/withdrawcurrency", withdrawdata)
        .then((res) => {
          if (res.data.status) {
            store.addNotification({
              title: "Wonderful!",
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true,
              },
            });
            this.gethistory();

          } else {
            store.addNotification({
              title: "Error!",
              message: res.data.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
          }
        })
        .catch();
    }
  }

  validateForm() {
    let fields = this.state.validation;
    let errors = {};
    errors["withdrawamount"] = "";
    errors["amount"] = "";
    let formIsValid = true;

    if (this.state.withdrawamount == "") {
      formIsValid = false;
      errors["withdrawamount"] = "*Please enter the value";
    }
    if (this.state.withdrawamount < 0) {
      formIsValid = false;
      errors["withdrawamount"] = "*Amount is invalid";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    const { errors } = this.state;
    const { selectedOption } = this.state;
    return (
      <div>
        <UserHeader />
        <section className="lgoinContent buyPage">
          <div className="container">
            <h2>
              <span>Withdraw</span>{" "}
              <a href="/Dashboard">
                <i className="fas fa-angle-left"></i> Dashboard
              </a>
            </h2>
            <div className="buyMain">
              <div className="row">
                <div className="col-lg-8 col-md-7 mx-auto">
                  <div className="buyAmt">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Withdraw amount</label>
                          <input
                            name="withdrawamount"
                            onChange={this.onadd}
                            value={this.state.withdrawamount}
                            error={errors.withdrawamount}
                            id="withdrawamount"
                            type="text"
                            name="withdrawamount"
                            className={classnames("form-control", {
                              invalid: errors.withdrawamount,
                            })}
                          />
                          {/* <div className="input-group-append"> */}
                          
                          {this.state.currency.value == "ETH" ? (
                        <p>ETH Balance = {this.state.ethbalance} ETH</p>
                      ) : (
                        ""
                      )}
                      {this.state.currency.value == "BTC" ? (
                        <p>BTC Balance = {this.state.btcbalance} BTC</p>
                      ) : (
                        ""
                      )}
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="col-md-3">
                            <div className="form-group">
                              <label class="invisible">hide Label</label>
                              <Select
                                value={selectedOption}
                                onChange={this.handleChange}
                                options={this.state.currencydata}
                                theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#ffba00",
                      primary: "#ffba00",
                    },
                  })}
                              />
                            </div>
                          </div>
                    </div>
               

                    <div className="form-group">
                      <label>Receiver Withdraw address</label>
                      <input
                        name="receiveraddress"
                        onChange={this.onChange}
                        value={this.state.receiveraddress}
                        error={errors.receiveraddress}
                        id="receiveraddress"
                        type="text"
                        name="receiveraddress"
                        className={classnames("form-control", {
                          invalid: errors.receiveraddress,
                        })}
                      />{" "}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-group form-group darkInput">
                          <label>
                            Final amount(inc Transaction fee -{" "}
                            {this.state.transaction_fee}
                            )
                          </label>
                          <input
                            name="finalamount"
                            onChange={this.onChange}
                            value={this.state.finalamount}
                            error={errors.finalamount}
                            id="finalamount"
                            type="text"
                            name="finalamount"
                            className={classnames("form-control", {
                              invalid: errors.finalamount,
                            })}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              {this.state.currency.value}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        className="btn btnType1"
                        name=""
                        value="Withdraw"
                        type="submit"
                        onClick={() => this.Withdraw()}
                      />
                    </div>
                    <div className="noteText">
                      <h6>Note:</h6>
                      <p>
                        <span>
                          1. Receiver withdraw address should be either BTC or
                          ETH only.
                        </span>
                        <span>
                          2. Withdraw will take 1-4 hours due to blockchain
                          network traffic.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4>Withdraw History</h4>
            <div className="table-responsive">
              <table className="table borderedTable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Withdraw</th>
                    <th>Fee</th>
                    <th>Final Amount</th>
                    <th>Coin</th>
                    <th>Trans. ID</th>
                    <th>Receiver Address</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.history.map((answer, i) => {
                    var noHTML = /(<([^>]+)>)/gi; // regex for removing HTML tags
                    var clean_desc = answer.transactionId.replace(noHTML, ""); // remove all html tags
                    var echo_val = clean_desc.substring(0, 10);
                    var link = "https://etherscan.io/tx/" + answer.transactionId;
                    return (
                      <tr>
                        <td>
                          {" "}
                          {moment(answer.created_date).format(
                            "DD/MM/YYYY  h:mm a "
                          )}
                        </td>
                        <td>{answer.transferamount}</td>
                        <td>{answer.transactionfee}</td>
                        <td>{answer.finalamount}</td>
                        <td>{answer.currency}</td>
                        <td>
                          <a target="_blank" href={link}>
                            {echo_val}....
                          </a>
                        </td>

                        <td>{answer.receiveraddress}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <UserFooter />
      </div>
    );
  }
}

Withdraw.propTypes = {
  // logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Withdraw);
