import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import ReactDOM from "react-dom";
import Tooltip from "rc-tooltip";
import Slider from "rc-slider";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import keys from "../actions/config";
import { connect } from "react-redux";
import axios from "axios";
import { store } from "react-notifications-component";

const url = keys.baseUrl;

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

class Landing extends Component {
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      // toast.success("res.data.message", {
      //   position: toast.POSITION.TOP_CENTER
      // });
      this.props.history.push("/Dashboard");
    }
    let withdraw_id = this.props.match.params.id;
    if (typeof withdraw_id != "undefined" && withdraw_id != "") {
      let passdata = { withdrawid: withdraw_id };
      axios
        .post(url + "api/withdrawrequest", passdata)
        .then((res) => {
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
          this.props.history.push("/Login");
        })
        .catch();
    }
  }
  render() {
    return (
      <div>
        <Navbar />
        <section className="landingRoadmap pt-5">
          {/* <div className="container">
    <h2 className="landinTitle">Roadmap</h2> */}
          {/* <div className="roadmapContainer">
      <div className="roadmapLeft">
      <div className="roadmapcont active" data-toggle="collapse" href="#roadmap1" role="button" aria-expanded="false" aria-controls="roadmap1">
        <div className="roadmapBall">1</div>
        <div className="roadmapText">
          <h3>Idea Development</h3>
          <p>Quarter 1 2019</p>
        </div>
      </div>
      <div className="roadmapcont" data-toggle="collapse" href="#roadmap2" role="button" aria-expanded="false" aria-controls="roadmap2">
        <div className="roadmapBall">2</div>
        <div className="roadmapText">
          <h3>Build Team and Legal Research</h3>
          <p>Quarter 2 2019</p>
        </div>
      </div>
      <div className="roadmapcont" data-toggle="collapse" href="#roadmap3" role="button" aria-expanded="false" aria-controls="roadmap3">
        <div className="roadmapBall">3</div>
        <div className="roadmapText">
          <h3>Genesis One Token Launch</h3>
          <p>Quarter 4 2019</p>
        </div>
      </div>
      <div className="roadmapcont" data-toggle="collapse" href="#roadmap4" role="button" aria-expanded="false" aria-controls="roadmap4">
        <div className="roadmapBall">4</div>
        <div className="roadmapText">
          <h3>Smart Contract Development</h3>
          <p>Quarter 1 2020</p>
        </div>
      </div>
      <div className="roadmapcont" data-toggle="collapse" href="#roadmap5" role="button" aria-expanded="false" aria-controls="roadmap5">
        <div className="roadmapBall">5</div>
        <div className="roadmapText">
          <h3>Marketing Campaign</h3>
          <p>Quarter 2 2020</p>
        </div>
      </div>
    </div>
    <div className="roadmapRight">
        <div className="collapse show" id="roadmap1">
          <div className="roadmapcontRight">
            <p>Quarter 1 2019</p>
            <h3>Idea Development</h3>
            <h6>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.</h6>
          </div>
        </div>
        <div className="collapse" id="roadmap2">
          <div className="roadmapcontRight">
            <p>Quarter 2 2019</p>
            <h3>Build Team and Legal Research</h3>
            <h6>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.</h6>
          </div>
        </div>
        <div className="collapse" id="roadmap3">
          <div className="roadmapcontRight">
            <p>Quarter 4 2019</p>
            <h3>Genesis One Token Launch</h3>
            <h6>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.</h6>
          </div>
        </div>
        <div className="collapse" id="roadmap4">
          <div className="roadmapcontRight">
            <p>Quarter 1 2020</p>
            <h3>Smart Contract Development</h3>
            <h6>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.</h6>
          </div>
        </div>
        <div className="collapse" id="roadmap5">
          <div className="roadmapcontRight">
            <p>Quarter 2 2020</p>
            <h3>Marketing Campaign</h3>
            <h6>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.</h6>
          </div>
        </div>
      </div>
    </div> */}
           <div className="container">
            <h2 className="landinTitle">Roadmap</h2>
            <div className="roadmapContainer">
            <div className="roadmapLeft">
                <div
                  className="roadmapcont active"
                  data-toggle="collapse"
                  href="#roadmap1"
                  role="button"
                  aria-expanded="false"
                  aria-controls="roadmap1"
                >
                  <div className="roadmapBall">1</div>
                  <div className="roadmapText">
                    <h3>GenesisOne Token Development</h3>
                    {/* <p>May to Sep, 2020</p> */}
                  </div>
                </div>
                <div
                  className="roadmapcont"
                  data-toggle="collapse"
                  href="#roadmap2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="roadmap2"
                >
                  <div className="roadmapBall">2</div>
                  <div className="roadmapText">
                    <h3>Development of NeoGenesis</h3>
                    {/* <p>Aug 30 to Dec 1, 2020</p> */}
                  </div>
                </div>
                <div
                  className="roadmapcont"
                  data-toggle="collapse"
                  href="#roadmap3"
                  role="button"
                  aria-expanded="false"
                  aria-controls="roadmap3"
                >
                  <div className="roadmapBall">3</div>
                  <div className="roadmapText">
                    <h3>Launch of NeoGenesis Platform</h3>
                    {/* <p>Sep to Nov, 2020</p> */}
                  </div>
                </div>
                <div
                  className="roadmapcont"
                  data-toggle="collapse"
                  href="#roadmap4"
                  role="button"
                  aria-expanded="false"
                  aria-controls="roadmap3"
                >
                  <div className="roadmapBall">4</div>
                  <div className="roadmapText">
                    <h3>Launch of Regional offices and sales armies</h3>
                    {/* <p>Sep to Nov, 2022</p> */}
                  </div>
                </div>
              </div>
              <div className="roadmapRight">
                <div className="collapse show" id="roadmap1">
                  <div className="roadmapcontRight">
                    {/* <p>- May to Sep, 2020</p> */}
                    <h3>GenesisOne Token Development</h3>
                    <h6>GenesisOne Token Development – Last quarter of 2020</h6>
			              <h6>GenesisOne Token and GenCoin Launch – 3rd quarter of 2021</h6>
		                <h6>EG Group and GenesisOne Ecosystem Websites launched – 3rd quarter of 2021</h6>
                  </div>
                </div>
                <div className="collapse" id="roadmap2">
                  <div className="roadmapcontRight">
                    {/* <p>- June to Sep, 2020</p> */}
                    <h3>Development of NeoGenesis</h3>
                    <h6>
                    Development of NeoGenesis Exchange/Wallet & NeoGenesis CyberLottery, Sports Wagering Platform and custom lotteries - 3rd  through 2nd Quarter of 2022
                    </h6>
                    <h6>Series A Capital Raise – Open</h6>
                  </div>
                </div>
                <div className="collapse" id="roadmap3">
                  <div className="roadmapcontRight">
                    {/* <p>- November, 2020</p> */}
                    <h3>Launch of NeoGenesis Platform</h3>
                    <h6>
                    Launch of NeoGenesis Platform</h6> 
			              <h6>Onboarding of Key Staff globally</h6>
			              <h6>Onboarding of Global Affiliate System</h6>
                    <h6>Launch of Global Marketing Efforts to include YouTube GenesisOne vlogging series, email, social media, external Exchange and listing companies and digital publications and media.</h6>
                  </div>
                </div>
                <div className="collapse" id="roadmap4">
                  <div className="roadmapcontRight">
                    {/* <p>- November, 2022</p> */}
                    <h3>Launch of Regional offices and sales armies!</h3>
                    <h6>Launch of Regional offices and sales armies!  - 4th Quarter of 2022</h6> 
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>
        <Footer />
      </div>
    );
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(Landing));
