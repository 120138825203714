import React, { Component } from "react";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import ReactDOM from "react-dom";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import classnames from "classnames";
import keys from "../actions/config";
import axios from "axios";
import * as moment from "moment";
import { anySeries } from "async";

const url = keys.baseUrl;

const optionsTerms = [
  { value: "3", label: "3 Months" },
  { value: "6", label: "6 Months" },
  { value: "9", label: "9 Months" },
  { value: "12", label: "12 Months" },
  { value: "15", label: "15 Months" },
  { value: "18", label: "18 Months" },
  { value: "21", label: "21 Months" },
  { value: "24", label: "24 Months" },
];

const optionsProjectType = [
  { value: "Locker", label: "Locker" },
  { value: "Games", label: "Games" },
  { value: "Health Care", label: "Health Care" },
  { value: "Agriculture", label: "Agriculture" },
  { value: "Travel and Tourism", label: "Travel and Tourism" },
  { value: "Software", label: "Software" },
  { value: "Research", label: "Research" },
];

class Transfer extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      projects: [],
      projecttype: "",
      projectid: "",
      investamount: "",
      termpercentage: "",
      termduration: "",
      lockeramount: "",
      lockerterm: "",
      history: [],
      returnterms: [],
      term: "",
      errors: {},
      termarray: [],
      projects: [],
      returnamount: "",
    };
  }

  handleTermsChange = (selectedTerms) => {
    this.setState({ term: selectedTerms.value });
    var termselected = selectedTerms.value;
    // console.log("term selected", termselected);
    var investedamount = this.state.investamount;
    // console.log("invested amount", investedamount);
    var retper = this.state.termpercentage;
    // console.log("return percentage", retper);

    var mulamount = investedamount * retper;
    // console.log("mulamount",mulamount)
  };

  handlelockerChange = (optionsTerms) => {
    // console.log("sleceted", optionsTerms);
    this.setState({ lockerterm: optionsTerms.value });
  };

  handleProjectChange = (selectedProject) => {
    this.setState({ selectedProject });
    // console.log(`Option selected:`, selectedProject);
  };
  investModal = (answer) => {
    this.setState({ show: !this.state.show });
    this.setState({ projectid: answer._id });
    this.setState({ projecttype: answer.name });
    this.setState({ termduration: answer.returnterm });
    this.setState({ termpercentage: answer.returnpercentage });

    const id = answer._id;
    axios.get(url + "api/projectdata/" + id).then((res) => {
      this.setState({ returnterms: res.data.returnterms });

      var returnterms_arr = [];
      for (var i = 0; i < res.data.returnterms.length; i++) {
        returnterms_arr.push({
          label: res.data.returnterms[i],
          value: res.data.returnterms[i],
        });
      }
      this.setState({ termarray: returnterms_arr });
    });
  };

  handleModal = (e) => {
    this.setState({ show: !this.state.show });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  componentDidMount() {
    this.getprojectdata();
    this.gethistory();
    this.getinvesteddetails();
  }

  getinvesteddetails() {
    const id = this.props.auth.user.id;
    axios.get(url + "api/investeddetails/" + id).then((res) => {});
  }

  gethistory() {
    const id = this.props.auth.user.id;
    axios.get(url + "api/investhistory/" + id).then((res) => {
      this.setState({ history: res.data });
    });
  }

  getprojectdata() {
    axios.get(url + "api/projectdetails").then((res) => {
      this.setState({ projects: res.data });
    });
  }
  movetolocker() {
    if (
      typeof this.state.lockeramount != "undefined" &&
      this.state.lockeramount != "" &&
      typeof this.state.lockerterm != "undefined" &&
      this.state.lockerterm != ""
    ) {
      const Data = {
        userid: this.props.auth.user.id,
        lockeramount: this.state.lockeramount,
        lockerterm: this.state.lockerterm,
      };
      axios
      .post(url + "api/lockeradd", Data)
      .then((res) => {
        if (res.data.status) {
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
          this.gethistory();
        } else {
          store.addNotification({
            title: "Error!",
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
      })
      .catch();
    }
    else{
      store.addNotification({
        title: "Error!",
        message: "Fill all the fields",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
    }
  }

  Investsubmit() {
    if (
      typeof this.state.investamount != "undefined" &&
      this.state.investamount != ""
    ) {
      const Data = {
        userid: this.props.auth.user.id,
        projectid: this.state.projectid,
        projectname: this.state.projecttype,
        // termduration: this.state.termduration,
        termduration: this.state.term,
        termpercentage: this.state.termpercentage,
        investamount: this.state.investamount,
      };
      axios
        .post(url + "api/investadd", Data)
        .then((res) => {
          if (res.data.status) {
            store.addNotification({
              title: "Wonderful!",
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
            this.handleModal();
            this.gethistory();
          } else {
            store.addNotification({
              title: "Error!",
              message: res.data.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
          }
        })
        .catch();
    } else {
      store.addNotification({
        title: "Error!",
        message: "Enter the Token Value to be Invest",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
    }
  }

  render() {
    const { selectedTerms } = this.state;
    const { selectedProject } = this.state;
    const { errors } = this.state;

    return (
      <div>
        <UserHeader />
        <section className="lgoinContent buyPage">
          <div className="container">
            <h2>
              <span>Invest GenOne</span>{" "}
              <a href="/Dashboard">
                <i className="fas fa-angle-left"></i> Dashboard
              </a>
            </h2>

            <h4>Locker</h4>
            <div className="col-md-12">
              <div className="col-md-3">
                <label>Amount</label>
                <input
                  onChange={this.onChange}
                  value={this.state.lockeramount}
                  id="lockeramount"
                  type="text"
                  className="form-control"
                  placeholder="Enter the  Amount"
                />
              </div>

              <div className="col-md-3">
                <label>Term </label>
                <Select
                  // value={selectedTerms}
                  onChange={this.handlelockerChange}
                  options={optionsTerms}
                  label="Single select"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#ffba00",
                      primary: "#ffba00",
                    },
                  })}
                />
              </div>

              <br />
              <div className="col-md-3">
                <input
                  className="btn btnType1"
                  name=""
                  value="Move to Locker"
                  type="submit"
                  readOnly
                  onClick={() => {
                    this.movetolocker();
                  }}
                />
              </div>
            </div>

            <br />

            <div className="buyMain">
              <h4>Projects</h4>
              <Modal
                show={this.state.show}
                onHide={() => {
                  this.handleModal();
                }}
              >
                <Modal.Header closeButton>
                  <h4>{this.state.projecttype}</h4>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12 mx-auto">
                      <div className="buyAmt">
                        <div className="input-group form-group">
                          <label>Token to Invest</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.investamount}
                            id="investamount"
                            type="text"
                            className="form-control"
                            // error={errors.investamount}
                            placeholder="Enter the Investing Amount"
                            // className={classnames("form-control", {
                            //   invalid: errors.investamount,
                            // })}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">GenOne</span>
                          </div>
                        </div>

                        {/* 
                        <div className="form-group">
                          <div className="form-group">
                            <label>Project </label>
                            <Select
                              value={selectedProject}
                              onChange={this.handleProjectChange}
                              options={optionsProjectType}
                              label="Single select"
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#ffba00",
                                  primary: "#ffba00",
                                },
                              })}
                            />
                            <input
                              type="text"
                              readOnly
                              className="form-control"
                              value={this.state.projecttype}
                            />
                          </div>
                        </div> */}

                        <div className="form-group">
                          <div className="form-group">
                            <label>Duration(Months)</label>
                            <Select
                              value={selectedTerms}
                              onChange={this.handleTermsChange}
                              options={this.state.termarray}
                              label="Single select"
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#ffba00",
                                  primary: "#ffba00",
                                },
                              })}
                            />

                            {/* <input
                              type="text"
                              className="form-control"
                              readOnly
                              value={this.state.termduration}
                            /> */}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="form-group">
                            <label>Percentage</label>
                            <input
                              type="text"
                              readOnly
                              className="form-control"
                              value={this.state.termpercentage}
                            />
                          </div>
                        </div>
                        <div className="form-group text-center">
                          <input
                            className="btn btnType1"
                            name=""
                            value="Invest Now"
                            type="submit"
                            readOnly
                            onClick={() => {
                              this.Investsubmit();
                            }}
                          />
                        </div>
                        <div className="noteText">
                          <h6>Note:</h6>
                          <p>
                            <span>
                              Estimated % of Token Return = 0.005 GenOne / 3 Mo.
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btnType1 smallFont py-2 px-3"
                    onClick={() => {
                      this.handleModal();
                    }}
                  >
                    Cancel
                  </button>
                </Modal.Footer>
              </Modal>
              <div className="row">
                {this.state.projects.map((answer, i) => {
                  var link = keys.baseUrl + "projectpdfs/" + answer.pdffile;
                  return (
                    <div className="col-md-6">
                      <div className="iconMainBox">
                        <div className="iconBox">
                          <h5>{answer.name}</h5>
                          {/* <p>
                            <span>Balance :</span> 1500 GenOne
                          </p>    */}
                          <p>
                            <span>Description :</span> {answer.description}
                          </p>

                          <p>
                            <span>Return % :</span> {answer.returnpercentage} %
                          </p>
                          {/* <p>
                            <span>Duration(Months) :</span>
                            {answer.returnterm}
                          </p> */}
                          <p className="text-center">
                            {/* <span>WhitePaper :</span>
                            {link} */}

                            <a
                              href={link}
                              target="_blank"
                              className="btn btnType1 py-1 px-4 m-0 mr-2"
                            >
                              View Whitepaper
                            </a>
                            <Button
                              onClick={() => {
                                this.investModal(answer);
                              }}
                              className="btn btnType1 py-1 px-4 m-0"
                            >
                              Invest Now
                            </Button>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <h4>Invest History</h4>
            <div className="table-responsive">
              <table className="table borderedTable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Invest ID</th>
                    <th>Project</th>
                    <th>Token </th>
                    <th>% Value</th>
                    <th>Term Duration</th>
                    {/* <th>Term End</th> */}
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.history.map((answer, i) => {
                    return (
                      <tr>
                        <td>
                          {moment(answer.createddate).format(
                            "DD/MM/YYYY  h:mm a "
                          )}
                        </td>
                        <td>{answer.investid}</td>
                        <td>{answer.projectname}</td>
                        <td>{answer.Investamount}</td>
                        <td>{answer.termpercentage}%</td>
                        <td>{answer.projectduration} Months</td>
                        {/* <td>{answer.termend}</td> */}
                        <td>{answer.type}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <UserFooter />
      </div>
    );
  }
}

Transfer.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(Transfer));
