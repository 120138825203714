import React, { Component } from 'react'
import MemberOne from "../images/member1.jpg"
import MemberTwo from "../images/member2.jpg"
import MemberThree from "../images/member3.jpg"
import MemberFour from "../images/member4.jpg"
import MemberFive from "../images/member5.jpg"
import MemberSix from "../images/member6.jpg"
import MemberSeven from "../images/member7.jpg"
import MemberEight from "../images/member8.jpg"
import MemberNine from "../images/member9.png";
import MemberTen from "../images/member10.jpg";
import MemberEleven from "../images/member11.jpg";
import Membertwelve from "../images/member12.jpg";
import Memberthirteen from "../images/member13.png";
import Navbar from './Navbar'
import Footer from './Footer'
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import ReactDOM from 'react-dom';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider'
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import keys from "../actions/config";
import { connect } from "react-redux";
import axios from "axios";
import { store } from "react-notifications-component";



const url = keys.baseUrl;

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};


class Landing extends Component {


  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      // toast.success("res.data.message", {
      //   position: toast.POSITION.TOP_CENTER
      // });
      this.props.history.push("/Dashboard");
    }
    let withdraw_id = this.props.match.params.id;
    if(typeof withdraw_id != "undefined" && withdraw_id!=""){
      let passdata = {withdrawid:withdraw_id}
      axios
          .post(url+"api/withdrawrequest",passdata)
          .then(res => {
            store.addNotification({
              title: "Wonderful!",
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true
              }
            });
            this.props.history.push("/Login");
          })
          .catch()
    }
  }
	render() {
		return (
      <div>
      <Navbar />
<section className="landingOurTeam pt-5">
  <div className="container">
    <h2 className="landinTitle">Our Team</h2>
    <div className="row">
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://www.linkedin.com/in/j-edouard-eggroupb44a01187/" target="_blank">
            <img src={MemberOne} className="img-fluid" />
            <span>James Edouard<small>President and CEO</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://www.linkedin.com/in/elis-li-432737155/" target="_blank">
            <img src={MemberTwo} className="img-fluid" />
            <span>Elis Li<small>Public Relations & Brand Director</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://www.linkedin.com/in/mohana-sundaram-3516a0a9/" target="_blank">
            <img src={MemberThree} className="img-fluid" />
            <span>Mohana Sundaram<small>Chief Technical Officer</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://www.linkedin.com/in/jenicks-raja-33401b24/" target="_blank">
            <img src={MemberNine} className="img-fluid" />
            <span>Jenicks Raja<small>Project Manager</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://www.linkedin.com/in/vigneshprofile/" target="_blank">
            <img src={MemberFour} className="img-fluid" />
            <span>Vignesh M<small>Product Development Officer</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://www.linkedin.com/in/ranjith-babu-992030159/" target="_blank">
            <img src={MemberFive} className="img-fluid" />
            <span>Ranjith Babu<small>Senior UI/UX Designer</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://www.linkedin.com/in/hafiz-ahamed-87731115/" target="_blank">
            <img src={MemberSix} className="img-fluid" />
            <span>Hafiz Ahamed<small>UI Developer</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://www.linkedin.com/in/veera-sarma-405231b7/" target="_blank">
            <img src={MemberSeven} className="img-fluid" />
            <span>Veera Sharma<small>Blockchain Full Stack Developer</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://www.linkedin.com/in/thirupathi-a-9a293216b/" target="_blank">
            <img src={MemberEight} className="img-fluid" />
            <span>Thirupathi A<small>Unity 3D and Full Stack Developer</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://www.linkedin.com/in/alwin-mathew-45a9b5142/" target="_blank">
            <img src={MemberTen} className="img-fluid" />
            <span>Alwin Mathew<small>MERN Stack Developer</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://linkedin.com/in/kathleen-edouard-364231229/" target="_blank">
            <img src={MemberEleven} className="img-fluid" />
            <span>Kathleen Edouard<small>Business Development Leader</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="#" target="_blank">
            <img src={Membertwelve} className="img-fluid" />
            <span>Livenie Edouard<small>VP - of International Development</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://www.linkedin.com/in/leah-audrey-51803422b/" target="_blank">
            <img src={Memberthirteen} className="img-fluid" />
            <span>Leah A. Monestime<small>Business Development Specialist</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
    <Footer />
</div>
);
	}
}


Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, {  })(withRouter(Landing));
