import React, { Component } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ReactDOM from 'react-dom';


class TermsAndConditions extends Component {
	render() {
		return (
      <div>
      <Navbar />
      <section className="lgoinContent pageTextContent">
      <div className="container">
        <h2>Privacy Policy</h2>
        <p><span>Effective Date: April20, 2020</span></p>

        <h5>Introduction</h5>
        <p>Emirates Genesis Group SA/GenesisOne and its subsidiaries and affiliated companies (“Emirates Genesis Group SA/GenesisOne”, “we”, “our”, “us”) understands and respects our users’ need for privacy. This Privacy Policy explains how Emirates Genesis Group SA/GenesisOne collects, uses and shares information we collect about you.</p>

        <h5>Applicability:</h5>
        <p>This policy applies to Information we collect when you use our websites (including our company website <a href="https://www.eggroupinternational.com/">www.eggroupinternational.com</a>, <a href="https://www.genesisonetokens.com/">www.genesisonetokens.com</a>, <a href="https://www.genesisone.com/">www.genesisone.io</a>, <a href="https://www.genesisoneworld.com/">www.genesisoneworld.com</a>, our Emirates Genesis Group SA/GenesisOne, NeoGenesisNet Services website, and our developer website.Emirates Genesis Group SA/GenesisOne.com, (collectively, the “Site”), and when you interact with us, through the use of Emirates Genesis Group SA/GenesisOne products and services (including Emirates Genesis Group SA/GenesisOneNet, collectively, the “Emirates Genesis Group SA/GenesisOne Service” or the “Services”) described in our Terms and Conditions.</p>
        <p>This Privacy Policy does not apply to the GenesisOne Token and GenOne Coin Ledger software, which is open source, or the distributed open source global network accessed through that software (collectively, “GenesisOne Ledger”). However, if you access GenesisOne Ledger-related content from a hyperlink on our Site, we may collect the information identified in the “Information We Collect Automatically” sections below in connection with your use of the Site.</p>

        <h5>TABLE OF CONTENTS:</h5>
        <ul className="listType1">
            <li>How we collect your information</li>
            <li>How we use your information</li>
            <li>How we share your information</li>
            <li>Legal bases for processing (for EEA users)</li>
            <li>Transfer of personal data to other countries</li>
            <li>How we protect your information</li>
            <li>How long we retain your information</li>
            <li>Children’s information</li>
            <li>Your rights</li>
            <li>Changes to this privacy policy</li>
            <li>Third-party services, applications and websites</li>
            <li>Contacting us</li>
        </ul>

        <h3 className="mb-4">How We Collect Your Information</h3>
        <h5>INFORMATION WE COLLECT FROM OUR WEBSITES AND EVENTS:</h5>
        <p><span><b>Information You Provide to Us:</b></span>  We collect information you provide directly to us when you browse our Site, create an online account, post messages to our forums or wikis, provide feedback through surveys, participate in any interactive features, contests, promotions, sweepstakes, activities or events. The types of information we may collect include your name, email address, username, password, location and any other information you choose to provide.</p>
        <p><span><b>Information We Collect Automatically through the use of Cookies and Other Tracking Technology:</b></span>  When you visit the Company’s website, we obtain certain information by automated means, such as cookies, web beacons, web server logs and other technologies. A “cookie” is a text file that websites send to a visitor’s computer or other Internet-connected device to identify the visitor’s browser or to store information or settings in the browser. A “web beacon,” also known as an Internet tag, pixel tag or clear GIF, links web pages to web servers and their cookies and may be used to transmit information collected through cookies back to a web server.</p>
        <p>We may use these automated technologies to collect information about your equipment, browsing actions, and usage patterns. The information we obtain in this manner may include your device IP address, identifiers associated with your devices, types of devices connected to our services, web browser characteristics, device characteristics, language preferences, referring/exit pages, clickstream data, and dates and times of visits to our Site.</p>
        <p>The information we collect through cookies and similar technologies helps us (1) remember your information so you will not have to re-enter it; (2) understand how you use and interact with our website; (3) measure the usability of our website and the effectiveness of our communications; and (4) otherwise manage and enhance our website, and help ensure it is working properly.</p>
        <p>Your browser may tell you how to be notified when you receive certain types of cookies or how to restrict or disable certain types of cookies. Please note, however, that without cookies you may not be able to use all of the features of our website.</p>
        <h5>INFORMATION WE COLLECT RELATING TO OUR PRODUCTS AND SERVICES:</h5>
        <p>Members, GenesisOneToken-holders, GenesisOne Coin-holders, Financial institutions and other organizations around the world, together referred to as “Emirates Genesis Group SA/GenesisOneNet Customers” use our products and services (“the Emirates Genesis Group SA/GenesisOne Service” or “the Services”) to exchange data relating to financial transactions and, in the case of OnDemand Liquidity, settle those financial transactions.</p>
        <p>These Emirates Genesis Group SA/GenesisOne Services process Personal Data of individuals (“Personal Data”) in the two following contexts:</p>
        <ul className="listType1">
            <li><span><b>Emirates Genesis Group SA/GenesisOne’s Provision of the Services:</b></span>  Personal Data that Emirates Genesis Group SA/GenesisOne collects for its own purposes relating to the provision of the Services (for example, contact details of employees of Emirates Genesis Group SA/GenesisOne Customers)</li>
            <li><span><b>Customer Use of the Services:</b></span> Personal Data that Emirates Genesis Group SA/GenesisOneNet Customers collect and supply to the Emirates Genesis Group SA/GenesisOne Service as part of their use of the Services (for example, Personal Data contained in messages or files that Emirates Genesis Group SA/GenesisOnecustomers send)</li>
        </ul>
        <h5>EMIRATES GENESIS GROUP SA/GENESISONE’S PROVISION OF THE SERVICES:</h5>

        <p><span><b>Information You Provide to Us:</b></span>  Emirates Genesis Group SA/GenesisOne collects Personal Data relating to employees, officers, and/or directors of Emirates Genesis Group SA/GenesisOne’s Customers that is directly provided to us. Personal Data typically includes contact details such as name, title, work address, work email address, and work telephone number.</p>
        <p><span><b>Information from Other Sources:</b></span>  Emirates Genesis Group SA/GenesisOne collects information publicly available on a Customer’s website, or through publicly available resources relating to employees, officers, or directors of Emirates Genesis Group SA/GenesisOne’s Customers and Potential Customers. This information typically includes publicly available information such as name, title, work address, work email address, and work telephone number.</p>
        <p><span><b>Information We Collect Automatically:</b></span>  Emirates Genesis Group SA/GenesisOne may collect Emirates Genesis Group SA/GenesisOneusernames, user passwords, user’s Internet Protocol (“IP”) addresses, computer and connection information such as browser type, version, and operating system during the course of managing the Emirates Genesis Group SA/GenesisOne services.</p>
        <h5>CUSTOMER’S USE OF THE SERVICES:</h5>
        <p>Our products enables Emirates Genesis Group SA/GenesisOneNet Customers to connect to their digital asset exchange accounts in order to check balances, receive payment estimates, submit instructions for payments initiation, and check status of payments for entries, games, P2P, B2B in progress.</p>
        <p>The Emirates Genesis Group SA/GenesisOneCloud product enables Emirates Genesis Group SA/GenesisOne Customers to connect to initiate payments, purchase cryptocurrencies, and view and manage balances and transaction listings.</p>
        <p><span><b>Information You Provide to Us:</b></span> Through use of the Services, Emirates Genesis Group SA/GenesisOne may collect and process Personal Data of individuals collected by Emirates Genesis Group SA/GenesisOne's Customers for purposes of processing transactions in accordance with the Terms and Conditions we have entered into with you.</p>
        <p>Personal Data used for facilitating transactions typically includes sender and beneficiary account name and account number (which could be the account holder’s name); description of the transaction; payment amounts and instructions; and sender and beneficiary addresses and phone number.</p>


        <h3 className="mb-4">How We Use Your Information</h3>
        <h5>EMIRATES GENESIS GROUP SA/GENESISONE WEBSITES AND EVENTS:</h5>
        <p><span><b>Emirates Genesis Group SA/GenesisOne collects this data for various purposes, including to:</b></span></p>
        <ul className="listType1">
            <li>Respond to your comments, questions and requests and provide customer service;</li>
            <li>Monitor and analyze trends, usage and activities in order to operate and improve our Site;</li>
            <li>Manage your online account(s) and send you technical notices, updates, security alerts and support and administrative messages;</li>
            <li>Organize regional or local events, and the registration and management of customer representatives that are active in Emirates Genesis Group SA/GenesisOne advisory and working groups;</li>
            <li>Link or combine with information we get from others to help understand your needs and provide you with better service; and</li>
            <li>Carry out any other purpose for which the information was collected.</li>
        </ul>
        <h5>EMIRATES GENESIS GROUP SA/GENESISONE’S PROVISION OF THE SERVICES</h5>
        <p><span><b>Emirates Genesis Group SA/GenesisOne collects this data for purposes relating to the provision of the Services, and include the following:</b></span></p>
        <ul className="listType1">
            <li>The administration, monitoring, and ongoing management of the services and products;</li>
            <li>The development, deployment, management, support, and invoicing of the services and products; and</li>
            <li>The organization of regional or local events, and the registration and management of customer representatives that are active in Emirates Genesis Group SA/GenesisOne advisory and working groups.</li>
        </ul>
        <h5>CUSTOMER USE OF THE SERVICES</h5>
        <p><span><b>Emirates Genesis Group SA/GenesisOne collects this data for various purposes, including to:</b></span></p>
        <ul className="listType1">
            <li>Respond to your comments, questions and requests and provide customer service;</li>
            <li>Monitor and analyze trends, usage and activities in order to operate and improve our Services;</li>
            <li>Manage your online account(s) and send you technical notices, updates, security alerts and support and administrative messages; and</li>
            <li>Carry out any other purpose for which the information was collected.</li>
        </ul>

        <h3 className="mb-4">How We Share Your Information</h3>
        <h5>EMIRATES GENESIS GROUP SA/GENESISONE WEBSITES AND EVENTS, AND EMIRATES GENESIS GROUP SA/GENESISONE’S PROVISION OF THE SERVICES:</h5>
        <p><span><b>We may share your Personal Data as follows:</b></span></p>
        <ul className="listType1">
            <li>With vendors, consultants and other service providers who need access to such information to carry out work on our behalf;</li>
            <li>In response to a request for information if we believe disclosure is in accordance with any applicable law, regulation or legal process, or as otherwise required by any applicable law, rule or regulation;</li>
            <li>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property and safety of us or any third-party;</li>
            <li>In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business to another company;</li>
            <li>With your consent or at your direction; and</li>
            <li>We may also share aggregated or de-identified information, which cannot reasonably be used to identify you.</li>
        </ul>
        <h3 className="mb-2">Legal Bases For Processing (For EEA Users)</h3>
        <p>If you are an individual from the European Economic Area (“EEA”), we collect and process your personal data only where we have legal basis for doing so under applicable EU laws.</p>
        <p>The legal basis depends on the Services you use and how you use them. This means we collect and use your personal data only:</p>
        <ul className="listType1">
            <li>To fulfill our contractual obligations to you;</li>
            <li>To operate our business, including to improve and develop our services, for fraud prevention purposes, improve user experience, or other legitimate interest; and/or</li>
            <li>As otherwise in compliance with law.</li>
        </ul>
        <p>If you have any questions about the legal basis for processing, please refer to the “Your Rights” section below or contact us at the address listed in the “Contact Us” section.</p>
        <h3 className="mb-2">Transfer Of Personal Data To Other Countries</h3>
        <p>We transfer your Personal Data to countries outside the United Kingdom and the European Economic Area (“EEA”), including, but not limited to the United States, to where-ever ourEmirates Genesis Group SA/GenesisOne IT systems (including email) are located. When required for the provision of the Services Emirates Genesis Group SA/GenesisOne may transfer and store Personal Data to locations in or outside the European Economic Area (“EEA”).</p>
        <p>Emirates Genesis Group SA/GenesisOne complies with the EU-U.S. Privacy Shield Framework and Swiss-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union, the United Kingdom, and Switzerland to the United States. Emirates Genesis Group SA/GenesisOnewill certify to the Department of Commerce that it adheres to the Privacy Shield Principles. If there is any conflict between the terms in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our certification, please visit https://www.privacyshield.gov/</p>
        <p>Emirates Genesis Group SA/GenesisOne is subject to the investigatory and enforcement powers of the Federal Trade Commission (FTC). Under certain conditions, you can invoke binding arbitration for complaints regarding Emirates Genesis Group SA/GenesisOne’s Privacy Shield compliance. For further information, please refer to: https://www.privacyshield.gov/article?id=ANNEX-I-introduction</p>
        <p>In cases of onward transfer to third-parties of Personal Data received pursuant to the EU-U.S. Privacy Shield and the Swiss-U.S. Privacy Shield, Emirates Genesis Group SA/GenesisOne shall remain potentially liable.</p>
        <h3 className="mb-2">How We Protect Your Information</h3>
        <p>We are committed to protecting your information. We maintain appropriate administrative, technical and physical safeguards designed to protect the Personal Data you provide against accidental, unlawful or unauthorized destruction, loss, alteration, access, disclosure or use.</p>
        <h3 className="mb-2">How Long We Retain Your Information</h3>
        <p>We will retain your Personal Data on file for as long as necessary for our legitimate business purposes. Please note that in certain cases, legal or regulatory obligations require us to retain specific records for a longer period of time.</p>
        <h3 className="mb-2">Children's Information</h3>
        <p>Our Services are not directed to children under the age of 16. If you learn that a child under the age of 16 has provided us with personal information without consent, please contact us.</p>

        <h3 className="mb-2">Your Rights</h3>
        <p>You have the right to request a copy of your information, to object to our use of your information (including for marketing purposes), where applicable, to correct your information, to request the deletion or restriction of your information, or to request your information in a structured, electronic format.</p>
        <p>Please note that we may retain certain information as required by law or for legitimate business purposes. If you would like to exercise any of these rights, please contact <a href="mailto:privacy@eggroupinternational.com">privacy@eggroupinternational.com</a></p>
        <p>In compliance with the Privacy Shield Principles, Emirates Genesis Group SA/GenesisOne commits to resolve complaints about our collection or use of your personal information. EU and Swiss individuals with inquiries or complaints regarding our Privacy Shield policy should first contact Emirates Genesis Group SA/GenesisOne <a href="mailto:atprivacy@eggroupinternational.com">atprivacy@eggroupinternational.com</a></p>
        <p>Emirates Genesis Group SA/GenesisOne has further committed to refer unresolved Privacy Shield complaints to JAMS, an alternative dispute resolution provider located in the United States. If you do not receive timely acknowledgment of your complaint from us, or if we have not addressed your complaint to your satisfaction, please visit <a href="https://www.jamsadr.com/eu-us-privacy-shield" target="_blank">https://www.jamsadr.com/eu-us-privacy-shield</a> for more information or to file a complaint. The services of JAMS are provided at no cost to you.</p>
        <h3 className="mb-2">Changes To This Privacy Policy</h3>
        <p>We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of this policy, and in some cases, we may provide you with additional notice (such as adding a statement to the homepages of our Site or sending you an email notification). We encourage you to review the Privacy Policy whenever you interact with us to stay informed about our information practices and the ways you can help protect your privacy.</p>
        <h3 className="mb-2">Third-Party Services, Applications, And Websites</h3>
        <p>Certain third-party services, websites, or applications you use, or navigate to from our Services may have separate user terms and privacy policies that are independent of this Policy. This includes, for example, websites owned and operated by our customers or partners. We are not responsible for the privacy practices of these third-party services or applications. We recommend carefully reviewing the user terms and privacy statement of each third-party service, website, and/or application prior to use.</p>
        <h3 className="mb-2">Contacting Us</h3>
        <p>To submit questions regarding this Privacy Policy, you can contact Emirates Genesis Group SA/GenesisOne by emailing us at <a href="mailto:privacy@eggroupinternational.com">privacy@eggroupinternational.com</a>.</p>

      </div>
    </section>
      <Footer />
</div>
);
	}
}

export default TermsAndConditions