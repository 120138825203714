import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  FORGOT,
  USER_LOADING,
  USER_FORGOT,
  USER_UPDATE
} from "./types";
import keys from "./config";

import { store } from "react-notifications-component";



const url = keys.baseUrl;

export const profileUser = profileData => dispatch => {
  axios
    .post(url + "api/profileupdate", profileData)
    .then(res =>
      dispatch({
        type: USER_UPDATE,
        payload: res
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const PasswordUpdate = profileData => dispatch => {
  axios
    .post(url + "api/passwordupdate", profileData)
    .then(res =>
      
      
      
      dispatch({
        type: USER_UPDATE,
        payload: res,
        
      })
      
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};



export const kycadd = kycData => dispatch => {

  axios
    .post(url + "api/kycupdate", kycData)
    .then(res =>  
      dispatch({
        type: USER_UPDATE,
        payload: res,      
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};




export const forgotUser = (forgotData) => dispatch => {
  axios
    .post(url + "api/forgotpassword", forgotData)
    .then(res =>
      dispatch({
        type: USER_FORGOT,
        payload: res
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


export const resetUser = (resetData) => dispatch => {
  axios
      .post(url+"api/resetpassword", resetData)
      .then(res => dispatch({
          type: USER_FORGOT,
          payload: res,
      })
      )
      .catch(err =>
          dispatch({
              type: GET_ERRORS,
              payload: err.response.data
          })
      );
 };

