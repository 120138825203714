import React, { Component } from "react";
import BannerCoin from "../images/bannerCoin.png";
import SpeakerIcon from "../images/speakerIcon.png";
import RevRightImg from "../images/rev-right-img.png";
import RevLeftImg from "../images/rev-left-img.png";
import RevLeftImgTwo from "../images/rev-left-img2.png";
import TokenTitleCoin from "../images/token-title-coin.png";
import chartAllocation from "../images/chartAllocation.png";
import MemberOne from "../images/member1.jpg";
import MemberTwo from "../images/member2.jpg";
import MemberThree from "../images/member3.jpg";
import MemberFour from "../images/member4.jpg";
import MemberFive from "../images/member5.jpg";
import MemberSix from "../images/member6.jpg";
import MemberSeven from "../images/member7.jpg";
import MemberEight from "../images/member8.jpg";
import MemberNine from "../images/member9.png";
import MemberTen from "../images/member10.jpg";
import MemberEleven from "../images/member11.jpg";
import Membertwelve from "../images/member12.jpg";
import Memberthirteen from "../images/member13.png";
import usaFlag from "../images/usaFlag.png"
import phFlag from "../images/phFlag.png"
import indiaFlag from "../images/indiaFlag.png"
import franceFlag from "../images/FranceFlag.png"
import uaeFlag from "../images/uaeFlag.png"
import Navbar from "./Navbar";
import Footer from "./Footer";
import Marquee from "react-text-marquee";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import ReactDOM from "react-dom";
import Tooltip from "rc-tooltip";
import Slider from "rc-slider";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import keys from "../actions/config";
import { connect } from "react-redux";
import axios from "axios";
import { store } from "react-notifications-component";

const url = keys.baseUrl;

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

class Landing extends Component {
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      // toast.success("res.data.message", {
      //   position: toast.POSITION.TOP_CENTER
      // });
      this.props.history.push("/Dashboard");
    }
    let withdraw_id = this.props.match.params.id;
    if (typeof withdraw_id != "undefined" && withdraw_id != "") {
      let passdata = { withdrawid: withdraw_id };
      axios
        .post(url + "api/withdrawrequest", passdata)
        .then((res) => {
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
          this.props.history.push("/Login");
        })
        .catch();
    }
  }
  render() {
    return (
      <div>
        <Navbar />
        <header className="homeBanner">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div
                  className="homeBannerImg wow flipInY"
                  data-wow-delay=".15s;"
                >
                  <img src={BannerCoin} className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 col-md-8 mx-md-auto">
                <div
                  className="homeBannerText wow fadeInUp"
                  data-wow-delay=".25s"
                >
                  <h2>GenOne</h2>
                  <h3>Invest on Blockchain Gaming Projects.</h3>
                  <h6>Leader of Blockchain Gaming Platform </h6>
                  <div className="bannerTimerBox">
                    <h4>GenesisOne Token Series A Sale</h4>
                  {/*}  <h6>Pre ICO - May 15,2020 to July 15,2020</h6>*/}
                    <h6>GenesisOne is open now to investors for our Series A GenesisOne Token sale!</h6>
                    {/*  <h6>November 1,2020(USA)</h6>
                   <h6>ICO - August 1,2020 to Nov 1,2020</h6> */}

                  {/*}  <div id="countdown">
                      <div id="tiles"></div>
                    </div>
                    {/* <div className="sliderCustomRange">
                      <div className="slider-range-top">
                        <p>SoftCap - 1 Million USD</p>
                        <p>HardCap - 1 Billion USD</p>
                      </div>
                      <div className="mt-1 mb-4">
                        <Slider
                          min={0}
                          max={100}
                          defaultValue={0}
                          marks={{ 0: "0%" }}
                          step={null}
                        />
                      </div>
                    </div> */}
                    <br/>
                    <a href="/Login" className="btn btnType1 mt-3">
                      Buy GenOne Now
                    </a>
                    <p>We accept: ETH, BTC ,USD & EUR </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-10 col-lg-12 mx-auto">
                <div className="newsMain wow fadeIn" ata-wow-delay="1s">
                  <div className="todayPrice">1 GenOne = $1 USD</div>
                  <div className="speakerIcon">
                    <img src={SpeakerIcon} className="img-fluid" />
                  </div>
                  <div className="simple-marquee-container">
                    <Marquee text="“GenesisOne is launching their Series A investment rounds to interested parties! Be sure to come in now – you missed the ground floor opportunity, so take heed to join the family now!”" />
                  </div>
                  {/* <div className="newsMore"><a href="#">More <i className="fas fa-angle-right"></i></a></div> */}
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="GenOneRevolution" id="About">
          <div className="container">
            <h2 className="landinTitle">GenOne Revolution</h2>
            <div className="row">
              <div className="col-md-6">
                <div className="GenOneRevolutionContent">
                  <h4>Purpose and idea behind the GenesisOne Ecosystem</h4>
                  <p>
                    The idea behind the GenesisOne Ecosystem is to gradually bring on millions of
                    users globally and we’ve identified key centers we’re going
                    to focus on in terms of major population centers. The
                    lottery allows us to onboard more members globally than
                    simply another cryptocurrency. So we will aggressively
                    develop that into our core business by penetrating areas
                    without much internet service or Smartphone owners with our
                    team of agents who go to local Western Union/Moneygram
                    agents to use them as affiliates who will have access to a
                    master account from which local people can sign up for our
                    Token and play the lottery, etc.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="GenOneRevolutionImg">
                  <img src={RevRightImg} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="mobReverseColum">
              <div className="row mt-5">
                <div className="col-md-6">
                  <div className="GenOneRevolutionImg">
                    <img src={RevLeftImg} className="img-fluid" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="GenOneRevolutionContent">
                    <h4>Dedicated Crypto Gaming Platform</h4>
                    <p>
                      GenesisOne is all set to establish a global gambling
                      platform on a multitude of various sports games globally
                      through an app interface, virtual casino offerings through
                      an app to play the Slots, Baccarat, Poker, Roulette,
                      Blackjack, and Craps. At the same time, through the app
                      offering multiple angle views of players, we’ll be able to
                      provide Poker Tournament play with competitors worldwide.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-6">
                <div className="GenOneRevolutionContent">
                  <h4>GenOne Tokens are insured to Protected</h4>
                  <p>
                    The person in effect fills out a KYC application allowing
                    them to become Members and thereby able to receive member
                    exclusives such as the burnable feature for their tokens, if
                    they are lost or stolen allowing those lost GenesisOne
                    Tokens once properly confirmed of their loss to be destroyed
                    and useless to whomever took/finds them. We then reissue new
                    tokens to that person by minting them a new set. The loss
                    must be documented and through the blockchain ledger we can
                    assess whether the person did indeed lose them and not just
                    performed a transaction and trying to not pay another party
                    or something.
                  </p>
                  <p className="text-center">
                    <a href="/Login" className="btn btnType1">
                      Buy GenOne Now
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="GenOneRevolutionImg">
                  <img src={RevLeftImgTwo} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tokenDetails">
          <div className="container">
            <div className="mapTokenDetails">
              <h2 className="landinTitle">
                <img src={TokenTitleCoin} /> Token Details
              </h2>
              <div className="row">
                <div className="col-lg-6 col-md-12 mb-md-4">
                  <ul>
                    <li className="greyMap">
                      <span>Token Name</span> GenesisOne (GenOne){" "}
                    </li>
                    <li className="transMap">
                      <span>Total Supply</span>1 Undecillion (USD) ~ 1 Sextillion (Euro)
                    </li>
                    {/* <li className="blackMap">
                      <span>Premine</span> 6,000,000 (6x10-28% of Total Supply)
                    </li>
                    <li className="transMap">
                      <span>Presale</span>  5,000,000 (83.33% of PreMine Supply)
                    </li> */}
                    <li className="greyMap">
                      <span>Bounty Program</span> 500,000 (8.33% of Premine Supply)
                    </li>
                    <li className="transMap">
                      <span>Price</span>  1 GenOne = 1 USD or Equivalent ETH
                    </li>
                    <li className="blackMap">
                      <span>Token Sale Start and End Date</span>OPEN{" "}
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-12">
                  <ul>
                    {/* <li className="greyMap">
                      <span>Soft Cap</span> $10
                    </li> */}
                    <li className="greyMap">
                      <span>Hard Cap</span>No Hard Cap
                    </li>
                    <li className="transMap">
                      <span>Series A Funding Stage Goal</span>$5,000,000 (5 Million)
                    </li>
                    {/* <li className="transMap">
                      <span>ICO Crowdsale Goal</span> 100,000,000,000 (100 Billion)
                    </li> */}
                    <li className="greyMap">
                      <span>Mintable</span> Yes (If Token is lost or because of Theft)
                    </li>
                    <li className="transMap">
                      <span>Burnable/Freezable</span> Yes
                    </li>
                    <li className="blackMap">
                      <span>Series A Funding Stage</span> OPEN
                    </li>
                    {/* <li className="blackMap">
                      <span>Token Sale Start and End Date (USA)</span> Sep 10, 2020
                      to January 30, 2021
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tokenAllocation">
          <div className="container">
            <h2 className="landinTitle">Token Allocation</h2>
            <div className="row">
              <div className="col-md-8 mx-auto">
                <div className="taChart">
                  <img
                    src={chartAllocation}
                    className="img-fluid d-block mx-auto"
                  />
                </div>
              </div>
            </div>
            <p className="text-center mt-5">
            {/*}  <a
                href="/pdf/Whitepaper.pdf"
                download
                className="btn buttonType2"
              >
                Download Whitepaper
              </a>*/}
              <div className="btn buttonType2 dropdown p-0">
                <a className="nav-link dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Download Whitepaper</a>
                 <div class="dropdown-menu   dropWhitePaper w-100" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="/pdf/Whitepaper.pdf" download ><img src={usaFlag} class="img-fluid" alt="" /> English</a>
                  <a class="dropdown-item" href="/pdf/Hindi-whitepaper.pdf" download><img src={indiaFlag} class="img-fluid" alt="" /> Hindi</a>
                  <a class="dropdown-item" href="/pdf/Arabic-whitepaper.pdf" download><img src={uaeFlag} class="img-fluid" alt="" /> Arabic</a>
                  <a class="dropdown-item" href="/pdf/French-whitepaper.pdf" download><img src={franceFlag} class="img-fluid" alt="" /> French</a>
                  <a class="dropdown-item" href="/pdf/Filipino-whitepaper.pdf" download><img src={phFlag} class="img-fluid" alt="" /> Filipino</a>
                </div>
              </div>
            </p>
          </div>
        </section>
        <section className="landingRoadmap">
          <div className="container">
            <h2 className="landinTitle">Roadmap</h2>
            <div className="roadmapContainer">
            <div className="roadmapLeft">
                <div
                  className="roadmapcont active"
                  data-toggle="collapse"
                  href="#roadmap1"
                  role="button"
                  aria-expanded="false"
                  aria-controls="roadmap1"
                >
                  <div className="roadmapBall">1</div>
                  <div className="roadmapText">
                    <h3>GenesisOne Token Development</h3>
                    {/* <p>May to Sep, 2020</p> */}
                  </div>
                </div>
                <div
                  className="roadmapcont"
                  data-toggle="collapse"
                  href="#roadmap2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="roadmap2"
                >
                  <div className="roadmapBall">2</div>
                  <div className="roadmapText">
                    <h3>Development of NeoGenesis</h3>
                    {/* <p>Aug 30 to Dec 1, 2020</p> */}
                  </div>
                </div>
                <div
                  className="roadmapcont"
                  data-toggle="collapse"
                  href="#roadmap3"
                  role="button"
                  aria-expanded="false"
                  aria-controls="roadmap3"
                >
                  <div className="roadmapBall">3</div>
                  <div className="roadmapText">
                    <h3>Launch of NeoGenesis Platform</h3>
                    {/* <p>Sep to Nov, 2020</p> */}
                  </div>
                </div>
                <div
                  className="roadmapcont"
                  data-toggle="collapse"
                  href="#roadmap4"
                  role="button"
                  aria-expanded="false"
                  aria-controls="roadmap3"
                >
                  <div className="roadmapBall">4</div>
                  <div className="roadmapText">
                    <h3>Launch of Regional offices and sales armies</h3>
                    {/* <p>Sep to Nov, 2022</p> */}
                  </div>
                </div>
              </div>
              <div className="roadmapRight">
                <div className="collapse show" id="roadmap1">
                  <div className="roadmapcontRight">
                    {/* <p>- May to Sep, 2020</p> */}
                    <h3>GenesisOne Token Development</h3>
                    <h6>GenesisOne Token Development – Last quarter of 2020</h6>
			              <h6>GenesisOne Token and GenCoin Launch – 3rd quarter of 2021</h6>
		                <h6>EG Group and GenesisOne Ecosystem Websites launched – 3rd quarter of 2021</h6>
                  </div>
                </div>
                <div className="collapse" id="roadmap2">
                  <div className="roadmapcontRight">
                    {/* <p>- June to Sep, 2020</p> */}
                    <h3>Development of NeoGenesis</h3>
                    <h6>
                    Development of NeoGenesis Exchange/Wallet & NeoGenesis CyberLottery, Sports Wagering Platform and custom lotteries - 3rd  through 2nd Quarter of 2022
                    </h6>
                    <h6>Series A Capital Raise – Open</h6>
                  </div>
                </div>
                <div className="collapse" id="roadmap3">
                  <div className="roadmapcontRight">
                    {/* <p>- November, 2020</p> */}
                    <h3>Launch of NeoGenesis Platform</h3>
                    <h6>
                    Launch of NeoGenesis Platform</h6> 
			              <h6>Onboarding of Key Staff globally</h6>
			              <h6>Onboarding of Global Affiliate System</h6>
                    <h6>Launch of Global Marketing Efforts to include YouTube GenesisOne vlogging series, email, social media, external Exchange and listing companies and digital publications and media.</h6>
                  </div>
                </div>
                <div className="collapse" id="roadmap4">
                  <div className="roadmapcontRight">
                    {/* <p>- November, 2022</p> */}
                    <h3>Launch of Regional offices and sales armies!</h3>
                    <h6>Launch of Regional offices and sales armies!  - 4th Quarter of 2022</h6> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="landingOurTeam">
          <div className="container">
            <h2 className="landinTitle">Our Team</h2>
            <div className="row">
              <div className="col-md-3 col-sm-4">
                <div className="landingTeamContent">
                  <a
                    href="https://www.linkedin.com/in/j-edouard-eggroupb44a01187/"
                    target="_blank"
                  >
                    <img src={MemberOne} className="img-fluid" />
                    <span>
                      James Edouard<small>President and CEO</small>
                    </span>
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="landingTeamContent">
                  <a
                    href="https://www.linkedin.com/in/elis-li-432737155/"
                    target="_blank"
                  >
                    <img src={MemberTwo} className="img-fluid" />
                    <span>
                      Elis Li<small>Public Relations & Brand Director</small>
                    </span>
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="landingTeamContent">
                  <a
                    href="https://www.linkedin.com/in/mohana-sundaram-3516a0a9/"
                    target="_blank"
                  >
                    <img src={MemberThree} className="img-fluid" />
                    <span>
                      Mohana Sundaram<small>Cheif Technical Officer</small>
                    </span>
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://www.linkedin.com/in/jenicks-raja-33401b24/" target="_blank">
            <img src={MemberNine} className="img-fluid" />
            <span>Jenicks Raja<small>Project Manager</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
              <div className="col-md-3 col-sm-4">
                <div className="landingTeamContent">
                  <a
                    href="https://www.linkedin.com/in/vigneshprofile/"
                    target="_blank"
                  >
                    <img src={MemberFour} className="img-fluid" />
                    <span>
                      Vignesh M<small>Product Development Officer</small>
                    </span>
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="landingTeamContent">
                  <a
                    href="https://www.linkedin.com/in/ranjith-babu-992030159/"
                    target="_blank"
                  >
                    <img src={MemberFive} className="img-fluid" />
                    <span>
                      Ranjith Babu<small>Senior UI/UX Designer</small>
                    </span>
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="landingTeamContent">
                  <a
                    href="https://www.linkedin.com/in/hafiz-ahamed-87731115/"
                    target="_blank"
                  >
                    <img src={MemberSix} className="img-fluid" />
                    <span>
                      Hafiz Ahamed<small>UI Developer</small>
                    </span>
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="landingTeamContent">
                  <a
                    href="https://www.linkedin.com/in/veera-sarma-405231b7/"
                    target="_blank"
                  >
                    <img src={MemberSeven} className="img-fluid" />
                    <span>
                      Veera Sharma<small>Blockchain Full Stack Developer</small>
                    </span>
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="landingTeamContent">
                  <a
                    href="https://www.linkedin.com/in/thirupathi-a-9a293216b/"
                    target="_blank"
                  >
                    <img src={MemberEight} className="img-fluid" />
                    <span>
                      Thirupathi A
                      <small>Unity 3D and Full Stack Developer</small>
                    </span>
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://www.linkedin.com/in/alwin-mathew-45a9b5142/" target="_blank">
            <img src={MemberTen} className="img-fluid" />
            <span>Alwin Mathew<small>MERN Stack Developer</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://linkedin.com/in/kathleen-edouard-364231229/" target="_blank">
            <img src={MemberEleven} className="img-fluid" />
            <span>Kathleen Edouard<small>Business Development Leader</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="#" target="_blank">
            <img src={Membertwelve} className="img-fluid" />
            <span>Livenie Edouard<small>VP - of International Development</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="col-md-3 col-sm-4">
        <div className="landingTeamContent">
          <a href="https://www.linkedin.com/in/leah-audrey-51803422b/" target="_blank">
            <img src={Memberthirteen} className="img-fluid" />
            <span>Leah A. Monestime<small>Business Development Specialist</small></span>
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
            </div>
          </div>
        </section>
        {/* <section className="secFAQ">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-10 col-sm-12 ml-auto mr-auto">
                <div className="faqCont">
                  <div className="homeAccordian wow fadeIn">
                    <h2 className="landinTitle">FAQ</h2>
                    <div id="accordion">
                      <div className="card">
                        <div
                          className="card-header wow flipInX"
                          id="headingOne"
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              <span className="question">
                                When and How will I use my Genesis Tokens?
                              </span>{" "}
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </h5>
                        </div>

                        <div
                          id="collapseOne"
                          className="collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. Lorem Ipsum is simply dummy text of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever since
                              the 1500s, when an unknown printer took a galley
                              of type and scrambled it to make a type specimen
                              book.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header wow flipInX"
                          id="headingTwo"
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              <span className="question">
                                When will I receive my tokens?
                              </span>{" "}
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              Urna condimentum mattis pellentesque id nibh
                              tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header wow flipInX"
                          id="headingThree"
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <span className="question">
                                {" "}
                                What exchange can I use?
                              </span>{" "}
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              Urna condimentum mattis pellentesque id nibh
                              tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet.. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div
                          className="card-header wow flipInX"
                          id="headingFour"
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              <span className="question">
                                What crypto coins need to deposit the buy the
                                tokens?
                              </span>{" "}
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseFour"
                          className="collapse"
                          aria-labelledby="headingFour"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              Urna condimentum mattis pellentesque id nibh
                              tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header wow flipInX"
                          id="headingFive"
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              <span className="question">
                                Are you a registered company?
                              </span>{" "}
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseFive"
                          className="collapse"
                          aria-labelledby="headingFive"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              Urna condimentum mattis pellentesque id nibh
                              tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet.. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center mt-4">
                    <Link to="/FAQ" className="btn buttonType2">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <Footer />
      </div>
    );
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(Landing));
