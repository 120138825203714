import React, { Component } from "react";
import "react-phone-number-input/style.css";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import ReactDOM from "react-dom";
import Select from "react-select";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { store } from "react-notifications-component";
import { toast, ToastContainer } from "react-toastify";
import keys from "../actions/config";
import { withRouter } from "react-router-dom";
import { profileUser, PasswordUpdate } from "../actions/useractions";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-number-input";

const url = keys.baseUrl;

// const options = [
//   { value: "Australia", label: "Australia" },
//   { value: "United States", label: "United States" },
//   { value: "Japan", label: "Japan" },
//   { value: "South Africa", label: "South Africa" },
//   { value: "Sri Lanka", label: "Sri Lanka" },
//   { value: "India", label: "India" },
//   { value: "China", label: "China" },
//   { value: "Pakistan", label: "Pakistan" }
// ];
class Settings extends Component {
  constructor(props) {
    super(props);
    this.options = countryList().getData();
    this.state = {
      _id: "",
      name: "",
      lastname: "",
      address1: "",
      address2: "",
      otp: "",
      otpstatus: "",
      email: "",
      city: "",
      pincode: "",
      googleauth: "",
      phonenumber: "",
      country: "",
      currentpassword: "",
      resetpassword: "",
      password2: "",
      otpstatus: "",
      validation: {},
      validationtfa: {},
      errors: {},
      secretcode: "",
      onecode: "",
      loginpassword: "",
      options: this.options,
      value: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    console.log("nextProps in settingss", nextProps.auth);
    if (
      nextProps.auth.updatenewuser !== undefined &&
      nextProps.auth.updatenewuser.data !== undefined &&
      nextProps.auth.updatenewuser.data.message !== undefined
    ) {
      store.addNotification({
        title: "Wonderful!",
        message: nextProps.auth.updatenewuser.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
      this.getData();
      if (nextProps.auth.updatenewuser.data.update.IDproofno == "") {
        this.props.history.push("/Kyc");
      }
    }
  }

  getData() {
    if (this.props.auth !== undefined && this.props.auth.user !== undefined) {
      const id = this.props.auth.user.id;
      axios
        .get(url + "api/usergetsettings/" + id)
        .then((res) => {
          // console.log("Settigns user", res);
          this.setState(res.data);
          this.setState({
            country: {
              value: res.data.country,
              label: res.data.country,
            },
          });
          this.setState({
            secretcode: res.data.newSecret.secret,
            Factor2AImg:
              "https://chart.googleapis.com/chart?chs=166x166&chld=L|0&cht=qr&chl=otpauth://totp/GenesisOne%3Fsecret=" +
              res.data.newSecret.secret,
          });
          if (res.data.google != "Disabled") {
            this.setState({ googleauth: "Verified" });
          }
        })
        .catch();
    }
  }

  handleChange = (selectedOption) => {
    // this.setState({ country: selectedOption.value });
    this.setState({
      country: {
        value: selectedOption.value,
        label: selectedOption.value,
      },
    });
  };

  changeHandler = (country) => {
    console.log("Value selected  ", country.label);
    this.setState({ country });
    // console.log("thsss",this.state.country)
  };

  onProfileSubmit = (e) => {
    if (this.state.otpstatus == "Verified") {
      if (this.validateForm()) {
        let fields = {};
        fields["name"] = "";
        fields["lastname"] = "";
        fields["address1"] = "";
        fields["address2"] = "";
        fields["city"] = "";
        fields["pincode"] = "";
        fields["country"] = "";
        fields["phonenumber"] = "";

        this.setState({ validation: fields });
        const userData = {
          id: this.props.auth.user.id,
          name: this.state.name,
          lastname: this.state.lastname,
          phonenumber: this.state.phonenumber,
          address1: this.state.address1,
          address2: this.state.address2,
          email: this.state.email,
          city: this.state.city,
          pincode: this.state.pincode,
          country: this.state.country.label,
        };
        // this.props.profileUser(userData);
        // console.log("User data", userData);
        axios
          .post(url + "api/profileupdate", userData)
          .then((res) => {
            if (res.status === 200) {
              // console.log("resposne", res);
              console.log("idd", res.data.update.IDproofno);
              if (res.data.update.IDstatus === "Not verified") {
                console.log("inside");
                this.props.history.push("/Kyc");
              }
              store.addNotification({
                title: "Wonderful!",
                message: res.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 1500,
                  onScreen: true,
                },
              });
            }
          })
          .catch();
      }
    } else {
      store.addNotification({
        title: "Error!",
        message: "Verify the Mobile Number First",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    }
  };

  onchangepassword = (e) => {
    // e.preventDefault();
    if (
      typeof this.state.currentpassword != "undefined" &&
      this.state.currentpassword != ""
    ) {
      if (this.state.resetpassword === this.state.password2) {
        const newpass = {
          id: this.props.auth.user.id,
          currentpassword: this.state.currentpassword,
          resetpassword: this.state.resetpassword,
          password2: this.state.password2,
        };
        console.log("new passowrd", newpass);
        // this.props.PasswordUpdate(newpass);
        axios.post(url + "api/passwordupdate", newpass).then((res) => {
          console.log("sss", res.data);
          if (res.data.status) {
            store.addNotification({
              title: "Wonderful!",
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error!",
              message: res.data.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
          }
        });
      } else {
        store.addNotification({
          title: "Error!",
          message: "Passwords must be matching",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
          },
        });
      }
    } else {
      store.addNotification({
        title: "Error!",
        message: "Enter the Current Password",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
    }
  };

  onsendOtp = (e) => {
    console.log("phonenumber", this.state.phonenumber);
    if (
      typeof this.state.phonenumber != "undefined" &&
      this.state.phonenumber != ""
    ) {
      const phonenumberdata = {
        _id: this.props.auth.user.id,
        phonenumber: this.state.phonenumber,
      };
      axios
        .post(url + "api/sendotpprofile", phonenumberdata)
        .then((res) => {
          if (res.data.success) {
            store.addNotification({
              title: "Wonderful!",
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
          } else {
            store.addNotification({
              title: "Error!",
              message: res.data.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
          }
        })
        .catch();
    } else {
      store.addNotification({
        title: "Error!",
        message: "Phone Number should not be empty",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
    }
  };

  TFAenable() {
    if (this.validateFormTFA()) {
      let fields = {};
      fields["loginpassword"] = "";
      fields["secretcode"] = "";
      fields["onecode"] = "";

      this.setState({ validationtfa: fields });
      const tfadata = {
        loginpassword: this.state.loginpassword,
        secretcode: this.state.secretcode,
        onecode: this.state.onecode,
        id: this.props.auth.user.id,
      };
      axios
        .post(url + "api/tfa-enable", tfadata)
        .then((res) => {
          if (res.data.success) {
            store.addNotification({
              title: "Wonderful!",
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
            this.getData();
          } else {
            store.addNotification({
              title: "Error!",
              message: res.data.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
          }
        })
        .catch();
    }
  }

  validateFormTFA() {
    let fields = this.state.validationtfa;
    let errors = {};
    errors["loginpassword"] = "";
    errors["secretcode"] = "";
    errors["onecode"] = "";

    let formIsValid = true;

    if (this.state.loginpassword == "") {
      formIsValid = false;
      errors["loginpassword"] = "*Please enter the Password";
    }
    if (this.state.secretcode == "") {
      formIsValid = false;
      errors["secretcode"] = "*Please enter the Secret Code";
    }
    if (this.state.onecode == "") {
      formIsValid = false;
      errors["onecode"] = "*Please enter the Authentication code";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  OTPCheck() {
    if (
      typeof this.state.otpcheck != "undefined" &&
      this.state.otpcheck != ""
    ) {
      const otpdata = {
        _id: this.props.auth.user.id,
        otp: this.state.otpcheck,
        mobilenumber: this.state.phonenumber,
      };
      console.log("otpdata", otpdata);
      axios
        .post(url + "api/checkotpprofile", otpdata)
        .then((res) => {
          if (res.data.success) {
            store.addNotification({
              title: "Wonderful!",
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
            this.getData();
          } else {
            store.addNotification({
              title: "Error!",
              message: res.data.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 1500,
                onScreen: true,
              },
            });
          }
        })
        .catch();
    } else {
      store.addNotification({
        title: "Error!",
        message: "OTP should not be empty",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
        },
      });
    }
  }

  validateForm() {
    let fields = this.state.validation;
    let errors = {};
    errors["name"] = "";
    errors["lastname"] = "";
    errors["address1"] = "";
    errors["address2"] = "";
    errors["city"] = "";
    errors["pincode"] = "";
    errors["country"] = "";
    errors["phonenumber"] = "";
    let formIsValid = true;

    if (this.state.name == "") {
      formIsValid = false;
      errors["name"] = "*Please enter the First Name";
    }
    if (this.state.lastname == "") {
      formIsValid = false;
      errors["lastname"] = "*Please enter the Last Name";
    }
    if (this.state.phonenumber == "") {
      formIsValid = false;
      errors["phonenumber"] = "*Please entert the Mobile Number";
    }

    if (this.state.address1 == "") {
      formIsValid = false;
      errors["address1"] = "*Please enter the Address";
    }
    if (this.state.address2 == "") {
      formIsValid = false;
      errors["address2"] = "*Please enter the State";
    }
    if (this.state.city == "") {
      formIsValid = false;
      errors["city"] = "*Please enter the City Name";
    }
    if (this.state.pincode == "") {
      formIsValid = false;
      errors["pincode"] = "*Please enter the pincode";
    }
    if (this.state.country == "") {
      formIsValid = false;
      errors["country"] = "*Please select the Country Name";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    const { selectedOption, errors } = this.state;
    return (
      <div>
        <UserHeader />
        <section className="lgoinContent SettingsPage">
          <div className="container">
            <ul className="breadCrumb">
              <li>
                {" "}
                <a href="/Dashboard">Dashboard </a>
              </li>
              <li>
                <i className="fas fa-angle-right"></i>
              </li>
              <li>Settings</li>
            </ul>
            <h2>Settings</h2>

            <h4>User address </h4>
            <form noValidate onSubmit={this.onProfileSubmit}>
              <div className="settingsBox">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.name}
                        id="name"
                        type="text"
                        error={errors.name}
                        placeholder="Enter the First Name"
                        className={classnames("form-control", {
                          invalid: errors.name,
                        })}
                      />
                      <span className="text-danger">{errors.name}</span>

                      {/* <input type="text" name="" className="form-control" /> */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Last Name</label>

                      <input
                        onChange={this.onChange}
                        value={this.state.lastname}
                        id="lastname"
                        type="text"
                        error={errors.lastname}
                        placeholder="Enter the Last Name"
                        className={classnames("form-control", {
                          invalid: errors.lastname,
                        })}
                      />
                      <span className="text-danger">{errors.lastname}</span>
                      {/* <input type="text" name="" className="form-control" /> */}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email Address</label>

                      <input
                        onChange={this.onChange}
                        value={this.state.email}
                        id="email"
                        type="text"
                        readOnly
                        error={errors.email}
                        className={classnames("form-control", {
                          invalid: errors.email,
                        })}
                      />
                      <span className="text-danger">{errors.email}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Address</label>
                      {/* <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Plot No / Building Name"
                    /> */}

                      <input
                        onChange={this.onChange}
                        value={this.state.address1}
                        id="address1"
                        type="text"
                        error={errors.address1}
                        placeholder="Plot No / Building Name"
                        className={classnames("form-control", {
                          invalid: errors.address1,
                        })}
                      />
                      <span className="text-danger">{errors.address1}</span>
                    </div>
                  </div>

                  <div className="col-md-6">
                    {/* <div className="form-group">
                      <label>State</label>
                   
                      <input
                        onChange={this.onChange}
                        value={this.state.address2}
                        id="address2"
                        type="text"
                        error={errors.address2}
                        placeholder="Full Address"
                        className={classnames("form-control", {
                          invalid: errors.address2,
                        })}
                      />
                      <span className="text-danger">{errors.address2}</span>
                    </div> */}
                    <div className="form-group">
                      <label>Country </label>
                      <Select
                      placeholder="Select the country"
                        options={this.state.options}
                        value={this.state.country}
                        onChange={this.changeHandler}
                      />

                      <span className="text-danger">{errors.country}</span>
                    </div>
                  </div>

                  {/* {this.state.otpstatus == "Not Verified" ? (
                    <div className="col-md-6 mb-3">
                      <div className="input-group clearfix">
                        <label>Mobile Number</label>
                   

                        <PhoneInput
                          placeholder=" Select the country and Enter phone number"
                          country={"IN"}
                          value={this.state.phonenumber}
                          onChange={(phonenumber) =>
                            this.setState({ phonenumber })
                          }
                          className={classnames("form-control", {
                            invalid: errors.phonenumber,
                          })}
                        />

                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            onClick={() => this.onsendOtp()}
                            type="button"
                          >
                            Send OTP
                          </button>
                        </div>
                      </div>
                      <span className="text-danger">{errors.phonenumber}</span>
                    </div>
                  ) : (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Mobile Number{" "}
                          <sup
                            className="ml-2"
                            style={{ backgroundColor: "green" }}
                          >
                            OTP Verified
                          </sup>
                        </label>
                        <input
                          onChange={this.onChange}
                          value={this.state.phonenumber}
                          id="city"
                          type="text"
                          error={errors.phonenumber}
                          className={classnames("form-control", {
                            invalid: errors.phonenumber,
                          })}
                        />
                        <span className="text-danger">
                          {errors.phonenumber}
                        </span>
                      </div>
                    </div>
                  )}

                  {this.state.otpstatus == "Not Verified" ? (
                    <div className="col-md-6 mb-3">
                      <div className="input-group clearfix">
                        <label>Enter OTP</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.otpcheck}
                          id="otpcheck"
                          type="text"
                          error={errors.otpcheck}
                          placeholder="Enter the OTP Number"
                          className={classnames("form-control", {
                            invalid: errors.otpcheck,
                          })}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={() => this.OTPCheck()}
                          >
                            Check OTP
                          </button>
                        </div>
                      </div>
                      <span className="text-danger">{errors.phonenumber}</span>
                    </div>
                  ) : (
                    ""
                  )} */}
                    <div className="col-md-4">
                     <div className="form-group">
                      <label>State</label>
                   
                      <input
                        onChange={this.onChange}
                        value={this.state.address2}
                        id="address2"
                        type="text"
                        error={errors.address2}
                        placeholder="Enter the State"
                        className={classnames("form-control", {
                          invalid: errors.address2,
                        })}
                      />
                      <span className="text-danger">{errors.address2}</span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>City</label>
                      {/* <input type="text" name="" className="form-control" /> */}
                      <input
                        onChange={this.onChange}
                        value={this.state.city}
                        id="city"
                        type="text"
                        error={errors.city}
                        placeholder="Enter the City"
                        className={classnames("form-control", {
                          invalid: errors.city,
                        })}
                      />
                      <span className="text-danger">{errors.city}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Zip Code</label>
                      {/* <input type="text" name="" className="form-control" /> */}
                      <input
                        onChange={this.onChange}
                        value={this.state.pincode}
                        id="pincode"
                        type="text"
                        error={errors.pincode}
                        placeholder="Enter the Zip Code"
                        className={classnames("form-control", {
                          invalid: errors.pincode,
                        })}
                      />
                      <span className="text-danger">{errors.pincode}</span>
                    </div>
                  </div>
                

                  {this.state.otpstatus == "Not Verified" ? (
                    <div className="col-md-6 mb-3">
                      <div className="input-group clearfix">
                        <label>Mobile Number</label>
                   

                        <PhoneInput
                          placeholder=" Select the country and Enter phone number"
                          country={"IN"}
                          value={this.state.phonenumber}
                          onChange={(phonenumber) =>
                            this.setState({ phonenumber })
                          }
                          className={classnames("form-control", {
                            invalid: errors.phonenumber,
                          })}
                        />

                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            onClick={() => this.onsendOtp()}
                            type="button"
                          >
                            Send OTP
                          </button>
                        </div>
                      </div>
                      <span className="text-danger">{errors.phonenumber}</span>
                    </div>
                  ) : (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Mobile Number{" "}
                          <sup
                            className="ml-2"
                            style={{ backgroundColor: "green" }}
                          >
                            OTP Verified
                          </sup>
                        </label>
                        <input
                          onChange={this.onChange}
                          value={this.state.phonenumber}
                          id="city"
                          type="text"
                          error={errors.phonenumber}
                          className={classnames("form-control", {
                            invalid: errors.phonenumber,
                          })}
                        />
                        <span className="text-danger">
                          {errors.phonenumber}
                        </span>
                      </div>
                    </div>
                  )}

                  {this.state.otpstatus == "Not Verified" ? (
                    <div className="col-md-6 mb-3">
                      <div className="input-group clearfix">
                        <label>Enter OTP</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.otpcheck}
                          id="otpcheck"
                          type="text"
                          error={errors.otpcheck}
                          placeholder="Enter the OTP Number"
                          className={classnames("form-control", {
                            invalid: errors.otpcheck,
                          })}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={() => this.OTPCheck()}
                          >
                            Check OTP
                          </button>
                        </div>
                      </div>
                      <span className="text-danger">{errors.phonenumber}</span>
                    </div>
                  ) : (
                    ""
                  )}


                  <div className="col-md-12">
                    <p className="text-center">
                      <input
                        type="text"
                        readOnly
                        name=""
                        value="Submit"
                        className="btn btnType1 px-0 mt-3"
                        onClick={() => this.onProfileSubmit()}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </form>

            <h4>Google Authentication</h4>
            <form
              noValidate
              // onSubmit={this.onchangepassword}
            >
              <div className="settingsBox">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Write down 2FA code </label>
                      <input
                        onChange={this.onChange}
                        value={this.state.secretcode}
                        id="secretcode"
                        type="text"
                        error={errors.secretcode}
                        placeholder="Enter the secret code"
                        className={classnames("form-control", {
                          invalid: errors.secretcode,
                        })}
                      />
                      <span className="text-danger">{errors.secretcode}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Login Password</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.loginpassword}
                        error={errors.loginpassword}
                        id="loginpassword"
                        type="password"
                        className={classnames("form-control", {
                          invalid: errors.loginpassword,
                        })}
                      />
                      <span className="text-danger">
                        {errors.loginpassword}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Enter 6 Digit Google Authentication Code</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.onecode}
                        error={errors.onecode}
                        id="onecode"
                        type="password"
                        className={classnames("form-control", {
                          invalid: errors.onecode,
                        })}
                      />
                      <span className="text-danger">{errors.onecode}</span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <p className="text-center">
                    <span className="scanCode"><img src={this.state.Factor2AImg} className="img-fluid" /></span>
                  </p>
                  <p class="text-center">
                      {this.state.googleauth!= ""?(
                         <input
                         type="text"
                         name=""
                         readOnly
                         value="Disable"
                         className="btn btnType1 px-0 mt-3"
                         onClick={() => this.TFAenable()}
                       />
 

                      ):(

                        <input
                        type="text"
                        name=""
                        readOnly
                        value="Enable"
                        className="btn btnType1 px-0 mt-3"
                        onClick={() => this.TFAenable()}
                      />
                      )}
                    

                      {/* <button className="btn btnType1" onClick={this.TFAenable}>
                        {this.state.googleauth != "" ? "Disable" : "Enable"}
                      </button> */}
                    </p>
                  </div>
                </div>
              </div>
            </form>

            <h4>Change Password</h4>
            <form
              noValidate
              // onSubmit={this.onchangepassword}
            >
              <div className="settingsBox">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Current Password</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.currentpassword}
                        error={errors.currentpassword}
                        id="currentpassword"
                        type="password"
                        className={classnames("form-control", {
                          invalid: errors.currentpassword,
                        })}
                      />
                      <span className="text-danger">
                        {errors.currentpassword}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>New Password</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.resetpassword}
                        error={errors.resetpassword}
                        id="resetpassword"
                        type="password"
                        className={classnames("form-control", {
                          invalid: errors.resetpassword,
                        })}
                      />
                      <span className="text-danger">
                        {errors.resetpassword}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Confirm New Password</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.password2}
                        error={errors.password2}
                        id="password2"
                        type="password"
                        className={classnames("form-control", {
                          invalid: errors.password2,
                        })}
                      />
                      <span className="text-danger">{errors.password2}</span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <p className="text-center">
                      <input
                        type="text"
                        name=""
                        readOnly
                        value="Submit"
                        className="btn btnType1 px-0 mt-3"
                        onClick={() => this.onchangepassword()}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <UserFooter />
      </div>
    );
  }
}

Settings.propTypes = {
  profileUser: PropTypes.func.isRequired,
  PasswordUpdate: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { profileUser, PasswordUpdate })(
  Settings
);
