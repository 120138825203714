import React, { Component } from 'react'
import footerLogo from "../images/footer-logo.png"
import {Link} from 'react-router-dom';
class Footer extends Component{
	render(){
			return (<footer className="pageFooter">
  <div className="container">
    <div className="row">
      <div className="col-md-2 col-sm-12 mb-3 mb-md-0">
        <div className="footerCont wow fadeInUp" data-wow-delay=".25s">
          <p><a href="/"><img src={footerLogo} className="img-fluid d-block mx-auto" /></a></p>
        </div>
      </div>
      <div className="col-md-2 col-sm-4 mb-4 mb-md-0">
        <div className="footerCont wow fadeInUp" data-wow-delay=".25s">
          <ul>
            <li><a href="/About">About</a></li>
            <li><a href="/TokenDetails">Token Details</a></li>
            <li><a href="/Support">Support Ticket</a></li>
          </ul>
        </div>
      </div>
      <div className="col-md-2 col-sm-4 mb-4 mb-md-0">
        <div className="footerCont wow fadeInUp" data-wow-delay=".25s">
          <ul>
            <li><a href="/TermsAndConditions">Terms and Conditions</a></li>
            <li><a href="/PrivacyPolicy">Privacy Policy</a></li>
          </ul>
        </div>
      </div>
      <div className="col-md-2 col-sm-4 mb-4 mb-md-0">
        <div className="footerCont wow fadeInUp" data-wow-delay=".25s">
          <ul>
            <li><a href="/News">News</a></li>
            <li><a href="/FAQ">FAQ</a></li>
            <li><a href="/Contact">Contact us</a></li>
          </ul>
        </div>
      </div>
      <div className="col-md-4 col-sm-12 mt-3 mt-md-0">
        <div className="footerCont socialIconsMain wow fadeInUp" data-wow-delay=".25s">
          <ul className="socialIcons">
            <li><a href="https://www.facebook.com/RealGenesisOne/" target="_blank"><i className="fab fa-facebook"></i></a></li>
            <li><a href="https://www.instagram.com/genesisone_platform/" target="_blank"><i className="fab fa-instagram"></i></a></li>
            <li><a href="https://www.linkedin.com/company/genesisone" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
            <li><a href="https://twitter.com/RealGenesisOne" target="_blank"><i className="fab fa-twitter"></i></a></li>
            <li><a href="https://web.telegram.org/z/#736448021" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
            <li><a href="https://wa.me/17866307736" target="_blank"><i className="fab fa-whatsapp"></i></a></li>
            <li><a href="skype:live:jamde06?chat"><i className="fab fa-skype"></i></a></li>
          </ul>
          <p className="mt-3">
            <a href="https://bitcointalk.org/index.php?topic=5267792.msg54968314#msg54968314" target="_blank" className="btn btnFooter mr-2 mr-md-0 mb-md-3 mb-lg-0 mr-lg-2"><i class="fab fa-bitcoin"></i> AIRDROP</a>
            <a href="https://bitcointalk.org/index.php?topic=5267885.new#new" target="_blank" className="btn btnFooter"><i class="fab fa-bitcoin"></i> BOUNTY</a>
          </p>
        </div>
      </div>
      <div className="col-md-12">
        <div className="footerBottom">
          <p className="text-center">Copyright @ 2022 EG Group Global Holdings. All rights reserved</p>
        </div>
      </div>
    </div>
  </div>
</footer>
);
	};
}

export default Footer;