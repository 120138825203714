import React, { Component } from 'react'
import Logo from "../images/logo.png"
import usaFlag from "../images/usaFlag.png"
import phFlag from "../images/phFlag.png"
import indiaFlag from "../images/indiaFlag.png"
import franceFlag from "../images/FranceFlag.png"
import uaeFlag from "../images/uaeFlag.png"
import {Link} from 'react-router-dom';
//import scrollToComponent from 'react-scroll-to-component';

class Navbar extends Component {
  render() {
    return (<nav className="navbar navbar-expand-xl customNav wow fadeInDown" data-wow-delay=".2s">
  <div className="container">
    <Link to="/" className="navbar-brand"><img src={Logo} alt="EG GROUP HOLDINGS LIC" /></Link>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fas fa-bars"></i>
        </button>
    <div className="collapse navbar-collapse" id="navbarResponsive">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item active">
          <Link to="/About" className="nav-link">About</Link>
        </li>
        <li className="nav-item">
          <Link to="/TokenDetails" className="nav-link">Token Details</Link>
        </li>
        <li className="nav-item">
          <Link to="/FundAllocation" className="nav-link">Token Allocation</Link>
        </li>
        <li className="nav-item">
          <Link to="/Roadmap" className="nav-link">Road Map</Link>
        </li>
        <li className="nav-item">
          <Link to="/Team" className="nav-link">Team</Link>
        </li>
        <li className="nav-item">
          <Link to="/FAQ" className="nav-link">FAQ</Link>
        </li>
        <li className="nav-item">
          <Link to="/Login" className="nav-link">Login</Link>
        </li>
        <li className="nav-item">
          <Link to="/Register" className="nav-link">Register</Link>
        </li>
        <li className="nav-item dropdown">
          {/* <Link to="#" className="nav-link">Download Whitepaper</Link> */}
          <a  href="/pdf/Whitepaper.pdf" download  className="nav-link dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Download Whitepaper</a>
           <div class="dropdown-menu  dropdown-menu-right dropWhitePaper w-100" aria-labelledby="dropdownMenuButton">
           <a class="dropdown-item" href="/pdf/Whitepaper.pdf" download ><img src={usaFlag} class="img-fluid" alt="" /> English</a>
           <a class="dropdown-item" href="/pdf/Hindi-whitepaper.pdf" download><img src={indiaFlag} class="img-fluid" alt="" /> Hindi</a>
           <a class="dropdown-item" href="/pdf/Arabic-whitepaper.pdf" download><img src={uaeFlag} class="img-fluid" alt="" /> Arabic</a>
           <a class="dropdown-item" href="/pdf/French-whitepaper.pdf" download><img src={franceFlag} class="img-fluid" alt="" /> French</a>
           <a class="dropdown-item" href="/pdf/Filipino-whitepaper.pdf" download><img src={phFlag} class="img-fluid" alt="" /> Filipino</a>
          </div>

        </li>
      </ul>
    </div>
  </div>
</nav>
);
  }
}

export default Navbar
