import React, { Component } from "react";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import ReactDOM from "react-dom";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { store } from "react-notifications-component";
import classnames from "classnames";
import keys from "../actions/config";

const url = keys.baseUrl;

class LoginHistory extends Component {
  constructor() {
    super();
    this.state = {
      loginhistory: {},
    };
  }
  componentDidMount() {
    this.getData();
  }

  getData() {
    let userid = this.props.auth.user.id;
    axios
      .get(url + "api/userget/" + userid)
      .then((res) => {
        this.props.auth.user.name = res.data.name;
        this.setState({
          name: res.data.name,
          loginhistory: res.data.loginhistory,
        });
        this.loginHistory();
      })
      .catch();
  }

  loginHistory = () => {
    let table = [];

    for (var i = 0; i < this.state.loginhistory.length; i++) {
      var new_i = this.state.loginhistory.length - 1 - i;
      table.push(
        <tr>
          <td>{this.state.loginhistory[new_i].logindate}</td>
          <td>{this.state.loginhistory[new_i].ipaddress}</td>
          <td>
            {this.state.loginhistory[new_i].browsername}/
            {this.state.loginhistory[new_i].os}
          </td>
          <td>
            {this.state.loginhistory[new_i].regionName}/
            {this.state.loginhistory[new_i].regionName}
          </td>
        </tr>
      );
    }
    return table;
  };
  render() {
    return (
      <div>
        <UserHeader />
        <section className="lgoinContent buyPage">
          <div className="container">
            <h2 className="mb-0 noBorderBottom">
              <span>Login History</span>{" "}
              <a href="/Dashboard">
                <i className="fas fa-angle-left"></i> Dashboard
              </a>
            </h2>
            <div className="table-responsive">
              <table className="table borderedTable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>IP Address</th>
                    <th>Browser / Device</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>{this.loginHistory()}</tbody>
              </table>
            </div>
          </div>
        </section>
        <UserFooter />
      </div>
    );
  }
}

LoginHistory.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(LoginHistory));
