import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ReactDOM from "react-dom";
import axios from "axios";
import keys from "../actions/config";

const url = keys.baseUrl;

class FAQ extends Component {
  constructor() {
    super();
    this.state = {
      id: "",
      supportarray: [],
      errors: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    axios
      .post(url + "api/faq-data")
      .then((res) => {
        console.log("REsonse", res);
        this.setState({ supportarray: res.data });
      })
      .catch();
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    return (
      <div>
        <Navbar />
        <section className="lgoinContent pageFAQ">
          <div className="container">
            <h2 className="text-center borderBottomZero pb-0">FAQ</h2>
            <div className="row">
              <div className="col-md-12">
                <div className="faqCont">
                  <h3>GenONE Token</h3>
                  <div className="faqAccordian wow fadeIn">
                    <div id="accordion">
                      {this.state.supportarray.map((answer, i) => {
                        return (
                          <div className="card">
                            <div
                              className="card-header wow flipInX"
                              id="headingOne"
                            >
                              <h5 className="mb-0">
                                <button
                                  className="btn btn-link"
                                  data-toggle="collapse"
                                  data-target={"#collapsetest" + i}
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                >
                                  <span className="question">
                                    {answer.question}
                                  </span>{" "}
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </h5>
                            </div>

                            <div
                              id={"collapsetest" + i}
                              className="collapse "
                              aria-labelledby="headingOne"
                              data-parent="#accordion"
                            >
                              <div className="card-body">
                                <p>{answer.answer}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {/* <div className="card">
                        <div
                          className="card-header wow flipInX"
                          id="headingTwo"
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              <span className="question">
                                When will I receive my tokens?
                              </span>{" "}
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              Urna condimentum mattis pellentesque id nibh
                              tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header wow flipInX"
                          id="headingThree"
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <span className="question">
                                {" "}
                                What exchange can I use?
                              </span>{" "}
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              Urna condimentum mattis pellentesque id nibh
                              tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet.. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div
                          className="card-header wow flipInX"
                          id="headingFour"
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              <span className="question">
                                What crypto coins need to deposit the buy the
                                tokens?
                              </span>{" "}
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseFour"
                          className="collapse"
                          aria-labelledby="headingFour"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              Urna condimentum mattis pellentesque id nibh
                              tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header wow flipInX"
                          id="headingFive"
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              <span className="question">
                                Are you a registered company?
                              </span>{" "}
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseFive"
                          className="collapse"
                          aria-labelledby="headingFive"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              Urna condimentum mattis pellentesque id nibh
                              tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet.. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet.
                            </p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                {/* <div className="faqCont">
                  <h3>GenCo Coin</h3>
                  <div className="faqAccordian wow fadeIn">
                    <div id="accordion2">
                      <div className="card">
                        <div
                          className="card-header wow flipInX"
                          id="heading2One"
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapse2One"
                              aria-expanded="true"
                              aria-controls="collapse2One"
                            >
                              <span className="question">
                                When and How will I use my Genesis Tokens?
                              </span>{" "}
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </h5>
                        </div>

                        <div
                          id="collapse2One"
                          className="collapse"
                          aria-labelledby="heading2One"
                          data-parent="#accordion2"
                        >
                          <div className="card-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. Lorem Ipsum is simply dummy text of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever since
                              the 1500s, when an unknown printer took a galley
                              of type and scrambled it to make a type specimen
                              book.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header wow flipInX"
                          id="heading2Two"
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapse2Two"
                              aria-expanded="false"
                              aria-controls="collapse2Two"
                            >
                              <span className="question">
                                When will I receive my tokens?
                              </span>{" "}
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapse2Two"
                          className="collapse"
                          aria-labelledby="heading2Two"
                          data-parent="#accordion2"
                        >
                          <div className="card-body">
                            <p>
                              Urna condimentum mattis pellentesque id nibh
                              tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header wow flipInX"
                          id="heading2Three"
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapse2Three"
                              aria-expanded="false"
                              aria-controls="collapse2Three"
                            >
                              <span className="question">
                                {" "}
                                What exchange can I use?
                              </span>{" "}
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapse2Three"
                          className="collapse"
                          aria-labelledby="heading2Three"
                          data-parent="#accordion2"
                        >
                          <div className="card-body">
                            <p>
                              Urna condimentum mattis pellentesque id nibh
                              tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet.. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div
                          className="card-header wow flipInX"
                          id="heading2Four"
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapse2Four"
                              aria-expanded="false"
                              aria-controls="collapse2Four"
                            >
                              <span className="question">
                                What crypto coins need to deposit the buy the
                                tokens?
                              </span>{" "}
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapse2Four"
                          className="collapse"
                          aria-labelledby="heading2Four"
                          data-parent="#accordion2"
                        >
                          <div className="card-body">
                            <p>
                              Urna condimentum mattis pellentesque id nibh
                              tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div
                          className="card-header wow flipInX"
                          id="heading2Five"
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapse2Five"
                              aria-expanded="false"
                              aria-controls="collapse2Five"
                            >
                              <span className="question">
                                Are you a registered company?
                              </span>{" "}
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapse2Five"
                          className="collapse"
                          aria-labelledby="heading2Five"
                          data-parent="#accordion2"
                        >
                          <div className="card-body">
                            <p>
                              Urna condimentum mattis pellentesque id nibh
                              tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet.. Urna
                              condimentum mattis pellentesque id nibh tortor
                              aliquet. Urna condimentum mattis pellentesque id
                              nibh tortor aliquet. Urna condimentum mattis
                              pellentesque id nibh tortor aliquet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default FAQ;
