import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import Logo from "../images/logo.png"
import HeaderSpeakerIcon from "../images/speakerIcon.png"
import Marquee from 'react-text-marquee';


class TradeHeader extends Component {
  render() {
    return (
    	<div>
    		<nav className="navbar navbar-expand-xl customNav wow fadeInDown" data-wow-delay=".2s">
  <div className="container">
    <Link to="/" className="navbar-brand"><img src={Logo} alt="EG GROUP HOLDINGS LIC" /></Link>
    
        <div className="ml-auto rightNav">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fas fa-bars"></i>
        </button>
    <div className="collapse navbar-collapse" id="navbarResponsive">
      <ul className="navbar-nav">
        <li className="nav-item active">
          <Link to="/About" className="nav-link">About</Link>
        </li>
        <li className="nav-item">
          <Link to="/TokenDetails" className="nav-link">Token Details</Link>
        </li>
        <li className="nav-item">
          <Link to="/FundAllocation" className="nav-link">Fund Allocation</Link>
        </li>
        <li className="nav-item">
          <Link to="/Roadmap" className="nav-link">Road Map</Link>
        </li>
        <li className="nav-item">
          <Link to="/Team" className="nav-link">Team</Link>
        </li>
        <li className="nav-item">
          <Link to="/FAQ" className="nav-link">FAQ</Link>
        </li>
        <li className="nav-item">
          <Link to="/Login" className="nav-link">Login</Link>
        </li>
        <li className="nav-item">
          <Link to="/Register" className="nav-link">Register</Link>
        </li>
        <li className="nav-item">
          {/* <Link to="#" className=>Download Whitepaper</Link> */}
          <a  href="/pdf/Whitepaper.pdf" download  className="nav-link">Download Whitepaper</a>
        </li>
      </ul>
    </div>
    <div className="newsMain wow fadeIn" ata-wow-delay="1s">
          <div className="todayPrice">1 GenONE = $1 USD</div>
          <div className="speakerIcon"><img src={HeaderSpeakerIcon} className="img-fluid" /></div>
          <div className="simple-marquee-container">
          <Marquee text="“GenesisOne is launching their Series A investment rounds to interested parties! Be sure to come in now – you missed the ground floor opportunity, so take heed to join the family now!”" />

           
          </div>
          {/* <div className="newsMore"><a href="#">More <i className="fas fa-angle-right"></i></a></div> */}
        </div>
        </div>
  </div>
</nav>
    	</div>
    	);
    }
}

export default TradeHeader