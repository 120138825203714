import React, { Component } from 'react'
import ChartLeft from "../images/chartleft.png"
import ChartRight from "../images/chartright.png"
import chartAllocation from "../images/chartAllocation.png"
import Navbar from './Navbar'
import Footer from './Footer'
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import ReactDOM from 'react-dom';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider'
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import keys from "../actions/config";
import { connect } from "react-redux";
import axios from "axios";
import { store } from "react-notifications-component";
import usaFlag from "../images/usaFlag.png"
import phFlag from "../images/phFlag.png"
import indiaFlag from "../images/indiaFlag.png"
import franceFlag from "../images/FranceFlag.png"
import uaeFlag from "../images/uaeFlag.png"



const url = keys.baseUrl;

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};


class Landing extends Component {


  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      // toast.success("res.data.message", {
      //   position: toast.POSITION.TOP_CENTER
      // });
      this.props.history.push("/Dashboard");
    }
    let withdraw_id = this.props.match.params.id;
    if(typeof withdraw_id != "undefined" && withdraw_id!=""){
      let passdata = {withdrawid:withdraw_id}
      axios
          .post(url+"api/withdrawrequest",passdata)
          .then(res => {
            store.addNotification({
              title: "Wonderful!",
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true
              }
            });
            this.props.history.push("/Login");
          })
          .catch()
    }
  }
	render() {
		return (
      <div>
      <Navbar />
<section className="tokenAllocation pt-5">
  <div className="container">
    <h2 className="landinTitle">Token Allocation</h2>
    {/* <div className="row">
      <div className="col-md-6 mb-5 mb-md-0">
        <div className="taChart">
           <img src={ChartLeft} className="img-fluid" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="taChart">
          <img src={ChartRight} className="img-fluid" />
        </div>
      </div>
    </div> */}
    <div className="row">
      <div className="col-md-8 mx-auto">
        <div className="taChart">
          <img src={chartAllocation} className="img-fluid d-block mx-auto" />
        </div>
      </div>
    </div>
    <p className="text-center mt-5">
    <div className="btn buttonType2 dropdown p-0">
      <a className="nav-link dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Download Whitepaper</a>
       <div class="dropdown-menu   dropWhitePaper w-100" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" href="/pdf/Whitepaper.pdf" download ><img src={usaFlag} class="img-fluid" alt="" /> English</a>
        <a class="dropdown-item" href="/pdf/Hindi-whitepaper.pdf" download><img src={indiaFlag} class="img-fluid" alt="" /> Hindi</a>
        <a class="dropdown-item" href="/pdf/Arabic-whitepaper.pdf" download><img src={uaeFlag} class="img-fluid" alt="" /> Arabic</a>
        <a class="dropdown-item" href="/pdf/French-whitepaper.pdf" download><img src={franceFlag} class="img-fluid" alt="" /> French</a>
        <a class="dropdown-item" href="/pdf/Filipino-whitepaper.pdf" download><img src={phFlag} class="img-fluid" alt="" /> Filipino</a>
      </div>
    </div>    </p>
  </div>
</section>
    <Footer />
</div>
);
	}
}


Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, {  })(withRouter(Landing));
