import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    FORGOT,
    USER_LOADING,
    USER_ADD,
    SUPPORT_REPLY,
    SUPPORT
} from "./types";
import keys from "./config";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { store } from 'react-notifications-component';

import { AlertPage } from "twilio/lib/rest/monitor/v1/alert";


const url = keys.baseUrl;


export const registerfrontUser = (userData, history) => dispatch => {
    axios
        .post(url+"api/registerfrontenduser", userData)
        .then(res => dispatch({
                type: USER_ADD,
                payload: res,
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const support = (supportdata) => dispatch => {
    console.log(supportdata,'supportdatactions');
    axios
        .post(url+"api/supportticketadd", supportdata)
        .then(res => dispatch({
            type: SUPPORT,
             payload: res,
        })

        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );


   };


   export const supportreply = (supportreplydata) => dispatch => {
    axios
        .post(url+"api/support_reply_user", supportreplydata)
        .then(res => dispatch({
            type: SUPPORT,
            payload: res,
        })

        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );


   };

export const loginUser = userData => dispatch => {
    axios
        .post(url+"api/loginfrontend", userData)
        .then(res => {
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));            
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};


export const logoutUser = () => dispatch => {
    localStorage.removeItem("jwtToken");
    setAuthToken(false);
    dispatch(setCurrentUser({}));
};


export const tfachecking = userData => dispatch => {
    axios
        .post(url+"api/tfachecking", userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
  };
