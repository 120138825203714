import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import TradeHeader from "./TradeHeader";
import Footer from "./Footer";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import keys from "../actions/config";
import { forgotUser } from "../actions/useractions";
import axios from "axios";

const url = keys.baseUrl;

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps.auth.forgotuser", nextProps.auth.forgotuser);
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }

    if (
      nextProps.auth.forgotuser !== undefined &&
      nextProps.auth.forgotuser.data !== undefined &&
      nextProps.auth.forgotuser.data.message !== undefined
    ) {
      store.addNotification({
        title: "Wonderful!",
        message: nextProps.auth.forgotuser.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      this.setState({
        otpver: this.state.phone != "" ? "true" : "",
      });
      if (
        nextProps.auth.forgotuser.data.message ==
        "Your password has been reset successfully"
      ) {
        nextProps.auth.forgotuser = "";
        this.props.history.push("/Login");
      }
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const forgotUser = {
      id: this.props.auth.user.id,
      email: this.state.email,
    };
    console.log(forgotUser);
    // this.props.forgotUser(forgotUser);
    axios
      .post(url + "api/forgotpassword", forgotUser)
      .then((res) => {
        if (res.data.status) {
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        } else {
          store.addNotification({
            title: "Error!",
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 1500,
              onScreen: true,
            },
          });
        }
      })
      .catch();
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <TradeHeader />
        <section className="forgotPasswordBg">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-7 mx-auto">
                <div className="loginRegRight">
                  <div className="formBox">
                    <h2 className="text-center mb-3">Forgot Password</h2>
                    <p className="text-center"></p>
                    <form noValidate onSubmit={this.onSubmit}>
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.email}
                          error={errors.email}
                          id="email"
                          type="email"
                          className={classnames("form-control", {
                            invalid: errors.email,
                          })}
                        />
                        <span className="text-danger">{errors.email}</span>
                      </div>
                      <p className="text-center">
                        <button
                          className="btn btnType1"
                          type="submit"
                          // onClick={() => this.forgotpass()}
                        >
                          Submit
                        </button>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  forgotUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { forgotUser })(
  withRouter(ForgotPassword)
);
