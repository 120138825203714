import React, {Fragment,Component} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/animate.css";
import "./css/marquee.css";
import "./css/style.css";
import "react-notifications-component/dist/theme.css";

import store from "./store";
import { Provider } from "react-redux";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authactions";
import PrivateRoute from "./components/privateroute/PrivateRoute";
import ReactNotification from "react-notifications-component";

import Landing from "./components/Landing";
import Login from "./components/Login";
import Register from "./components/Register";
import ForgotPassword from "./components/ForgotPassword";
import Settings from "./components/Settings";
import Support from "./components/Support";
import Dashboard from "./components/Dashboard";
import About from "./components/About";
import Contact from "./components/Contact";
import TokenDetails from "./components/TokenDetails";
import FundAllocation from "./components/FundAllocation";
import Team from "./components/Team";
import Roadmap from "./components/Roadmap";
import Buy from "./components/Buy";
import Deposit from "./components/Deposit";
import Withdraw from "./components/Withdraw";
import Transfer from "./components/Transfer";
import LoginHistory from "./components/LoginHistory";
import FAQ from "./components/FAQ";
import News from "./components/News";
import Kyc from "./components/Kyc";
import ResetPassword from "./components/resetpassword";
import Referral from "./components/Referral";
import Invest from "./components/Invest";
import Transactionhistory from "./components/Transactionhistory";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import PaymentSuccess from "./components/PaymentSuccess";
import {Redirect,BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import keys from "./actions/config";
import axios from "axios";
import IdleTimer from 'react-idle-timer'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const url = keys.baseUrl;


if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "./Login";
  }
}


class App extends Component {
	constructor(props) {
	  super(props);
	  this.idleTimer = null
  this.onAction = this._onAction.bind(this)
  this.onActive = this._onActive.bind(this)
  this.onIdle = this._onIdle.bind(this)
	  this.state = {
		maintanancecheck : false
	  }
  }
  _onAction(e) {
//   console.log('user did something', e)
}

_onActive(e) {
//   console.log('user is active', e)
//   console.log('time remaining', this.idleTimer.getRemainingTime())
}

_onIdle(e) {
//   console.log('user is idle', e)
//   console.log('last active', this.idleTimer.getLastActiveTime())
  if(this.idleTimer.getRemainingTime()==0)
  {
	store.dispatch(logoutUser());
	  window.location.href = "/Login";
  }
}
render() {
 return (
	 <div>
	<IdleTimer
	ref={ref => { this.idleTimer = ref }}
	element={document}
	onActive={this.onActive}
	onIdle={this.onIdle}
	onAction={this.onAction}
	debounce={250}
	timeout={1000*60*10} />
	<Provider store={store}>
	<Router basename={"/"}>
	  <Fragment>
		<ReactNotification />
		<Route exact path="/" component={Landing} />
		<section className="container-fluid px-0">
		  <Switch>
			<Route exact path="/Register" component={Register} />
			<Route exact path="/Login" component={Login} />
			<Route exact path="/Activate/:id" component={Login} />
			<Route exact path="/ForgotPassword" component={ForgotPassword} />
			<Route
			  exact
			  path="/resetpassword/:id"
			  component={ResetPassword}
			/>
			<Route exact path="/FAQ" component={FAQ} />
			<Route exact path="/Contact" component={Contact} />
			<Route exact path="/About" component={About} />
			<Route exact path="/TokenDetails" component={TokenDetails} />
			<Route exact path="/FundAllocation" component={FundAllocation} />
			<Route exact path="/Team" component={Team} />
			<Route exact path="/Roadmap" component={Roadmap} />
			<Route exact path="/News" component={News} />
			<Route
			  exact
			  path="/TermsAndConditions"
			  component={TermsAndConditions}
			/>
			<Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
			<Route exact path="/Withdraw/:id" component={Landing} />
			<Route exact path="/tokentransfer/:id" component={Landing} />

			<Switch>
			  <PrivateRoute exact path="/Settings" component={Settings} />
			  <PrivateRoute exact path="/Dashboard" component={Dashboard} />
			  <PrivateRoute exact path="/Support" component={Support} />
			  <PrivateRoute exact path="/Kyc" component={Kyc} />
			  <PrivateRoute exact path="/Buy" component={Buy} />
			  <PrivateRoute exact path="/Deposit" component={Deposit} />
			  <PrivateRoute exact path="/Withdraw" component={Withdraw} />
			  <PrivateRoute exact path="/Transfer" component={Transfer} />
			  <Route exact path="/payment-success" component={PaymentSuccess} />
			  <PrivateRoute
				exact
				path="/LoginHistory"
				component={LoginHistory}
			  />
			  <PrivateRoute
				exact
				path="/Transactionhistory"
				component={Transactionhistory}
			  />
			  <PrivateRoute exact path="/Referral" component={Referral} />
			  <PrivateRoute exact path="/Invest" component={Invest} />
			</Switch>
		  </Switch>
		</section>
	  </Fragment>
	</Router>
  </Provider>
  </div>
);
}
}






// function App() {


//   return (
   
//   );
// }

export default App;
