import React, { Component } from 'react'
import RevRightImg from "../images/rev-right-img.png"
import RevLeftImg from "../images/rev-left-img.png"
import RevLeftImgTwo from "../images/rev-left-img2.png"
import Navbar from './Navbar'
import Footer from './Footer'
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import ReactDOM from 'react-dom';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider'
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import keys from "../actions/config";
import { connect } from "react-redux";
import axios from "axios";
import { store } from "react-notifications-component";



const url = keys.baseUrl;

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};


class Landing extends Component {

  
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      // toast.success("res.data.message", {
      //   position: toast.POSITION.TOP_CENTER
      // });
      this.props.history.push("/Dashboard");
    }
    let withdraw_id = this.props.match.params.id;
    if(typeof withdraw_id != "undefined" && withdraw_id!=""){
      let passdata = {withdrawid:withdraw_id}
      axios
          .post(url+"api/withdrawrequest",passdata)
          .then(res => {
            store.addNotification({
              title: "Wonderful!",
              message: res.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true
              }
            });
            this.props.history.push("/Login");
          })
          .catch()
    }
  }
	render() {
		return (
      <div>
      <Navbar />
      
<section className="genoneRevolution" id="About">
  <div className="container">
    <h2 className="landinTitle">GenONE Revolution</h2>
    <p>GenesisOne is a cryptocurrency platform that will utilize diverse businesses and projects globally to bring sustainable value to its members.
By entrenching itself into communities globally where the greatest needs exist; GenesisOne looks to embrace marginalized and neglected peoples to cultivate, develop and elevate them and their communities to allow for the exponential growth needed in global GDP to bring equity to all!</p>
<p>GenesisOne’s launch of a CyberLottery, sports book and virtual casino allows for maximum exposure across cultures and serves as the initial stream of dividends adding value to the underlying GenesisOne Token. Furthermore, the launch of the exchange and wallet will allow advanced financial trading operations to take place across crypto, stock, commodity and currency pairs.
Members can trade options, conduct algorithmic trading, quantitative trading, and utilize levels of margin to enhance their portfolios.
Provision is underway for the next wave of projects dealing in Banking, Trade, Infrastructure and community development.</p>
<p>GenesisONE where tomorrow is happening today!</p>
<p>GenesisOne is currently developing both our global CyberLottery system (including custom GenONE Lottery), global professional sports wagering system and virtual casino as well as our NeoGenesis Exchange and wallet offering new opportunities for algorithmic and quantitative trading, option trading on stock, commodity, currency, cryptocurrency trade pairs. These systems are already in phase two development and will continue forward with completion toward the end of October/beginning of November.</p>
<p>These are the first forays into our global initiatives that will allow for maximum exposure and penetration facilitating future projects and investments.</p>
<p>Yes, there are other crypto gambling sites just as there are many forms of crypto that specialize in one particular thing. GenesisOne is developing many revenue streams from lotteries/casino gambling, algorithmic, quantitative, option trading exchange/wallet, real estate and community development, infrastructure projects, investment and retail banking, mining, cross border trade and logistics, warehousing and port development, petroleum and refinery products, leisure attractions, hotelier, restauranteur, airline operations, and in marginalized neglected, crime ridden areas we are enterprising in the development of city wide surveillance systems working together with official police and our security forces to augment the overall security apparatus of the area and begin its revitalization as an economic center.</p>
<p>All together, think of GenesisOne as a Berkshire Hathaway Group in its infancy which allows you access at only $1 USD in lieu of the latter’s approximately $240,000 price of entry.</p>
<p>A lot of the established dapps concentrate on specific niches as such creating a market for themselves becomes increasingly difficult.</p>
<p>GenesisOne is not a platform targeting a niche because that does nothing for the majority of the world. So many in the world lack the basic necessities in life to truly live. We’re not interesting in surviving; we want people to truly enjoy life and be joyous and merry as we all were created to be. So with that being said, yes we will be developing numerous revenue streams in a strategic phased in manner to not only ensure we impact the communities where we serve to grow, develop and prosper but also to allow the greatest potential for value for all our members in achieving the highest dividends from the individual business component performances.</p>
    <div className="row">
      <div className="col-md-6">
        <div className="genoneRevolutionContent">
          <h4>Global Cyber PowerBall Lottery Enterprise</h4>
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
        </div>
      </div>
      <div className="col-md-6">
        <div className="genoneRevolutionImg">
            <img src={RevRightImg} className="img-fluid" />
        </div>
      </div>
    </div>
    <div className="mobReverseColum">
    <div className="row mt-5">
      <div className="col-md-6">
        <div className="genoneRevolutionImg">
            <img src={RevLeftImg} className="img-fluid" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="genoneRevolutionContent">
          <h4>Global Professional Sports Wagering System</h4>
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
        </div>
      </div>
    </div>
    </div>
    <div className="row mt-5">
      <div className="col-md-6">
        <div className="genoneRevolutionContent">
          <h4>Solution for lost or stolen Tokens</h4>
          <p>Member through our website and fills out the application providing their information and so forth. This person in effect fills out a KYC application allowing them to become Members and thereby able to receive member exclusives such as the burnable feature for their tokens if they are lost or stolen allowing those lost GenesisOne.</p>
          <p className="text-center"><a href="#" className="btn btnType1">Buy GenONE Now</a></p>
        </div>
      </div>
      <div className="col-md-6">
        <div className="genoneRevolutionImg">
            <img src={RevLeftImgTwo} className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>

    <Footer />
</div>
);
	}
}


Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, {  })(withRouter(Landing));
