import React, { Component } from "react";
import TokenTitleCoin from "../images/token-title-coin.png";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import ReactDOM from "react-dom";
import Tooltip from "rc-tooltip";
import Slider from "rc-slider";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import keys from "../actions/config";
import { connect } from "react-redux";
import axios from "axios";
import { store } from "react-notifications-component";

const url = keys.baseUrl;

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

class Landing extends Component {
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      // toast.success("res.data.message", {
      //   position: toast.POSITION.TOP_CENTER
      // });
      this.props.history.push("/Dashboard");
    }
    let withdraw_id = this.props.match.params.id;
    if (typeof withdraw_id != "undefined" && withdraw_id != "") {
      let passdata = { withdrawid: withdraw_id };
      axios
        .post(url + "api/withdrawrequest", passdata)
        .then((res) => {
          store.addNotification({
            title: "Wonderful!",
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true,
            },
          });
          this.props.history.push("/Login");
        })
        .catch();
    }
  }
  render() {
    return (
      <div>
        <Navbar />
        <section className="tokenDetails pt-5">
          <div className="container">
          <div className="mapTokenDetails">
              <h2 className="landinTitle">
                <img src={TokenTitleCoin} /> Token Details
              </h2>
              <div className="row">
                <div className="col-lg-6 col-md-12 mb-md-4">
                  <ul>
                    <li className="greyMap">
                      <span>Token Name</span> GenesisOne (GenOne){" "}
                    </li>
                    <li className="transMap">
                      <span>Total Supply</span>1 Undecillion (USD) ~ 1 Sextillion (Euro)
                    </li>
                    {/* <li className="blackMap">
                      <span>Premine</span> 6,000,000 (6x10-28% of Total Supply)
                    </li>
                    <li className="transMap">
                      <span>Presale</span>  5,000,000 (83.33% of PreMine Supply)
                    </li> */}
                    <li className="greyMap">
                      <span>Bounty Program</span> 500,000 (8.33% of Premine Supply)
                    </li>
                    <li className="transMap">
                      <span>Price</span>  1 GenOne = 1 USD or Equivalent ETH
                    </li>
                    <li className="blackMap">
                      <span>Token Sale Start and End Date</span>OPEN{" "}
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-12">
                  <ul>
                    {/* <li className="greyMap">
                      <span>Soft Cap</span> $10
                    </li> */}
                    <li className="greyMap">
                      <span>Hard Cap</span>No Hard Cap
                    </li>
                    <li className="transMap">
                      <span>Series A Funding Stage Goal</span>$5,000,000 (5 Million)
                    </li>
                    {/* <li className="transMap">
                      <span>ICO Crowdsale Goal</span> 100,000,000,000 (100 Billion)
                    </li> */}
                    <li className="greyMap">
                      <span>Mintable</span> Yes (If Token is lost or because of Theft)
                    </li>
                    <li className="transMap">
                      <span>Burnable/Freezable</span> Yes
                    </li>
                    <li className="blackMap">
                      <span>Series A Funding Stage</span> OPEN
                    </li>
                    {/* <li className="blackMap">
                      <span>Token Sale Start and End Date (USA)</span> Sep 10, 2020
                      to January 30, 2021
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(Landing));
